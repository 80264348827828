import { Box, Button, SxProps } from '@mui/material';
import React from 'react';

const filledStyles : SxProps = {
  backgroundColor: '#835db2',
  color: 'white',
  padding:'8px 16px',
  cursor:'pointer',
  borderRadius: '8px',
  fontSize:'14px',
  fontWeight:700,
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#833bac', 
    color: '#fff', 
  },
 '&.Mui-disabled': {
    backgroundColor: '#9570c2',
    color: '#fff',
  }
  
};

const unfilledStyles : SxProps = {
  backgroundColor: '#fff',
  cursor:'pointer',
  borderRadius: '8px',
  fontSize:'14px',
  fontWeight:700,
  color: '#835db2',
  padding:'8px 16px',       
  border: '1px solid #835db2',  
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  '&:hover': {
    backgroundColor: '#f1f1f1', 
    border: '1px solid #833bac',  
    color: '#833bac', 
  },

};

interface CustomButtonProps {
    onClick: () => void;
    disable?: boolean;
    text: string;
    variant?: 'filled' | 'unfilled';
    type?: 'button' | 'submit' | 'reset' | undefined;
  }

  const CustomButton: React.FC<CustomButtonProps> = ({ onClick, text, variant, type, disable }) => {
    const styles = (variant === 'filled') ? filledStyles : unfilledStyles;
  
  if (type) {
    return (
      <Button
        onClick={onClick}
        type={type}
        sx={styles}
        disabled={disable} 
      >
        {text}
      </Button>
    );
  }

  return ( 
      <Box
        onClick={onClick}
        sx={styles}
      >
        {text}
      </Box>
   );
};

export default CustomButton;