import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalenderIcon from "src/assets/imageComponents/calender";
import utc from "dayjs/plugin/utc";
import { styled } from "@mui/material/styles";

// Apply the utc plugin
dayjs.extend(utc);
dayjs.locale("en"); // Set dayjs locale to English

interface CustomDatePickerProps {
  value?: Dayjs | null;
  disableFuture?: boolean;
  disablePast?: boolean;
  showError?: boolean;
  label?: string;
  onChange: (newValue: Dayjs | null) => void;
}

const StyledLabel = styled("span")({
  "&::after": {
    content: "' * '",
    color: "red",
  },
});


const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  value,
  disableFuture = false,
  disablePast = false,
  showError,
  label = "Date",
  onChange,
}) => {
  const [error, setError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [msg, setMsg] = React.useState<string>("Date of birth is required");


  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue !== null) {
      const selectedDate = newValue.toDate();
      const currentDate = new Date();
      // Calculate the date 18 years ago
      const ageRestriction = new Date();
      ageRestriction.setFullYear(currentDate.getFullYear() - 18);

      if (selectedDate.getTime() <= ageRestriction.getTime()) {
        onChange(newValue);
        setError(false);
      } else {
        onChange(null);
        setError(true);
        // setErrorMessage("Age restriction")
        setMsg("Age should be greater than 18");
      }
    } else {
      setError(true);
      // setErrorMessage("Date of birth is required");
      setMsg("Date of birth is required");
    }
  };

  React.useEffect(() => {
    setErrorMessage(msg);
  }, [error,msg]);

  // const maxDate = dayjs().subtract(18, "year");

  let defaultDate = value && value.isValid() ? value : null;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={
            <StyledLabel>
              {label}
            </StyledLabel>
          }
          value={defaultDate}
          timezone="UTC"
          onChange={(newValue) => {
            handleDateChange(newValue);
          }}
          format="MMMM D, YYYY" // Set custom formatting function
          disableFuture={disableFuture}
          disablePast={disablePast}
          sx={{
            width: "100%", 
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              borderColor: error || showError? "#d73f4a": "#919EAB",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: error || showError? "#d73f4a": "#919EAB",
            },
            "& .MuiOutlinedInput-input": {
              color: "#161313",
            },
            "& .MuiOutlinedInput-notchedOutline.Mui-focused": {
              borderColor:  "#919EAB",
            },
            "& .MuiInputBase-root.Mui-focused": {
              borderColor:  "#919EAB",
              color: "#919EAB"
            },

            "& label.Mui-focused": {
              color: "#919EAB",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& fieldset": {
                borderColor: error || showError? "#d73f4a": "#919EAB",
              },
            }, 
            "& .MuiInputLabel-root": {
              color: error || showError? "#d73f4a": "#919EAB",
               
            }, 
          }}
          slots={{
            openPickerIcon: CalenderIcon,
          }}
        />
      </LocalizationProvider>
      {(error || showError) && (
        <div className="text-xs text-[#dd3636] mt-[3px] mx-3">
          {errorMessage}
        </div>
      )}
    </>
  );
};

export default CustomDatePicker;
