import {instance} from "src/services/apiService";

// Function to dynamically get headers
const getHeaders = () => ({
  SessionIdentifier: localStorage.getItem("token"),
});

let successSteps = {
  getStepsStatus: (data:any) => {
    return instance({
      url: `/api/onboarding/get/tenant/status/${data}`,
      method: "get",
      headers: getHeaders(),
    });
  },
  getSliderImages: () => {
    return instance({
      url: "/api/onboarding/get/slider/images",
      method: "get",
      headers: getHeaders(),
    });
  },
};
export default successSteps;
