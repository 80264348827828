import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "src/components/CustomButton";
import {
  fetchPlansData,
  setBack,
  setCurrency,
  setEmployeeCount,
  setLoaded,
  setPlansData,
  setPrevPostPlan,
} from "src/redux/reducers/pickPlan";
import { setActiveStep } from "src/redux/reducers/stepsReducer";
import PlanSelect from "src/components/PlanPage/PlanSelect";
import { Slider } from "@mui/material";
import ModuleAccordian from "src/components/Accordian/ModuleAccordian";
import PlanTab from "src/components/PlanPage/PlanTab";
import Cart from "src/components/Cart";
import OtherAddons from "src/components/PlanPage/OtherAddons";
import Loader from "src/components/Loader";
import { setAuth, setError } from "src/redux/reducers/auth";
import ErrorImg from "src/assets/images/warning.svg";

const Step3 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderPlan = useSelector(
    (state: any) => state.plans.choosePlan.dataToRender
  );

  const plans = useSelector((state: any) => state.plans.choosePlan);
  const postPlan = useSelector((state: any) => state.plans.choosePlan.postData);

  const [totalEmp, setTotalEmp] = React.useState<number>(
    postPlan.employeeCount
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [planExist, setPlanExist] = React.useState<boolean>(false);

  // ------- set employee in company ----
  const empInCompany = (value: number | number[]) => {
    if (typeof value === "number") {
      dispatch(setEmployeeCount(value));
      setTotalEmp(value);
    }
  };

  // --------- Route controls start -----
  const PreviousStage = () => {
    navigate("/onboarding/setting-thing-up");
    moveToPreviousStep();
  };
  const moveToPreviousStep = () => {
    dispatch(setActiveStep({ stepId: 1 }));
    dispatch(setPrevPostPlan(postPlan));
    dispatch(setBack(true));
  };
  const NextStage = () => {
    setLoading(true);
    dispatch(setActiveStep({ stepId: 3 }));
    navigate("/onboarding/support-implementation");
    setLoading(false);
  };
  // --------- Route controls end -----

  //   ----------- useEffect start ---------

  //   --------- plan data api ---------
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchPlansData();
        if (data) {
          dispatch(setLoaded(false));
          const plansArray = Object.values(data);
          // Check if the array contains plans or not
          const plansExist =
            Array.isArray(plansArray) &&
            plansArray.every(
              (item) => typeof item === "object" && item !== null
            );
          if (plansExist) {
            setPlanExist(true);
            const resCurrency = plansArray[0]?.currency;
            dispatch(setCurrency(resCurrency));
            dispatch(setPlansData(data));
          } else {
            setPlanExist(false);
          }
        }
        setLoading(false);
      } catch (error: any) {
        // debugger;
        dispatch(setAuth({ isAuthenticated: false, token: null }));
        dispatch(setError(error.response.data));
        setLoading(false);
        navigate("/error");
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTotalEmp(plans.employeeCount);
    if(plans.prevState){
      setTotalEmp(plans.prevpostPlan.employee_count);
    }
    // eslint-disable-next-line
  }, [renderPlan]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-1 flex-col justify-start items-start shadow-custom  rounded-lg">
          {planExist && (
            <>
              <div className="w-full py-2 lg:py-4 lg:px-5 flex flex-col justify-start items-start border-dashed border-b-2 border-[#919EAB3D] ">
                <div className="text-pageHeading text-sm font-[400]">
                  Choose a Plan
                </div>
                <div className="text-lightText text-sm font-[400]">3 of 5</div>
              </div>

              <div className="w-full flex justify-between items-start border-dashed border-b-2 border-[#919EAB3D] h-[73vh] lg:h-[64vh] 2xl:h-[70vh] ">
                <div className="w-full lg:w-[69%] max-h-[100%]  overflow-y-auto pt-4 lg:pl-5 lg:pr-1 flex flex-col justify-start items-start gap-2">
                  {/* ------ description text --- */}
                  <div className="w-full  flex  justify-start items-center">
                    <div className="flex-1 flex flex-col justify-start items-start gap-1">
                      <div className="text-pageHeading text-base font-[600]">
                        Let’s choose your plan
                      </div>
                      <div className="text-lightText text-sm font-[400] break-words">
                        Plans and any additional features you need for your
                        business.
                      </div>
                    </div>
                  </div>
                  {/* --------- plan slider ------- */}
                  <div className="w-full flex flex-col justify-start items-start gap-4">
                    <PlanSelect upgrade={false}/>
                  </div>

                  {/* ------ Render modules and data for selected Plan --- */}
                  <div className="w-full pb-6">
                    {/* ---------- No of employees in company ------  */}
                    {renderPlan && renderPlan.is_free !== true && (
                      <div className="flex flex-col justify-center items-start gap-8">
                        <div className="text-black text-sm">
                          How many employees do you have?
                        </div>
                        <div className="w-full px-[25px] lg:pr-[25px]">
                          <Slider
                            defaultValue={totalEmp}
                            value={totalEmp}
                            step={10}
                            marks
                            min={10}
                            max={200}
                            valueLabelDisplay="on"
                            onChange={(event, newValue) =>
                              empInCompany(newValue)
                            }
                          />
                        </div>
                      </div>
                    )}

                    {/* ----- render the modules ---- */}
                    <ModuleAccordian />
                  </div>
                  {/* ----- render the Addons ---- */}
                  <div className="w-full ">
                    <OtherAddons />
                  </div>
                </div>

                <div className="w-full hidden  lg:flex flex-col gap-2 flex-1 relative pt-1  overflow-y-auto max-h-[100%]">
                  <div className="pt-[30px] px-2 flex justify-end items-end w-full">
                    <PlanTab />
                  </div>
                  <div className="overflow-y-auto pl-2 pr-1 max-h-[100%]">
                    <Cart />
                  </div>
                </div>
              </div>

              <div className="w-full px-2 lg:px-5 pt-3 pb-4 flex justify-between items-center">
                <CustomButton
                  variant="unfilled"
                  text={"Back"}
                  onClick={PreviousStage}
                />
                <CustomButton
                  variant="filled"
                  text={"Next"}
                  onClick={NextStage}
                />
              </div>
            </>
          )}
          {(!planExist && !loading) && (
            <div className="flex w-full flex-col justify-start items-center h-full">
              <div className="px-4 py-4 ">
                <img src={ErrorImg} alt="" className="h-[50vh]"/>
              </div>
              <div className="px-4 py-4 ">
                <div className="text-base text-pageHeading">Sorry! No Plan Exist</div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Step3;
