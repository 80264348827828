import React from "react";
import Logo from "src/assets/images/header_logo.svg";
import DashboardImg from "src/assets/images/Dashboard.svg";
import SwapImg from "src/assets/images/swap.svg";
import SupportImg from "src/assets/images/headphones.svg";
import UpgradeImg from "src/assets/images/upgrade.svg";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Navbar = () => {

  const is_free = useSelector((state: any) => state.subscribe.is_free);

  const navLinks = [
    {
      img: DashboardImg,
      path: "/dashboard",
      name: "Dashboard",
    },
    {
      img: SwapImg,
      path: "/swap-plan",
      name: "Swap Plan",
    },
    {
      img: UpgradeImg,
      path: "/upgrade-plan",
      name: "Upgrade Plan",
    },
  ];

  const filteredNavLinks = navLinks.filter(
    (link) => !(is_free && link.path === "/upgrade-plan")
  );

  return (
    <div className="w-[20vw] 2xl:w-[15vw] h-[100vh] bg-ri-secondary hidden lg:block">
      <div className="flex justify-center items-center py-4 w-full">
        <img src={Logo} alt="ResourceInn" />
      </div>

      <div className="flex flex-col justify-between items-start px-3 h-[calc(100vh-64px)] pb-8">
        <div className="w-full">
          {filteredNavLinks.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              className={({ isActive }) =>
                `flex justify-start items-center px-4 py-3 cursor-pointer gap-2 w-full ${
                  isActive ? "bg-[#997bbeb9] rounded-lg" : "text-white"
                }`
              }
            >
              <div className=" flex justify-center items-center">
                <img src={item.img} alt="" />
              </div>
              <div className="text-white text-base">{item.name}</div>
            </NavLink>
          ))}
        </div>
        <div className="w-full p-3 text-white">
          <div className="p-3 pt-8 w-full rounded-lg border border-white flex flex-col justify-start items-center gap-2 relative">
            <div className="p-3 rounded-full border border-white absolute top-[-30px] bg-ri-secondary">
              <img src={SupportImg} alt="" className="w-[30px]" />
            </div>
            <div className="text-sm font-semibold">Need Help? Contact Us!</div>
            <div className="text-center flex flex-col gap-2">
              <div className=" text-xs">
                If you have any questions or need assistance, our support team
                is here to help. Please feel free to reach out to us at:
              </div>
              <div className=" text-xs font-semibold">
              📞  1-800-123-4567
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
