import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutProps } from "./Layout";
import Navbar from "src/components/Navbar";
import { useSelector } from "react-redux";
import AppBarComponent from "src/components/AppBarComponent"
import { setLogout } from "src/redux/reducers/userReducer";
import { useDispatch } from "react-redux";

const AdminLayout: React.FC<LayoutProps> = ({ children }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedIn = useSelector((state: any) => state.userReducer.loggedIn);

  useEffect(() => {
    const tokenExist = sessionStorage.getItem("token");

    if (loggedIn && tokenExist) {
      // debugger;
      navigate("/dashboard");
    } else {
      // debugger;
      dispatch(setLogout(true));
        localStorage.clear();
        sessionStorage.clear();
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loggedIn && (
        <div className="flex justify-start items-start h-screen grow overflow-hidden">
          <Navbar />
          <div className="flex flex-col justify-start items-start grow">
            <AppBarComponent />
            <div className=" w-screen lg:w-[80vw] 2xl:w-[85vw] flex flex-1 py-[6px] overflow-hidden"> {children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminLayout;
