import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  loaded: boolean;
  isAuthenticated: boolean;
  token: string | null;
  error: string | null;
}

const initialState: AuthState = {
  loaded: false,
  isAuthenticated: false,
  token: null,
  error: null,
};

const authenticateUser = (data: any, state: any) => {
  let tempData = JSON.parse(JSON.stringify(state));
    state.isAuthenticated = data.isAuthenticated;
    state.token = data.token;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (
      state,
      action: PayloadAction<{ isAuthenticated: boolean; token: string | null }>
    ) => {
      authenticateUser(action.payload, state);
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setInitialAuth: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state = initialState;
      }
    },
  },
});

export const { setAuth, setError,setInitialAuth } = authSlice.actions;

export default authSlice.reducer;
