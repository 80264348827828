import { instance } from "src/services/apiService";

const getHeaders = () => ({
  SessionIdentifier: localStorage.getItem("token"),
});

let basicSetting = {
  getUserInfo: (data: any,token:any) => {
    return instance({
      url: `/api/onboarding/get/user/info`,
      method: "post",
      headers: token,
      data: data
    });
  },
  getInitialToken: (data: string) => {
    return instance({
      url: `/api/accessurl/${data}`,
      method: "get",
    });
  },
  getBasicSetting: () => {
    return instance({
      url: "/api/onboarding/get/gettingstarted",
      method: "get",
      headers: getHeaders(),
    });
  },
  getTimeZones: () => {
    return instance({
      url: "/api/onboarding/get/timezones",
      method: "get",
      headers: getHeaders(),
    });
  },
  domainValidate: (data: any) => {
    return instance({
      url: "/api/onboarding/validate/domain",
      method: "post",
      data: data,
      headers: getHeaders(),
    });
  },
  postGettingStarted: (data: any) => {
    return instance({
      url: "/api/onboarding/store/gettingstarted",
      method: "post",
      data: data,
      headers: getHeaders(),
    });
  },
};

export default basicSetting;
