import { useDispatch, useSelector } from "react-redux";
import Cart from "src/components/Cart";
import { setActiveStep } from "src/redux/reducers/stepsReducer";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./FormStyle.css";
import payments from "src/api/payment";
import { setSecret } from "src/redux/reducers/cartReducer";
import Loader from "src/components/Loader";
import CustomButton from "src/components/CustomButton";
import RILogo from "src/assets/images/ri_logo.svg";
import StripeLogo from "src/assets/images/stripe.svg";
import LinkLine from "src/assets/images/link.png";
import ShareImg from "src/assets/images/share.svg";
import EyeImg from "src/assets/images/eye.svg";
import ErrorImg from "src/assets/images/warning.svg";
import { useLocation } from "react-router-dom";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

if (!stripePublicKey) {
  throw new Error("Stripe public key is not defined in environment variables.");
}

const stripePromise = loadStripe(stripePublicKey);

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const upgrade = useSelector((state: any) => state.userReducer.forUpgrade);

  const [clientSecret, setClientSecret] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  const cartTotalAmount = useSelector(
    (state: any) => state.cart.cartTotalAmount
  );

  const postData = { amount: cartTotalAmount };

  // ----- api header for onboarding or upgrade -------
  const getHeaders = () => ({
    SessionIdentifier: localStorage.getItem("token"),
  });
  
  const bearerHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
  
  // ---------- api call ------------------------------
  const apiCall = () => {
    const headers = location.pathname.endsWith("/onboarding/checkout")
    ? getHeaders()
    : bearerHeaders();

    payments
    .getSecret(postData, headers )
      .then((res: any) => {
        setClientSecret(res.data.client_secret);
        dispatch(setSecret(res.data.client_secret));
        setLoading(false);
      })
      .catch((err: any) => {
        setErrorMsg(err.response.data);
        // debugger
        setErrorState(true);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    apiCall();
    // eslint-disable-next-line
  }, []);

  const moveToPreviousStep = () => {
    dispatch(setActiveStep({ stepId: 3 }));
  };

  const PreviousStage = () => {
    moveToPreviousStep();
    navigate("/onboarding/support-implementation");
  };

  const appearance = {
    theme: "stripe" as "flat" | "stripe" | "night" | undefined,
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {loading && !errorState && <Loader />}
      {!errorState && (
        <div
          className={`w-full flex flex-1 flex-col justify-start items-start shadow-custom h-fit rounded-lg ${
            upgrade ? "max-h-[72vh] overflow-y-auto" : ""
          }`}
        >
          <div className="flex w-full flex-1 flex-col justify-start items-start">
            {/* ------ Header having title and step count ---- */}
            {!upgrade && (
              <div className="w-full py-4 px-5 flex flex-col justify-start items-start border-dashed border-b-2 border-[#919EAB3D]">
                <div className="text-pageHeading text-sm font-[400]">
                  Payment
                </div>
                <div className="text-lightText text-sm font-[400]">5 of 5</div>
              </div>
            )}

            {/* ------ details start ---- */}
            <div
              className={`w-full ${
                !upgrade ? "px-3" : "p-6"
              } flex justify-start items-start gap-6`}
            >
              {/* ------ description text --- */}
              <div className="w-full flex flex-col justify-start items-start gap-3">
                <div className="text-pageHeading text-base font-[600]">
                  ResourceInn uses{" "}
                  <span className="text-[#3366ff]">Stripe</span> to connect your
                  account
                </div>
                <div className="w-full flex flex-col justify-start items-start break-words p-5 gap-4 bg-[#F9FAFB] rounded-lg">
                  <div className="flex justify-start items-center gap-3">
                    <div>
                      <img src={RILogo} alt="ResourceInn" />
                    </div>
                    <div>
                      <img src={LinkLine} alt="------" />
                    </div>
                    <div>
                      <img src={StripeLogo} alt="Stripe" />
                    </div>
                  </div>
                  <div className="flex justify-start items-start gap-2">
                    <div>
                      <img src={ShareImg} alt="" />
                    </div>
                    <div className="flex flex-col justify-start items-start text-sm gap-1">
                      <div className="font-semibold text-pageHeading">
                        Connect effortlessly
                      </div>
                      <div className="font-medium text-lightText">
                        Stripe lets you securely connect your financial accounts
                        in seconds
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start gap-2">
                    <div>
                      <img src={EyeImg} alt="" />
                    </div>
                    <div className="flex flex-col justify-start items-start text-sm gap-1">
                      <div className="font-semibold text-pageHeading">
                        Your data belongs to you
                      </div>
                      <div className="font-medium text-lightText">
                        Stripe doesn’t sell personal info, and will only use it
                        with your permission
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  {clientSecret && (
                    <>
                      <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm />
                      </Elements>
                    </>
                  )}
                </div>
              </div>
              <div className="overflow-y-auto min-w-[20vw] 2xl:min-w-[15vw]">
                <Cart />
              </div>
            </div>
          </div>

          {!upgrade && (
            <div
              className={`w-full px-5 py-4 flex self-end justify-start items-center border-dashed border-t-2 border-[#919EAB3D] `}
            >
              <CustomButton
                variant="unfilled"
                text={"Back"}
                onClick={PreviousStage}
              />
            </div>
          )}
        </div>
      )}
      {errorState && 
       <div className="flex w-full flex-col justify-start items-center h-full">
       <div className="px-4 py-4 ">
         <img src={ErrorImg} alt="" className="h-[50vh]"/>
       </div>
       <div className="px-4 py-4 ">
         <div className="text-base text-pageHeading">{errorMsg}</div>
       </div>
     </div>
      }
    </>
  );
};

export default Checkout;
