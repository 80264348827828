import React from "react";

const calender = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_540_846)">
        <path
          d="M15.1843 17.9998H4.81192C4.78312 17.9907 4.75473 17.9765 4.7251 17.9727C3.41284 17.8065 2.37105 16.8679 2.07471 15.5818C2.04675 15.4612 2.02463 15.3392 2 15.2181C2 12.1446 2 9.07143 2 5.99786C2.0096 5.96905 2.02421 5.94107 2.02838 5.91185C2.2208 4.46337 3.40658 3.34142 4.86033 3.24372C5.12871 3.22576 5.39918 3.24121 5.68592 3.24121C5.68592 3.03035 5.68508 2.84955 5.68592 2.66876C5.68842 2.28419 5.94511 2.00569 6.30031 1.99984C6.66051 1.994 6.93139 2.27793 6.93473 2.66625C6.9364 2.84747 6.93473 3.02826 6.93473 3.217H13.0607C13.0607 3.0316 13.0598 2.8554 13.0607 2.67961C13.0628 2.28586 13.322 2.00277 13.6822 2.00026C14.0432 1.99776 14.3074 2.2796 14.3099 2.67168C14.3112 2.85248 14.3099 3.03327 14.3099 3.22702C14.5002 3.22702 14.6709 3.22785 14.8421 3.22702C16.6727 3.21574 17.9871 4.66505 17.9925 6.27302C18.0025 9.16872 18.0013 12.0648 17.9925 14.9609C17.9883 16.361 16.9887 17.589 15.6238 17.9101C15.4782 17.9443 15.3308 17.9706 15.1843 18.0007V17.9998ZM3.24923 8.16702C3.24923 8.23216 3.24923 8.2831 3.24923 8.33404C3.24923 10.5111 3.2484 12.6882 3.24923 14.8653C3.24965 15.9359 4.05144 16.7459 5.12412 16.7472C8.37304 16.751 11.6215 16.751 14.8705 16.7472C15.9435 16.7459 16.7449 15.9363 16.7458 14.8657C16.747 12.6886 16.7462 10.5115 16.7458 8.33446C16.7458 8.27976 16.7412 8.22548 16.7382 8.16702H3.24923ZM5.68592 4.49093C3.79392 4.28299 3.05807 5.58407 3.27511 6.90477H16.732C16.9453 5.12268 15.7536 4.28842 14.3099 4.50095C14.3099 4.89971 14.3137 5.30056 14.3087 5.70098C14.3045 6.05089 14.0369 6.3152 13.6947 6.31895C13.3457 6.32313 13.0694 6.05841 13.0623 5.70265C13.0561 5.37989 13.0607 5.05671 13.0607 4.73395C13.0607 4.65252 13.0607 4.57152 13.0607 4.49051H6.93473C6.93473 4.89929 6.93849 5.29471 6.93348 5.69013C6.92931 6.03795 6.67637 6.30267 6.34455 6.31812C5.97934 6.33482 5.69134 6.06759 5.68675 5.69806C5.68216 5.29805 5.68592 4.89762 5.68592 4.49135V4.49093Z"
          fill="#637381"
        />
        <path
          d="M6.31536 13.686C6.51821 13.686 6.72106 13.6827 6.92349 13.6869C7.27701 13.694 7.54957 13.9687 7.54831 14.3119C7.54706 14.6489 7.28035 14.927 6.93518 14.9332C6.51946 14.9407 6.10333 14.9407 5.68762 14.9332C5.33743 14.9274 5.06237 14.638 5.06947 14.2998C5.07657 13.9616 5.34536 13.6944 5.69137 13.6869C5.89923 13.6823 6.10709 13.686 6.31536 13.686Z"
          fill="#637381"
        />
        <path
          d="M13.6959 13.6863C13.8988 13.6863 14.1016 13.6825 14.3041 13.6871C14.6559 13.6946 14.9305 13.9744 14.9264 14.3151C14.9222 14.6512 14.6538 14.9276 14.3086 14.9335C13.8929 14.9406 13.4768 14.941 13.0611 14.9335C12.7155 14.9272 12.4492 14.65 12.4479 14.3126C12.4463 13.9694 12.7184 13.6942 13.0719 13.6871C13.2798 13.6829 13.4876 13.6863 13.6959 13.6867V13.6863Z"
          fill="#637381"
        />
        <path
          d="M13.6917 9.99907C13.8946 9.99907 14.0974 9.99573 14.2999 9.9999C14.6526 10.0066 14.9276 10.283 14.9268 10.6246C14.926 10.9665 14.6526 11.2425 14.2978 11.2475C13.8925 11.253 13.4868 11.2525 13.0815 11.2475C12.7192 11.2434 12.4417 10.9611 12.4484 10.6145C12.455 10.2696 12.7268 10.0045 13.0836 9.99949C13.2865 9.99656 13.4893 9.99907 13.6917 9.99949V9.99907Z"
          fill="#637381"
        />
        <path
          d="M6.30195 11.2482C6.0991 11.2482 5.89625 11.2524 5.69382 11.2473C5.34155 11.2386 5.06691 10.9601 5.06941 10.6198C5.07192 10.2782 5.34739 10.0035 5.7005 9.99971C6.10578 9.99554 6.51148 9.99554 6.91676 9.99971C7.27195 10.0035 7.5445 10.2749 7.54784 10.6189C7.55118 10.9613 7.27905 11.2386 6.92552 11.2473C6.71766 11.2524 6.50981 11.2482 6.30153 11.2482H6.30195Z"
          fill="#637381"
        />
        <path
          d="M10.0042 9.99907C10.207 9.99907 10.4099 9.99573 10.6123 9.9999C10.9596 10.0074 11.2254 10.2734 11.2317 10.6137C11.238 10.9573 10.9692 11.2409 10.6161 11.2467C10.2003 11.2534 9.78421 11.2538 9.36849 11.2467C9.02415 11.2404 8.75577 10.9615 8.75452 10.6254C8.75327 10.283 9.02749 10.007 9.38018 9.9999C9.58804 9.99573 9.79589 9.99907 10.0042 9.99907Z"
          fill="#637381"
        />
        <path
          d="M9.99501 14.9356C9.79216 14.9356 9.58931 14.939 9.38688 14.9348C9.03252 14.9273 8.75789 14.6559 8.75455 14.3147C8.75121 13.9669 9.02877 13.6905 9.39314 13.6871C9.79342 13.6834 10.1937 13.6834 10.594 13.6871C10.9596 13.6905 11.2338 13.9627 11.2317 14.3135C11.2296 14.6579 10.9596 14.9277 10.6031 14.9352C10.4007 14.9398 10.1979 14.936 9.99501 14.936V14.9356Z"
          fill="#637381"
        />
      </g>
      <defs>
        <clipPath id="clip0_540_846">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default calender;
