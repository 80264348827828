import * as React from "react";
import Grid from "@mui/material/Grid";
import CustomTextField from "src/components/CustomTextField";
import LoaderTextField from "src/components/LoaderTextFeild";
import CircularProgress from "@mui/material/CircularProgress";
import CustomButton from "src/components/CustomButton";
import { useNavigate } from "react-router-dom";
import { setActiveStep } from "src/redux/reducers/stepsReducer";
import { useDispatch } from "react-redux";
import basicSetting from "src/api/basicSetting";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Loader from "src/components/Loader";
import { useDropzone } from "react-dropzone";
import { setError } from "src/redux/reducers/auth";
import { styled } from "@mui/material/styles";

interface ErrorsType {
  companyName?: string;
  email?: string;
  domain?: string;
  timeZone?: any;
}


const StyledLabel = styled("span")({
  "&::after": {
    content: "' * '",
    color: "red",
  },
});

 const Step1 = () => {
  const dispatch = useDispatch();
  // const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [errors, setErrors] = React.useState<ErrorsType>({});

  const markStep1AsCompleted = () => {
    dispatch(setActiveStep({ stepId: 1 }));
  };


  const validateForm = (field: string, value: any) => {
    let tempErrors: ErrorsType = { ...errors };

    switch (field) {
      case 'companyName':
        tempErrors.companyName = value ? '' : 'Company Name is required.';
        break;
      case 'email':
        tempErrors.email = value
          ? /\S+@\S+\.\S+/.test(value)
            ? ''
            : 'Email is invalid.'
          : 'Email is required.';
        break;
      case 'domain':
        tempErrors.domain = value && domainValid ? '' : 'Domain is required.';
        break;
      case 'timeZone':
        tempErrors.timeZone = value ? "" : 'Time Zone is required.';
        break;
      default:
        break;
    }

    setErrors(tempErrors);

    // Return true if no errors
    return Object.values(tempErrors).every((x) => x === '');
  };

  const [loading, setLoading] = React.useState<boolean>(false);
  const [timeZone, setTimeZone] = React.useState<any>({});
  const [timeZones, setTimeZones] = React.useState([]);
  const [companyName, setCompanyName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [domain, setDomain] = React.useState(companyName);
  // const [step1, setStep1] = React.useState({});
  const navigate = useNavigate();
  // const [apiStatus, setApiStatus] = React.useState(true);
  const [userTimeZone, setUserTimeZone] = React.useState("");
  const [domainResponse, setDomainResponse] = React.useState<boolean>(false);
  const [disableControls, setDisableControls] = React.useState<boolean>(false);
  const [domainLoader, setDomainLoader] = React.useState<boolean>(false);
  const [domainValid, setDomainValid] = React.useState<boolean>(false);
  const [domainMessage, setDomainMessage] = React.useState<boolean>(false);
  const [showSuggestion, setShowSuggestion] = React.useState<boolean>(false);
  const [suggestDomain, setSuggestDomain] = React.useState<string[]>([]);
  const [domainLimit, setDomainLimit] = React.useState<boolean>(false);

  // const [preSelectedImage, setPreSelectedImage] = React.useState<string | null>(
  //   null
  // );
  // const [selectedImage, setSelectedImage] = React.useState<File | null>(null);
  const [selectedLogo, setSelectedLogo] = React.useState<string | null>(null);

  // -------- Image helper functions -----------
  const [preview, setPreview] = React.useState<string | ArrayBuffer | null>(
    null
  );
  const [fileReader, setFileReader] = React.useState<FileReader | null>(null);

  const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
    const file = acceptedFiles[0];
    // setSelectedImage(file);

    const reader = new FileReader();
    setFileReader(reader);

    reader.onload = () => {
      const base64Data = reader.result as string;
      setSelectedLogo(base64Data);
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone({
      accept: {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      },
      onDrop,
    });

  const handleRemoveImage: () => void = () => {
    if (fileReader) {
      setFileReader(null);
    }
    setPreview(null);
    setSelectedLogo(null);
  };
   

  const apiCall = () => {
    // setLoading(true);
    setDisableControls(true);
    basicSetting.getTimeZones().then((result: any) => {
      setTimeZones(result.data?.timezone);
      if (result.data?.selectedTimeZone) {
        setTimeZone(result.data?.selectedTimeZone);
      }
    });
    basicSetting
      .getBasicSetting()
      .then((result: any) => {
        if (result.data.data && result.data.data.company_name) {
          setCompanyName(result.data.data.company_name);
          setEmail(result.data.data.official_email);
          if (result.data.data.time_zone) {
            setTimeZone(result.data.data.time_zone);
            setUserTimeZone(result.data.data.time_zone.id);
          }
          if (result.data.data.logo_image) {
            // setPreSelectedImage(result.data.data.logo_image);
          }
          if (result.data.data.domain_input) {
            const removeSpecialChar = result.data.data.domain_input.toLowerCase().replace(/\s+/g, "")
            setDomain(removeSpecialChar);
            setDomainLoader(true);
            delayedLog(removeSpecialChar);
          } else {
            setDomain(result.data.data.company_name);
            setDomainLoader(true);
            delayedLog(result.data.data.company_name);
          }
          setDomainResponse(true);
        } else {
          // setLoading(false);
        }
        // setLoading(false);
        setDisableControls(false);
      })
      .catch((error) => {
        // setLoading(false);
        setDisableControls(false);
      });
  };

  const NextStage = () => {
    // if (validateForm()) {
      if (validateForm('companyName', companyName) &&
      validateForm('email', email) &&
      validateForm('timeZone', timeZone) &&
      validateForm('domain', domainResponse) 
    ) 
      {
      setLoading(true); 
      basicSetting
        .postGettingStarted({
          company_name: companyName,
          official_email: email,
          timezone_id: userTimeZone,
          domain: domain,
          logo: selectedLogo,
        })
        .then((res) => {
          markStep1AsCompleted();
          navigate("/onboarding/setting-thing-up");
          setLoading(false);
        })
        .catch((error: any) => { 
          dispatch(setError(error.response.data));
          navigate("/error");
          console.error("Error fetching plans data:", error);
          throw error;
        });
    }
   };

  const generateDomain = (companyName: string) => {
    // Convert company name to lowercase and remove spaces
    // return companyName.toLowerCase().replace(/\s+/g, "");
    const res =  companyName.toLowerCase().replace(/\s+/g, "").replace(/[^a-z0-9]/gi, "");;
    // debugger
    return res
  };

  // Function to handle changes in company name
  const handleCompanyNameChange = (value: string) => {
    setCompanyName(value);
    const domainName = generateDomain(value);

    setDomain(domainName);
    setDomainLoader(true);
    delayedLog(domainName);
    setCompanyName(value);
  };

  useEffect(() => {
    const domainName = generateDomain(domain);

    setDomain(domainName);
  }, [domain]);

  const debounce = (func: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Function to handle changes in the domain name
  const handleChange = (value: string) => {
    setDomain(value);
    setDomainLoader(true);
    setDomainResponse(false);
    delayedLog(value);
  };

  const delayedLog = React.useMemo(
    () =>
      debounce((value: string) => {
        if (value !== "") {
          setDomainResponse(false);
          setDomainLimit(false);
          if (value.length < 16) {
            let dataObj = {
              domain: value,
            };
            basicSetting.domainValidate(dataObj).then((res) => {
              setDomainMessage(res.data.message);
              setDomainValid(res.data.status);
              setDomainLoader(false);
              setShowSuggestion(false);
              setDomainResponse(true);
              if (res.data.domains && res.data.domains.length) {
                setShowSuggestion(true);
                setSuggestDomain(res.data.domains);
              }
            });
          } else {
            setDomainLimit(true);
            return;
          }
        } else {
          setDomainLimit(true);
          setDomainLoader(false);
          return;
        }
      }, 1500),
    []
  );

  const handleTimeZoneChange = (event: React.SyntheticEvent, value: any) => {
    if(value){
      setUserTimeZone(value.id);
    }
    setTimeZone(value);
  };

  useEffect(() => {
    apiCall();
    // setApiStatus(true);
  }, []);

  //   ------- disable control buttons while api wait ----
  useEffect(() => {
    setDisableControls(disableControls);
  }, [disableControls]);

  //   ------- validation ----  
  useEffect(() => {
    validateForm('companyName', companyName);
    // eslint-disable-next-line
  }, [companyName]);

  useEffect(() => {
    validateForm('email', email);
    // eslint-disable-next-line
  }, [email]);

  useEffect(() => {
    validateForm('domain', domainResponse);
    // eslint-disable-next-line
  }, [domainResponse]);

  useEffect(() => {
    validateForm('timeZone', timeZone);
    // eslint-disable-next-line
  }, [timeZone]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-1 flex-col justify-start items-start shadow-custom rounded-lg">
          <div className="flex w-full flex-1 flex-col justify-start items-start max-h-[81vh] lg:max-h-[75vh] 2xl:max-h-[70vh]">
            {/* ------ Header having title and step count ---- */}
            <div className="w-full py-2 lg:py-4 lg:px-5 flex flex-col justify-start items-start border-dashed border-b-2 border-[#919EAB3D]">
              <div className="text-pageHeading text-sm font-[400]">
                Getting Started
              </div>
              <div className="text-lightText text-sm font-[400]">1 of 5</div>
            </div>

            {/* ------ details start ---- */}
            <div className="w-full py-4 lg:p-5 lg:py-4 flex flex-col justify-start items-start gap-3 2xl:gap-6 overflow-y-auto">
              {/* ------ description text --- */}
              <div className="w-full flex flex-col justify-start items-start gap-1">
                <div className="text-pageHeading text-base font-[600]">
                  Let’s start with your company?
                </div>
                <div className="text-lightText text-xs md:text-sm font-[400] break-words">
                  Provide your company name, and we will generate a domain name
                  for you. Feel free to edit it and proceed with verification.
                </div>
              </div>

              {/* --- User Input modals startFormLabel --- */}
              <Grid container spacing={2}>
                {/* First Row */}
                <Grid item xs={12} sm={6} xl={4}>
                  <CustomTextField
                    label="Company Name"
                    fullWidth
                    required
                    value={companyName}
                    onChangeValue={handleCompanyNameChange}
                    setValue={setDomain}
                    error={Boolean(errors.companyName)}
                    helperText={errors.companyName}
                  />
                </Grid>
              
                <Grid item xs={12} sm={6} xl={4}>
                  <div className="text-sm text-[#c4c4c4] flex flex-col justify-between items-center border border-[#97a0c4ca] rounded-lg cursor-pointer">
                    {!preview && (
                      <div {...getRootProps()} className="py-[16.5px] px-5 w-full text-center">
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <p>
                            Drag 'n' drop company logo here, or click to select
                            files
                          </p>
                        )}
                      </div>
                    )}
                    {preview && (
                      <>
                        <p className="py-3">
                          <img
                            src={preview as string}
                            alt="Upload preview"
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                        </p>
                        <button onClick={handleRemoveImage} className="text-xs">
                          Remove
                        </button>
                      </>
                    )}
                  </div>
                </Grid>
                {/* Second Row */}
                <Grid item xs={12} sm={6} xl={4}>
                  <CustomTextField
                    label="Official Email"
                    fullWidth
                    required
                    value={email}
                    defaultValue={email}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    onChangeValue={setEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={timeZones}
                    getOptionLabel={(option: any) =>
                      option.full_timezone ? option.full_timezone : ""
                    }
                    value={timeZone ? timeZone : ""}
                    onChange={handleTimeZoneChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <StyledLabel>
                            Time Zone
                          </StyledLabel>
                        }
                        error={Boolean(errors.timeZone)}
                        helperText={errors.timeZone}
                      />
                    )}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                      },
                      "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
                        color: "#919EAB",
                      },
                      "& .MuiOutlinedInput-input": {
                        color: "#161313",
                      },
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#919EAB",
                        },
                      },
                      "& .Mui-MuiInputLabel": {
                        "& .Mui-focused": {
                          borderColor: "#919EAB !important",
                          backgroundColor: "#919EAB !important",
                        },
                      },
                      "& .Mui-MuiInputLabel-root": {
                        "& .Mui-focused": {
                          backgroundColor: "#919EAB",
                          color: "#919EAB !important",
                        },
                      },
                      "& .Mui-focused": {
                        borderColor: "#919EAB !important",
                        color: "#919EAB !important",
                      }, 
                    }}
                  />
                </Grid>
              </Grid>

              {/* ------ domain provider ---- */}
              <div className="w-full p-4 flex justify-start flex-col items-start gap-3 bg-[#F9F6FD] rounded-lg">
                <div className="text-lightText text-xs">
                  Your portal can be accessed through this domain link.
                </div>
                <div className="flex justify-start items-start gap-2">
                  <div className="text-base text-pageHeading mt-[18px] ">
                    https://
                  </div>

                  <div className="relative max-w-[170px]">
                    <div className="relative flex">
                      <LoaderTextField
                        label="Domain"
                        companyDomain={handleChange}
                        value={domain}
                        defaultValue={domain}
                        onChangeValue={handleChange}
                        error={Boolean(errors.domain)}
                        required={true}
                      />

                      {domainLoader && domain !== "" && (
                        <div className="absolute top-4 right-3 ">
                          <CircularProgress color="secondary" size="1.5rem" />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="text-base text-pageHeading mt-[18px] ">
                    .resourceinn.com
                  </div>
                </div>
                <div>
                  
                  {domain !== "" && !domainLimit && domainResponse && (
                    <div
                      className={`text-xs ${
                        domainValid ? "text-[#23ad00]" : "text-[#e20100]"
                      }`}
                    >
                      {domainMessage}
                    </div>
                  )}
                  <div className="text-xs  text-[#e20100]">
                    {domain === ""
                      ? "Domain is Required"
                      : domainLimit
                      ? "Domain cannot be more than 15 letters"
                      : ""}
                  </div>
                  {showSuggestion && (
                    <div className="flex justify-start items-start gap-2 flex-wrap text-pageHeading text-base mt-2">
                      <span className="text-pageHeading text-xs mt-1">
                        Suggestions:{" "}
                      </span>
                      {suggestDomain.map((suggestion: any) => (
                        <button
                          className="bg-[#e6e3e3] text-pageHeading p-1 text-xs rounded-2xl"
                          key={suggestion.id}
                          onClick={() => {
                            setDomain(suggestion.name);
                            setShowSuggestion(false);
                            setDomainLoader(true);
                            delayedLog(suggestion.name)
                          }}
                        >
                          {suggestion.name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className={`w-full px-2 lg:px-5 py-4 flex self-end justify-end items-center border-dashed border-t-2 border-[#919EAB3D] ${
              disableControls ? "opacity-50 pointer-events-none" : ""
              }`}
              >
            <CustomButton variant="filled" text={"Next"} onClick={NextStage} type="button" disable={!domainValid}/>
          </div>
        </div>
      )}
    </>
  );
};

export default Step1; 
