import * as React from "react"; 
import DoneIcon from "@mui/icons-material/Done";
import { useSelector } from "react-redux";
import { Addon } from "src/types/planApi";

type SelectAddonFunction = (selectedAddon: Addon) => void;

interface OtherAddonCardProps {
  selectedAddons: boolean;
  addon: Addon;
  selectAddon: SelectAddonFunction;
}

const OtherAddonCard: React.FC<OtherAddonCardProps> = ({
  addon,
  selectedAddons,
  selectAddon,
}) => {
  const isSelected = selectedAddons;

  const renderPlan = useSelector(
    (state: any) => state.plans.choosePlan.dataToRender
  );

  return (
    <div
      className={`w-full flex justify-start items-center p-2  border rounded-xl cursor-pointer select-none h-full ${
        isSelected ? "border-ri-secondary shadow-dark" : "border-[#919EAB1F] "
      }`}
      onClick={() => selectAddon(addon)}
    >
      <div className="w-4">
        {isSelected && <DoneIcon sx={{ fontSize: "12px", color: "#835db2" }} />}
      </div>

      <div className="w-full flex-1 flex flex-col justify-center items-start">
        {/* ---- card header ---- */}
        <div className="w-full flex-1 flex flex-col justify-between items-center gap-1">
          <div className="w-full flex justify-start items-center gap-2">
            {addon.icon && (
              <div
                className={` p-2 rounded-full max-w-[70px] max-h-[70px] bg-[#f9fafb]`}
              >
                <img src={addon.icon} alt="" className="w-full h-full object-cover object-center rounded-full"/>
              </div>
            )}
            <div
              className={`flex flex-1 items-start  text-xs font-bold text-pageHeading`}
            >
              {addon.name}
            </div>
            <div className="flex justify-end items-center gap-[2px] text-pageHeading text-sm font-bold flex-wrap">
              {addon.is_free === 1 && <div>Free</div>}
              {addon.is_free === 0 && (
                <>
                <div className="text-xs">
                  {renderPlan.currency} {""} {addon.price}
                </div>
                  <div className="text-xs text-lightText">
                    ({addon.variation === "fixed" ? "Fixed" : "per"}{" "}
                    {addon.unit})
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={`w-full text-xs text-left text-desc `}>
            {addon.description}
          </div>
        </div>
      </div>

      <div className="w-4">
        {/* <Tooltip title={addon.info}>
          <IconButton>
            <img src={InfoImg} alt="info" />
          </IconButton>
        </Tooltip> */}
      </div>
    </div>
  );
};

export default OtherAddonCard;
