import React, { useEffect, useState } from "react";
import { Box, Grid, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import "src/index.css";
 import CustomCarousel from "src/components/Slider/custom.slider";
import successsteps from "src/api/successsteps";
import SuccessModal from "src/pages/Onboarding/payment/SuccessModal";
import Header from "src/components/Header";
import { useSelector } from "react-redux";
import DeployImg from "src/assets/images/deployed.svg";
import successSteps from "src/api/successsteps";

interface Stage {
  id: number;
  name: string;
  value: number;
  status: string;
}

interface Response {
  time: string;
  stage: Stage[];
}
 

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5, 
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#835db2" : "#835db2",
  },
}));

const initialResponse: Response = {
  time: "06 minutes 00 seconds",
  stage: [
    {
      id: 1,
      name: "Gathering Information",
      value: 100,
      status: "success",
    },
    {
      id: 2,
      name: "Setting Up",
      value: 0,
      status: "in progress",
    },
    {
      id: 3,
      name: "Configuring",
      value: 0,
      status: "pending",
    },
    {
      id: 4,
      name: "Deploying",
      value: 0,
      status: "pending",
    },
  ],
};

const LastStep: React.FC = () => {
  const [quickTipsResponse, setTipsQuickResponse] = React.useState([]);
  const post_data_id = localStorage.getItem('id');
  const [tenantID, setTenantID] = useState<string|null>('');
  const [loadImages, setLoadImages] = useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);

  const initialMinutes = 4;

  const [time, setTime] = useState<{ minutes: number; seconds: number }>({
    minutes: initialMinutes,
    seconds: 0,
  }); 

  // slider Images api call
  useEffect(() => {
    successsteps.getSliderImages().then((res) => {
      setTipsQuickResponse(res.data.images);
      setLoadImages(true);
    });
  }, []);

  // show completion pop up
  // useEffect(() => {
  //   const previousPageUrl = sessionStorage.getItem("PageUrl");
  //   if (previousPageUrl) {
  //     // Check if the previous page's URL ends with "/onboarding/checkout"
  //     const temp =
  //       previousPageUrl.endsWith("/onboarding/checkout") ||
  //       previousPageUrl.endsWith("/swap-plan");
  //     setShowPopup(temp);
  //   }
  // }, []);

  const upgrade = useSelector((state: any) => state.userReducer.forUpgrade);

  // steps completion logic
  const [response, setResponse] = useState<Response>(initialResponse);

  useEffect(() => {
    const stepDuration = 2 * 60 * 1000; // 2 minutes in milliseconds

    const startStepProgress = (index: number) => {
      const interval = setInterval(() => {
        setResponse((prevResponse) => {
          const newStage = [...prevResponse.stage];
          const currentStep = newStage[index];
          if (currentStep.value < 100) {
            currentStep.value += 100 / (stepDuration / 1000);
            currentStep.status = "in progress";
          } else {
            currentStep.value = 100;
            currentStep.status = "success";
            clearInterval(interval);
            if (index + 1 < newStage.length-1) {
              startStepProgress(index + 1);
            } else if (index === newStage.length - 2) {
              monitorDeploymentStatus(index);
            } else {
              onAllStepsCompleted();
            }
          }
          return { ...prevResponse, stage: newStage };
        });
      }, 1000);
    };

    const monitorDeploymentStatus = (index: number) => {
      debugger
      const checkDeployment = () => {
        successSteps.getStepsStatus(post_data_id)
          .then((res) => {
            const deploying = res.data.deploying;
            setResponse((prevResponse) => {
              const newStage = [...prevResponse.stage];
              const currentStep = newStage[index];
              if (deploying) {
                currentStep.value = 100;
                currentStep.status = "success";
                onAllStepsCompleted(); // All steps completed
              } else {
                currentStep.value = Math.min(currentStep.value + 100 / (stepDuration / 10000), 99); // Progress slowly, max 99%
                setTimeout(checkDeployment, 10000); // Re-check after 10 seconds
              }
              return { ...prevResponse, stage: newStage };
            });
          })
          .catch((error) => {
            console.error("Error checking deployment status:", error);
          });
      };
      checkDeployment();
    };
    // Start the progress from the second step (index 1)
    startStepProgress(1);
    // eslint-disable-next-line
  }, []);

  // Function to be called when all steps are completed
  const onAllStepsCompleted = () => {
    handleOpen();
  };

  // ------ Deployment Modal functions  -------
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  
  return (
    <>
      <div className="w-full">
        {upgrade && <Header />}
        <div className="w-full flex flex-1 flex-col justify-start items-start gap-5 rounded-lg  max-h-[calc(100vh-64px)] overflow-y-auto">
          <div className=" shadow-custom w-full py-5 px-6 flex flex-col justify-start items-start gap-3">
            <div className="text-pageHeading text-xl font-bold">Success</div>
            <div className="text-lightText text-sm w-full break-words">
              We have received your information. Your system is now being
              configured according to your preferences, and it will be ready in
               a while
            </div>
            {/* <div className="text-pageHeading font-semibold w-full text-center text-2xl">
              {isTimeUp
                ? "Some time"
                : `${time.minutes} minutes ${time.seconds} seconds`}

            </div> */}
            <div className="w-full  pt-4">
              <Grid container spacing={2}>
                {response.stage.map((item) => (
                  <Grid item xs={3}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={item.value}
                    />
                    <div className="flex flex-col pt-4 gap-2">
                      <div className="text-sm text-pageHeading">
                        0{item.id}: {item.name}
                      </div>
                      <div
                        className={`w-fit rounded-md text-xs font-bold capitalize px-2 py-1 ${
                          item.status === "success"
                            ? "text-[#00AB55] bg-[#00AB5529]"
                            : item.status === "in progress"
                            ? "text-[#00B8D9] bg-[#00B8D929]"
                            : item.status === "pending"
                            ? "text-[#B76E00] bg-[#FFAB0029]"
                            : "text-[#b7000f] bg-[#b7000f58]"
                        }`}
                      >
                        {item.status}
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>

          {/* ------------ Quick Tips ------------------ */}
          {loadImages && quickTipsResponse.length !== 0 && (
            <div className="w-full flex flex-col justify-start items-start bg-[#F9F6FD] px-6 pt-5 pb-2 flex-grow rounded-lg mb-2">
              <div className="flex flex-col justify-start items-start gap-2 w-full relative">
                <div className="text-[#835db2] text-base font-semibold">
                  Quick Tips
                </div>

                <CustomCarousel>
                  {quickTipsResponse.map((item: any, index) => {
                    return (
                      <div className="text-[#835db2] text-sm flex flex-col justify-start items-start gap-2 w-full">
                        <div className="max-w-[75%] 2xl:max-w-full">{item.info}</div>
                        <div className="w-full flex justify-center items-center">
                          <img
                            key={index}
                            src={item.imgURL}
                            alt={item.imgAlt}
                          />
                        </div>
                      </div>
                    );
                  })}
                </CustomCarousel>
              </div>
            </div>
          )}
        </div>
        {/* ------ will show popup only if payment is done ----- */}
        {/* {showPopup && <SuccessModal />} */}
        {/* <SuccessModal /> */}
      </div>

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col justify-start items-center w-full gap-3 py-2">
            <div className="w-1/4">
              <img src={DeployImg} alt="Done" />
            </div>
            <div className="flex flex-col w-full gap-1">
              <div className="text-pageHeading font-semibold text-base text-center">
                Congratulations! 🎉 <br/>Your subscription is now active.
              </div>
              <div className="text-lightText text-xs text-center ">
                You will receive your credentials at the email address you
                provided within the next few minutes. 
              </div>
              <div className="text-lightText text-xs text-center "> 
                Thank you for choosing our service! If you have any questions or
                need further assistance, please contact our support team. 
              </div>
              <div className="text-pageHeading text-sm text-center font-semibold"> 
                Enjoy your experience!
              </div>
            </div>
          </div>
        </Box>
      </Modal> */}
    </>
  );
};

export default LastStep;
