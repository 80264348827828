import * as React from "react";
 import FreeImg from "src/assets/images/free_support.svg";
import PremiumImg from "src/assets/images/premium_support.svg";
import Radio from "@mui/material/Radio";
import CheckImg from "src/assets/images/check_icon.svg";
import InfoImg from "src/assets/images/info.png";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ExcImg from "src/assets/images/exclusive.svg";
 import { setSupport } from "src/redux/reducers/pickPlan";
import { useDispatch, useSelector } from "react-redux";
import { SupportCardType, SupportFeatures } from "src/types/planApi";
interface SupportCardProps {
  data: SupportCardType;
  selectedSupport: string;
  setSelectedSupport: React.Dispatch<React.SetStateAction<string>>;
}

const ServicesCard: React.FC<SupportCardProps> = ({
  data,
  selectedSupport,
  setSelectedSupport,
}) => {
  const dispatch = useDispatch();

 
  const postPlan = useSelector((state: any) => state.plans.choosePlan.postData);

  const handleSupportChange = (obj: any) => {
    if (obj) {
      setSelectedSupport(obj.name);
      const supportObj = {
        type: "support",
        plan: obj,
      };
      dispatch(setSupport(supportObj));
    }
  };

 

//   ${
//   data.plan_type === "freemium"
//     ? "border-green-600"
//     : data.plan_type === "exclusive"
//     ? "border-[#ffb92c]"
//     : "border-[#835db2]"
// }
  return (
    <div
      className={`w-full  h-full cursor-pointer flex flex-col justify-start items-start gap-1 py-3 px-4 border rounded-lg relative ${selectedSupport === data.name? "border-[#835db2]" : "border-gray"}`}
      onClick={() => handleSupportChange(data)}
    > 
      <div className="w-full flex flex-col justify-center items-center gap-2">
        <div className="w-full flex justify-between items-center">
          <div className="flex justify-start items-center gap-3">
            <img src={ data.plan_type === "freemium"
                  ? FreeImg
                  : data.plan_type === "exclusive"
                  ? ExcImg
                  : PremiumImg} alt="" />
            <div className="text-pageHeading text-sm font-[400] capitalize">
              {data.name}
            </div>
          </div>
          <Radio
            checked={selectedSupport === data.name}
            value={data.name}
            sx={{
              "&.Mui-checked": {
                color: "#835db2"  
              },
              // "&.Mui-checked": {
              //   color: data.plan_type === "exclusive"  ? "#ffb92c" : data.plan_type === "premium" ? "#835db2" : 
              //   undefined,
              // },
              "& .MuiSvgIcon-root": {
                fontSize: 28,
              },
            }}
            // color={data.plan_type === "freemium" ? "success" : undefined}
          />
        </div>
        <div className="w-full flex flex-col justify-center items-start">
          <div className="w-full flex justify-start items-center gap-2 pl-1">
            <div className="text-pageHeading text-xl font-bold">
              {data.amount > 0 ?`${postPlan.currency}${data.amount}`: "Free"}
            </div>
            <div className="text-lightText text-xs font-[400] pb-[1px]">
              {data.frequency === "onetime" ? "One-time" : "Per month"}
            </div>
          </div>
          <div className="text-pageHeading text-xs font-[500] capitalize flex flex-col justify-center items-start w-full">
            {data.features.map((feature: SupportFeatures, index: number) => (
              <div
                className={`w-full flex justify-between items-center `}
                key={index}
              >
                <div className="flex justify-start items-center gap-1">
                  <img src={CheckImg} alt="" />
                  <div className="">{feature.description}</div>
                </div>
                <div className="flex justify-end items-ceter">
                  <Tooltip title={feature.info}>
                    <IconButton>
                      <img src={InfoImg} alt="" className="w-[20px]" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
