import React, { ReactNode } from "react";
import Header from "src/components/Header";
import Sidebar from "src/components/Sidebar";

import { useSelector } from "react-redux";

export interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  let userAuth = useSelector((state: any) => state.auth.isAuthenticated);
  const [authenticated, setAuthenticated] = React.useState<boolean>(userAuth);

  React.useEffect(() => {
    setAuthenticated(userAuth);
  }, [userAuth]);

  return (
    <>
      <div className="w-full font-customFont">
        <Header />
        <div className="w-full flex justify-start items-start h-[calc(100vh-64px)] overflow-hidden">
          {authenticated && (
            <div className="hidden lg:block">
              <Sidebar />
            </div>
          )}
          <div
            className={`${
              authenticated ? "w-[80%] " : "w-[100%] "
            } flex flex-1 px-[6px] lg:px-3 pt-1 pb-3 overflow-y-auto `}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
