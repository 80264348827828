import {instance} from "src/services/apiService";

const getHeaders = () => ({
    SessionIdentifier: localStorage.getItem("token"),
  });

let plansApiData = {
    getPlansData: () => {
        return instance({
            url: '/api/onboarding/get/plans',
            method: 'get',
            headers: getHeaders()
        });
    },
}
export default plansApiData;
