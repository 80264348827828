import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import CustomButton from "src/components/CustomButton";
import CustomTextField from "src/components/CustomTextField";
import dayjs, { Dayjs } from "dayjs";
import CustomDatePicker from "src/components/CustomDatePicker";
import GenderSelect from "./GenderSelect";
import CustomPasswordField from "src/components/CustomPasswordField";
import utc from "dayjs/plugin/utc";
import { useNavigate } from "react-router-dom";
import { setActiveStep } from "src/redux/reducers/stepsReducer";
import { useDispatch, useSelector } from "react-redux";
import settingUp from "src/api/settingUp";
import { Autocomplete, TextField } from "@mui/material";
import Loader from "src/components/Loader";
import { setError } from "src/redux/reducers/auth";
import { styled } from "@mui/material/styles";
import { setBack } from "src/redux/reducers/pickPlan";

// Apply the utc plugin
dayjs.extend(utc);

type TempValidate = {
  name?: string;
  job?: string;
  nationality?: string;
  dateOfBirth?: string;
  userName?: string;
  gender?: string;
  password?: string;
  confirmPassword?: string;
};

type NationalityDrop = {
  code: number;
  created_at: string | null;
  currency_code: string | null;
  currency_name: string | null;
  currency_symbol: string | null;
  id: number;
  is_active: boolean;
  iso2: string | null;
  iso3: string | null;
  name: string;
  nationality: string;
  updated_at: string | null;
};

const StyledLabel = styled("span")({
  "&::after": {
    content: "' * '",
    color: "red",
  },
});

const Step2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const plans = useSelector((state: any) => state.plans.choosePlan);


  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [nationality, setNationality] = React.useState<any>();
  const [nationalityDropDown, setNationalityDropDown] = React.useState<
    NationalityDrop[]
  >([]);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [dateOfBirth, setDateOfBirth] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [errors, setErrors] = React.useState<TempValidate>({});
  const [step2, setStep2] = React.useState({});


  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue !== null) {
      // Convert the selected date to UTC before updating the state
      const formattedDate = newValue.format("DD-MM-YYYY");
      // Convert the formatted date to UTC
      const utcDate = dayjs.utc(formattedDate, "DD-MM-YYYY");
      setSelectedDate(utcDate);
      setDateOfBirth(formattedDate);
    } else {
      setSelectedDate(null);
      setDateOfBirth("");
    }
  };

  const handleNationalityChange = (event: React.SyntheticEvent, value: any) => {
    setNationality(value);
  };

  function isEmpty(obj:any) {
    return Object.keys(obj).length === 0;
  }
  
  const storeSettingUp = () => {
    settingUp
      .postSettingUp({
        name: name,
        job_title: jobTitle,
        country_id: nationality.id,
        gender: gender,
        email: userName,
        password: password,
        confirm_password: confirmPassword,
        date_of_birth: dateOfBirth,
      })
      .then((res) => {
        console.log(plans)
        if (plans.prevpostPlan !== undefined && !isEmpty(plans.prevpostPlan)) {
          dispatch(setBack(false));
        }
        
        markStep2AsCompleted();
        navigate("/onboarding/pick-a-plan");
        setLoading(false);
      })
      .catch((error: any) => { 
        dispatch(setError(error.response.data));
        navigate("/error");
        console.error("Error fetching plans data:", error);
        throw error;
      });
  };

  useEffect(() => {
    // setLoading(true);

    settingUp.getNationality().then((res) => {
      setNationalityDropDown(res.data.data.original);
    });

    Promise.all([settingUp.getSettingUp()])
      .then(([settingUpResponse]) => {
        setName(settingUpResponse.data.data?.name);
        const utcDate = dayjs.utc(
          settingUpResponse.data.data?.date_of_birth,
          "DD-MM-YYYY"
        );
        setGender(settingUpResponse.data.data?.gender);
        setNationality(settingUpResponse.data.data?.country);
        setSelectedDate(utcDate);
        setDateOfBirth(settingUpResponse.data.data?.date_of_birth);
        setJobTitle(settingUpResponse.data.data?.job_title);
        setUserName(settingUpResponse.data.data?.email);
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  useEffect(() => {
    setGender(gender);
  }, [gender]);

  
  const validateForm = (field: string, value: any) => {
    let tempErrors: TempValidate = { ...errors };

    switch (field) {
      case 'name':
        tempErrors.name = value ? '' : 'Name is required.';
        break;
      case 'job':
        tempErrors.job = value ? '' : 'Job Title is required.';
        break;
      case 'nationality':
        tempErrors.nationality = value ? '' : 'Nationality is required.';
        break;
      case 'dateOfBirth':
        tempErrors.dateOfBirth = value ? '' : 'Date of Birth is required.';
        break;
      case 'userName':
        tempErrors.userName = value ? '' : 'Email or Username is required.';
        break;
      case 'gender':
        tempErrors.gender = value ? '' : 'Gender is required.';
        break;
      case 'password':
        tempErrors.password = value
          ? value.length >= 8
            ? ''
            : 'Password must be at least 8 characters long.'
          : 'Password is required.';
        break;
      case 'confirmPassword':
        tempErrors.confirmPassword = value === password ? '' : 'Passwords do not match.';
        break;
      default:
        break;
    }

    setErrors(tempErrors);

    // Return true if no errors
    return Object.values(tempErrors).every((x) => x === '');
  };


  const markStep2AsCompleted = () => {
    dispatch(setActiveStep({ stepId: 2 }));
  };

  const NextStage = () => {
      if (validateForm('name', name) &&
          validateForm('jobTitle', jobTitle) &&
          validateForm('nationality', nationality) &&
          validateForm('dateOfBirth', dateOfBirth) &&
          validateForm('userName', userName) &&
          validateForm('gender', gender) &&
          validateForm('password', password) &&
          validateForm('confirmPassword', confirmPassword)) {
            setLoading(true); 
            storeSettingUp();
            setLoading(false);
        } else {
          setLoading(false);
        }
  };

  const moveToPreviousStep = () => {
    dispatch(setActiveStep({ stepId: 0 }));
  };

  const PreviousStage = () => {
    moveToPreviousStep();
    navigate("/onboarding/getting-started");
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    // Perform validation
    if (password !== value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match.",
      }));
    } else {
      // If there's no error, remove the error message for confirmPassword
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }
  };

  // --- validation ------ 

  useEffect(() => {
    validateForm('name', name);
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    validateForm('job', jobTitle);
    // eslint-disable-next-line
  }, [jobTitle]);

  useEffect(() => {
    validateForm('nationality', nationality);
    // eslint-disable-next-line
  }, [nationality]);

  useEffect(() => {
    validateForm('dateOfBirth', dateOfBirth);
    // eslint-disable-next-line
  }, [dateOfBirth]);

  useEffect(() => {
    validateForm('userName', userName);
    // eslint-disable-next-line
  }, [userName]);

  useEffect(() => {
    validateForm('gender', gender);
    // eslint-disable-next-line
  }, [gender]);

  useEffect(() => {
    validateForm('password', password);
    // eslint-disable-next-line
  }, [password]);

  useEffect(() => {
    validateForm('confirmPassword', confirmPassword);
    // eslint-disable-next-line
  }, [confirmPassword]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-1 flex-col justify-start items-start shadow-custom rounded-lg">
          {/* <div className="w-full flex flex-1 flex-col justify-start items-start shadow-custom h-[calc(100vh-100px)]"> */}
          <div className="flex w-full flex-1 flex-col justify-start items-start max-h-[81vh] overflow-y-auto">
            {/* ------ Header having title and step count ---- */}
            <div className="w-full py-2 lg:py-4 lg:px-5 flex flex-col justify-start items-start border-dashed border-b-2 border-[#919EAB3D]">
              <div className="text-pageHeading text-sm font-[400]">
                Setting you up
              </div>
              <div className="text-lightText text-sm font-[400]">2 of 5</div>
            </div>

            {/* ------ details start ---- */}
            <div className="w-full py-4 lg:p-6 pb-10 flex flex-col justify-start items-start gap-6">
              {/* ------ description text --- */}
              <div className="w-full flex flex-col justify-start items-start gap-1">
                <div className="text-pageHeading text-base font-[600]">
                  Let’s set you up in the system
                </div>
              </div>

              {/* --- User Input modals start --- */}
              <Grid container spacing={2}>
                {/* First Row */}
                <Grid item xs={12} sm={4}>
                  <CustomTextField
                    label="Name"
                    required
                    fullWidth
                    value={name}
                    onChangeValue={setName}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomTextField
                    label="Job Title"
                    fullWidth
                    value={jobTitle}
                    required
                    onChangeValue={setJobTitle}
                    error={Boolean(errors.job)}
                    helperText={errors.job}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={nationalityDropDown}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.nationality === value.nationality &&
                      option.name === value.name
                    }
                    value={nationality ? nationality : ""}
                    onChange={handleNationalityChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <StyledLabel>
                            Nationality
                          </StyledLabel>
                        }
                        error={Boolean(errors.nationality)}
                        helperText={errors.nationality}
                      />
                    )}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-input": {
                        color: "#161313",
                      },
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#919EAB",
                        },
                      },
                      "& .Mui-focused": {
                        borderColor: "#919EAB !important",
                        color: "#919EAB",
                      },
                      "& .Mui-MuiInputLabel": {
                        "& .Mui-focused": {
                          borderColor: "#919EAB !important",
                          backgroundColor: "#919EAB",
                        },
                      }, 
                    }}
                  />
                </Grid>
                {/* Second Row */}
                <Grid item xs={12} sm={4}>
                  <CustomDatePicker
                    label="Date of Birth"
                    disableFuture={true}
                    value={selectedDate}
                    onChange={handleDateChange}
                    showError={Boolean(errors.dateOfBirth)}
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} xl={4}> 
                    <GenderSelect
                      defaultValue={gender}
                      selectGender={setGender}
                      error={Boolean(errors.gender)}
                      helperText={errors.gender}
                    /> 
                </Grid>
              </Grid>

              {/* --- User credentials start --- */}
              <div className="w-full flex flex-col justify-start items-start gap-1">
                <div className="text-pageHeading text-base font-[600]">
                  Set your credentials
                </div>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <CustomTextField
                    label="Email or Username"
                    required
                    fullWidth
                    onChangeValue={setUserName}
                    value={userName}
                    defaultValue={userName}
                    error={Boolean(errors.userName)}
                    helperText={errors.userName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomPasswordField
                    label="Password"
                    fullWidth
                    prompt={false}
                    onChangeValue={setPassword}
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomPasswordField
                    label="Confirm Password"
                    fullWidth
                    onChangeValue={handleConfirmPasswordChange} // Corrected from setValueOfConfirmPassword to setConfirmPassword
                    value={confirmPassword} // Ensure you have this line to bind the input value to your state
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword}
                    prompt={false}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="w-full px-2 lg:px-5 py-4 flex justify-between items-center border-dashed border-t-2 border-[#919EAB3D]">
            <CustomButton
              variant="unfilled"
              text={"Back"}
              onClick={PreviousStage}
            />
            <CustomButton variant="filled" text={"Next"} onClick={NextStage} />
          </div>
        </div>
      )}
    </>
  );
};

export default Step2;
