import * as React from "react";
import Crown from "src/assets/images/crown.svg";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { Slider, Tooltip } from "@mui/material";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import { setModulesAndFeatures } from "src/redux/reducers/pickPlan";
import { useDispatch, useSelector } from "react-redux";
import { Addon, Module } from "src/types/planApi";
import { withStyles } from "@mui/styles";
import { useState, useEffect } from "react";

// Define custom styles for the Slider thumb
const CustomSlider = withStyles({
  disabled: {
    "& .MuiSlider-thumb ": {
      // backgroundColor: "#505c68", // Background color of the thumb
    },
    "& .MuiSlider-track": {
      // backgroundColor: "#e0e0e0",
    },
    "& .MuiSlider-root": {
      // backgroundColor: "#212b3612",
    },
  },
})(Slider);

interface AddProsProps {
  propAddons: Addon[];
  moduleName: string;
}

const AddPros: React.FC<AddProsProps> = ({ propAddons,moduleName }) => {
  const moduleAddons = propAddons;
  const dispatch = useDispatch();

  // --------------- redux seletor --------------------
  const plans = useSelector((state: any) => state.plans);
  const renderPlan = useSelector(
    (state: any) => state.plans.choosePlan.dataToRender
  );
  const postToData = useSelector(
    (state: any) => state.plans.choosePlan.postData
  );
  const newCurrency = useSelector(
    (state: any) => state.plans.choosePlan.currency
  );

  // --------------- state management --------------------
  const [checkedObjects, setCheckedObjects] = useState<Addon[]>([]);

  // ------ state for popover -----
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // const [editAnchorEl, setEditAnchorEl] = useState<HTMLButtonElement | null>(
  //   null
  // );
  //  ----- popover controls start ------

  // --------- Add popover controls start ------
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const openEdit = Boolean(editAnchorEl);
  // const idEdit = openEdit ? "simple-popover" : undefined;
  // --------- Add popover controls end ------

  const openAddPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeAddPopup = () => {
    setAnchorEl(null);
  };

  const getYearType = (data: string) => {
    switch (data) {
      case "yearly":
        return 12;
      case "monthly":
        return 1;
    }
  };

  // -------- add/remove addons controls ----
  const handleCheckboxChange = (obj: Addon) => {
    obj = {...obj}; 

    if(!obj.is_slider){
      if (obj.monthly_price === undefined) {
      obj.monthly_price = obj.price;
    }
    obj.price = obj.monthly_price * (getYearType(postToData.frequency) ?? 1);
    }
    const moduleIndex = postToData.modules.findIndex(
      (module: Module) => module.module_id === obj.module_id
    );
    const matchedModule = postToData.modules[moduleIndex];

    const addonIndex = matchedModule.addons.findIndex(
      (item: Addon) => item.addon_id === obj.addon_id
    ); 
    if (addonIndex === -1) {
      dispatch(
        setModulesAndFeatures({
          type: "pros",
          data: [...checkedObjects, obj],
          moduleIndex,
        })
      );
      setCheckedObjects([...checkedObjects, obj]);
    } else {
      dispatch(
        setModulesAndFeatures({
          type: "pros",
          data: checkedObjects.filter((item) => item.addon_id !== obj.addon_id),
          moduleIndex,
        })
      );
      setCheckedObjects(checkedObjects.filter((item) => item.addon_id !== obj.addon_id));
    }
  };

  // -------- check added addons ----
  const handlechecked = (addon: Addon) => {
    const moduleIndex = postToData.modules.findIndex(
      (item: Module) => item.module_id === addon.module_id
    );
    if (moduleIndex !== -1) {
      const matchedModule = postToData.modules[moduleIndex];

      const addonIndex = matchedModule.addons.findIndex(
        (item: Addon) => item.addon_id === addon.addon_id
      );

      if (addonIndex !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return;
    }
  };

  // --------- remove addon function ------
  const removeAddon = (module_id: number, key: string) => {
    const moduleIndex = plans.choosePlan.postData.modules.findIndex(
      (item: Module) => item.module_id === module_id
    );
    dispatch(
      setModulesAndFeatures({
        type: "pros",
        data: checkedObjects.filter((item) => item.key !== key),
        moduleIndex,
      })
    );
    setCheckedObjects(checkedObjects.filter((item) => item.key !== key));
  };

  // ------- addon slider change function ------
  const handleAddSliderChange = (
    event: Event,
    value: number | number[],
    addon: Addon
  ) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    const moduleIndex = plans.choosePlan.postData.modules.findIndex(
      (item: Module) => item.module_id === addon.module_id
    );
    const matchIndex = checkedObjects.findIndex(
      (item) => item.key === addon.key
    );

    const { totalPrice, monthly_price } = calculateTotalPrice(newValue, addon);


    if (matchIndex !== -1) {
      const updatedCheckedObjects = checkedObjects.map((item) =>
        item.key === addon.key
          ? {
              ...item,
              data: {
                ...item.data,
                selected: newValue,
                total_price: totalPrice,
                monthly_price: monthly_price,
              },
            }
          : item
      );
      dispatch(
        setModulesAndFeatures({
          type: "pros",
          data: updatedCheckedObjects,
          moduleIndex,
        })
      );
      setCheckedObjects(updatedCheckedObjects);
    }
  };

  // ----- Function to get the default value for the Slider
  const getDefaultValue = (addonItem: Addon) => {
    let foundItem;

    let matchedModule = postToData.modules.find(
      (item: Module) => item.module_id === addonItem.module_id
    );

    if (matchedModule) {
      let matchedAddon = matchedModule.addons.find(
        (item: Addon) => item.addon_id === addonItem.addon_id
      );
      if (matchedAddon) {
        foundItem = matchedAddon.data
          ? matchedAddon.data.selected
            ? matchedAddon.data.selected
            : matchedAddon.data.free_until
            ? matchedAddon.data.free_until
            : matchedAddon.data.min_value
          : undefined;
      } else {
        foundItem = addonItem.data
          ? addonItem.data.selected
            ? addonItem.data.selected
            : addonItem.data.free_until
            ? addonItem.data.free_until
            : addonItem.data.min_value
          : undefined;
      }
      return foundItem;
    }
    return 0;
  };

  // ------ calculate price for variation ---

  const calculateTotalPrice = (newValue: number, addon: Addon): { totalPrice: number, monthly_price: number } => {
    let monthly_price = addon.price;
    let totalPrice = addon.price;
    for (const slab of addon.data.slabs) {
      if (newValue >= slab.min && newValue <= slab.max) {
        totalPrice = slab.price * (newValue - addon.data.free_until);
        monthly_price = slab.price * (newValue - addon.data.free_until);
        break;
      }
    }
    if (addon.variation === "as_per_actual") {
      totalPrice = totalPrice * (getYearType(postToData.frequency) ?? 1);
    } 
    return {totalPrice,monthly_price};
  };

  // ----check if addon is added to enable slider ---
  const addonAdded = (addon: Addon) => {
    const moduleIndex = postToData.modules.findIndex(
      (module: Module) => module.module_id === addon.module_id
    );
    const matchedModule = postToData.modules[moduleIndex];
    if (matchedModule && matchedModule.addons.length > 0) {
      const addonIndex = matchedModule.addons.findIndex(
        (item: Addon) => item.addon_id === addon.addon_id
      );

      if (addonIndex !== -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  // -------- Update the state if any addon is pre selected----------
  useEffect(() => {
    let matchId: number | null = null;

    if (moduleAddons && moduleAddons.length > 0) {
      matchId = moduleAddons[0].module_id;
    }

    let matchPostMod: Module = postToData.modules.find(
      (item: Module) => item.module_id === matchId
    );
    
    if (matchPostMod && matchPostMod.addons.length > 0) {
      setCheckedObjects(matchPostMod.addons);
    } else {
      setCheckedObjects([]);
    }
    // eslint-disable-next-line
  }, [postToData]);

  return (
    <>
      <div className="flex flex-col w-full py-3 gap-2">
        {checkedObjects?.map((addon: Addon) => (
          <div
            className="bg-[#FBF7FF] text-pageHeading px-[6px]  flex justify-between items-center w-full rounded-lg relative"
            key={addon.key}
          >
            <div className="">
              <img src={Crown} alt="" className="w-[18px]" />
            </div>
            <Tooltip title={addon.name}>
              <button
                className="text-sm text-ellipsis overflow-hidden font-semibold flex-1 py-[8px] capitalize w-full truncate"
                // onMouseEnter={(e) =>
                //   handleOpenEditPopup(e, addon.id, addon ?? {})
                // }
                // onMouseLeave={handleCloseEditPopup}
              >
                {addon.is_slider !== 0
                  ? addon.data.selected
                    ? addon.data.selected
                    : addon.data.min_value
                  : ""}{" "}
                {addon.unit ? addon.unit + " - " : ""}
                {addon.name}
                {" - "}
                {newCurrency}{" "}
                {addon.data ? addon.data.total_price : addon.price}
              </button>
            </Tooltip>
            <button
              onClick={() => removeAddon(addon.module_id, addon.key)}
              className="flex justify-center items-center"
            >
              <ClearIcon sx={{ fontSize: "small" }} />
            </button>
          </div>
        ))}

        <button
          className="w-full border border-dashed border-[#919EAB7A] px-1 py-2 rounded-md flex justify-center items-center cursor-pointer mt-1"
          onClick={openAddPopup}
        >
          <AddIcon />
        </button>
      </div>

      {/* ---- Add Popover ---- */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeAddPopup}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root.MuiPopover-paper": {
            borderRadius: "10px",
          },
        }}
      >
        <div className="px-3 flex flex-col justify-between items-between bg-white rounded-lg z-10 max-w-[50vw] min-w-[40vw] ">
          <div className="flex flex-col justify-center items-start gap-2 py-2 w-full">
            <div className="text-lightText text-xs px-3 pt-3">
              Unlock advanced 
              <span className="capitalize text-sm px-1 font-semibold text-pageHeading">
              {moduleName} 
              </span>
              features to streamline your work with our Pro`s. 
            </div>
            <div className="flex flex-col justify-center items-start gap-2 w-full">
              {moduleAddons?.map((addonItem: Addon) => (
                <div
                  key={addonItem.key}
                  className="w-full flex justify-center items-start flex-col gap-3 bg-[#F9FAFB] px-3 pb-2 rounded-lg"
                >
                  <div className="w-full flex justify-between items-center">
                    <div className="flex justify-start items-center gap-2 ml-[-10px]">
                      <Checkbox
                        color="default"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            borderRadius: "8px",
                          },
                        }}
                        onChange={() => handleCheckboxChange(addonItem)}
                        checked={handlechecked(addonItem)}
                      />
                      <div className="text-sm text-pageHeading font-bold">
                        {addonItem.name}
                      </div>
                    </div>
                    <div className="text-pageHeading font-semibold text-sm">
                      {renderPlan.currency}
                      {addonItem.price}{" "}
                      <span className="text-xs text-lightText">
                        ({addonItem.variation === "fixed" ? "Fixed" : "per"}{" "}
                        {addonItem.unit})
                      </span>
                    </div>
                  </div>
                  <div className=" text-start text-lightText text-xs text-wrap w-94 -mt-4 pb-1">
                    {addonItem.description}
                  </div>

                  {addonItem.is_slider === 1 && (
                    <div className="w-full px-3">
                      <CustomSlider
                        value={getDefaultValue(addonItem)}
                        step={addonItem.data.steps}
                        min={addonItem.data.min_value}
                        max={addonItem.data.max_value}
                        marks
                        valueLabelDisplay="auto"
                        onChange={(event, value) =>
                          handleAddSliderChange(event, value, addonItem)
                        }
                        disabled={addonAdded(addonItem)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Popover>

 
    </>
  );
};

export default AddPros;
