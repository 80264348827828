import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExistingPlan } from "src/types/existingPlan";

const initialState = {
  loaded: false,
  error: "",
  data: {},
  is_free: false,
};

const subscribeSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSubscribe: (state, action: PayloadAction<ExistingPlan>) => {
      if (!state.loaded) {
        state.data = action.payload;
        state.loaded = true;
        state.is_free = action.payload.is_free ? true : false;
      }
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload ?? "";
    },
    setInitialSubscribe: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state = initialState;
      }
    },
  },
});

export const { setError, setSubscribe, setLoaded, setInitialSubscribe } =
  subscribeSlice.actions;

export default subscribeSlice.reducer;
