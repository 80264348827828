import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
interface GenderSelectProps {
  defaultValue?: string;
  helperText?: string;
  error: boolean;
  selectGender: (gender: string) => void;
}

const GenderSelect: React.FC<GenderSelectProps> = ({
  defaultValue,
  selectGender,
  helperText,
  error
}) => {
  const [showError, setShowError] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>(defaultValue || "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;
    setValue(newValue);
    selectGender(newValue);
  };

  React.useEffect(() => {
    setValue(defaultValue || "")
  }, [defaultValue])

  React.useEffect(() => {
    setShowError(error)
  }, [error])
  

  return (
    <>
      <div className="text-[#212B36]">
        Gender<span className="text-[#e20100] text-[14px]"> *</span>
      </div>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="gender-radio-buttons-group-label"
          name="gender-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="male"
            control={<Radio color="secondary" />}
            label="Male"
          />
          <FormControlLabel
            value="female"
            control={<Radio color="secondary" />}
            label="Female"
          />
          <FormControlLabel
            value="other"
            control={<Radio color="secondary" />}
            label="Other"
          />
        </RadioGroup>
      </FormControl>
      {showError && <div className="text-[#d32f2f] text-[12px]">{helperText}</div>}
    </>
  );
};

export default GenderSelect;
