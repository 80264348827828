import React from "react";
import { useSelector } from "react-redux";
import ErrorImg from "src/assets/images/warning.svg";

const NotFound: React.FC = () => {
  const err = useSelector((state: any) => state.auth.error);

  return (
    <div className="h-[calc(100vh-100px)] bg-white w-full flex flex-col justify-center items-center font-customFont">
      <div className="px-4 py-4 ">
        <img src={ErrorImg} alt="" />
      </div>
      <div className="px-4 py-4 text-pageHeading text-base ">{err}</div>
    </div>
  );
};

export default NotFound;
