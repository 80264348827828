import React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import FormHelperText from '@mui/material/FormHelperText';


interface CustomPasswordFieldProps extends Omit<TextFieldProps, "onChange"> {
  onChangeValue: (value: string) => void;
  matchPassword?: string;
  required?: boolean;
  prompt?: boolean;
  label?: string;
}

const StyledCustomTextField = styled(TextField)(({ theme, required }) => ({
  "& label.Mui-focused": {
    color: "#919EAB"
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& fieldset": {
      borderColor: "#919EAB"
    }
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8
  },
  "& .MuiInputLabel-root": {
      "&::after": {
        content: required ? "' *'" : "''",
        color: required ? "red" : "transparent", // Change color to transparent when not required
      }
    },
    "& .MuiInputLabel-asterisk": {
      display: "none" // Hide the default asterisk
    }
}));

const CustomPasswordField: React.FC<CustomPasswordFieldProps> = ({
  onChangeValue,
  matchPassword,
  required,
  prompt,
  label,
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [helperText, setHelperText] = React.useState<string>('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };


  const debounce = (func: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    onChangeValue(value);  
    setShowPrompt(true)
  };

  React.useEffect(() => {
    delayedMatch()
    // eslint-disable-next-line
  }, [inputValue, matchPassword, showPrompt]);

  const delayedMatch = debounce(() => {
    if (showPrompt && matchPassword && inputValue === matchPassword) {
      setHelperText('Passwords match');
      setShowPrompt(false);
    } else if (showPrompt) {
      const timeoutId = setTimeout(() => {
        if (inputValue === matchPassword) {
          setHelperText('Passwords match');
          setShowPrompt(false);
        } else {
          setHelperText('Passwords do not match');
        }
      }, 2000);


      return () => clearTimeout(timeoutId);
      
    }
  },2000)

  return (
    <>
   
      <StyledCustomTextField        
        {...props}
        label={label}
        onChange={handleChange}
        type={showPassword ? "text" : "password"}
        autoComplete="new-password"
        required
        InputProps={{
          autoComplete: 'new-password',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {(prompt && showPrompt) && ( <FormHelperText className={inputValue !== matchPassword ? 'Mui-error' : ''}>
        {helperText}
      </FormHelperText>)}
    </>
  );
};

export default CustomPasswordField;
