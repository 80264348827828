import React, { useEffect } from "react";
import {
  setCurrency,
  setEmployeeCount,
  setLoaded,
  setPlansData,
} from "src/redux/reducers/pickPlan";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "src/redux/reducers/userReducer";
import ErrorImg from "src/assets/images/warning.svg";
import Loader from "src/components/Loader";
import PlanSelect from "src/components/PlanPage/PlanSelect";
import { Slider } from "@mui/material";
import ModuleAccordian from "src/components/Accordian/ModuleAccordian";
import PlanTab from "src/components/PlanPage/PlanTab";
import Cart from "src/components/Cart";
import OtherAddons from "src/components/PlanPage/OtherAddons";
import { AxiosResponse } from "axios";
import { PlanRootObject } from "src/types/planApi";
import swapApiData from "src/api/redux/swapPlansApi";

const Swap1 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ------------- Redux Selectors ---------------

  const renderPlan = useSelector(
    (state: any) => state.plans.choosePlan.dataToRender
  );

  const plans = useSelector((state: any) => state.plans.choosePlan);
  const postPlan = useSelector((state: any) => state.plans.choosePlan.postData);

  // ---------------- State Management ------------------------------
  const [loading, setLoading] = React.useState<boolean>(false);
  const [planExist, setPlanExist] = React.useState<boolean>(false);
  const [totalEmp, setTotalEmp] = React.useState<number>(postPlan.employeeCount);

  // ------- set employee in company ----
  const empInCompany = (value: number | number[]) => {
    if (typeof value === "number") {
      dispatch(setEmployeeCount(value));
      setTotalEmp(value);
    }
  };

  // --------------- Change No. of employees ---------
  useEffect(() => {
    setTotalEmp(plans.employeeCount);
    if(plans.prevState){
      setTotalEmp(plans.prevpostPlan.employee_count);
    }
    // eslint-disable-next-line
  }, [renderPlan]);

  const fetchSwapData = () => {
    try {
      return swapApiData
        .getSwapPlansData()
        .then((res: AxiosResponse<PlanRootObject>) => {
          const data = res.data;
          if (data) {
            return data;
          } else {
            // Handle the case where data is undefined or null
            throw new Error("Received undefined or null data from API.");
          }
        })
        .catch((error: any) => {
          // Handle any errors that occurred during the API call or data processing
          console.error("Error fetching plans data:", error);
          throw error; // Rethrow the error to propagate it further if necessary
        });
    } catch (error) {
      // This block won't catch any errors from asynchronous operations
      console.error("Error:", error);
    }
    // return SampleRes;
  };

  //   --------- Swap plan data api ---------
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchSwapData();
        if (data) {
          dispatch(setLoaded(false));
          const plansArray = Object.values(data);

          // Check if the array contains plans or not
          const plansExist =
            Array.isArray(plansArray) &&
            plansArray.every(
              (item) => typeof item === "object" && item !== null
            );
          if (plansExist) {
            setPlanExist(true);
            const resCurrency = plansArray[0]?.currency;
            dispatch(setCurrency(resCurrency));
            dispatch(setPlansData(data));
          } else {
            setPlanExist(false);
          }
        }
        setLoading(false);
      } catch (error: any) {
        // debugger;
        // dispatch(setAuth({ isAuthenticated: false, token: null }));
        dispatch(setError(error.response.data));
        setLoading(false);
        navigate("/error");
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <Loader />}
      {planExist && (
        <>
          <div className="w-full flex justify-between items-start h-[81vh] lg:h-[71vh] 2xl:h-[81vh]  pl-1">
            <div className="w-full lg:w-[69%] 2xl:w-[80%] max-h-[100%]  overflow-y-auto  flex flex-col justify-start items-start gap-2">
              <div className="w-full flex justify-between items-start border-dashed border-b-2 border-[#919EAB3D]">
                <div className="w-[69%] max-h-[100%]  overflow-y-auto flex flex-col justify-start items-start gap-2 grow">
                  {/* ------ description text --- */}
                  <div className="w-full  flex  justify-start items-center ">
                    <div className="flex-1 flex flex-col justify-start items-start gap-1">
                      <div className="text-pageHeading text-base font-[600]">
                        Let’s swap your plan
                      </div>
                      <div className="text-lightText text-sm font-[400] break-words">
                        Plans and any additional features you need for your
                        business.
                      </div>
                    </div>
                  </div>
                  {/* --------- plan slider ------- */}
                  <div className="w-full flex flex-col justify-start items-start gap-4 grow">
                    <PlanSelect upgrade={true} />
                  </div>
                </div>
              </div>

              {/* ------ Render modules and data for selected Plan --- */}
              <div className="w-full pb-6">
                {/* ---------- No of employees in company ------  */}
                {renderPlan && renderPlan.is_free !== true && (
                  <div className="flex flex-col justify-center items-start gap-8">
                    <div className="text-black text-sm">
                      How many employees do you have?
                    </div>
                    <div className="w-full pl-[20px] pr-[25px]">
                      <Slider
                        defaultValue={totalEmp}
                        value={totalEmp}
                        step={10}
                        marks
                        min={10}
                        max={200}
                        valueLabelDisplay="on"
                        onChange={(event, newValue) => empInCompany(newValue)}
                      />
                    </div>
                  </div>
                )}

                {/* ----- render the modules ---- */}
                <ModuleAccordian />
              </div>
              {/* ----- render the Addons ---- */}
              <div className="w-full ">
                <OtherAddons />
              </div>
            </div>

            <div className="w-full hidden lg:flex flex-col gap-2 flex-1 relative pt-1 lg:px-5 pl-1 overflow-y-auto max-h-[100%]">
              <div className="pt-[30px] flex justify-end items-end w-full">
                <PlanTab />
              </div>
              <div className="overflow-y-auto max-h-[100%]">
                <Cart />
              </div>
            </div>
          </div>
        </>
      )}
      {!planExist && !loading && (
        <div className="flex w-full flex-col justify-start items-center h-full">
          <div className="px-4 py-4 ">
            <img src={ErrorImg} alt="" className="h-[50vh]" />
          </div>
          <div className="px-4 py-4 ">
            <div className="text-base text-pageHeading">
              Sorry! No Plan Exist
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Swap1;
