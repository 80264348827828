// On Boarding Pages

import InitialStep from "src/pages/Onboarding/initialStep/page";
import Step1 from "src/pages/Onboarding/getting_started/page";
import Step2 from "src/pages/Onboarding/setting_you_up/page";
import Step3 from "src/pages/Onboarding/pick_a_plan/page";
import Step4 from "src/pages/Onboarding/support_implementation/page";
import NotFound from "src/pages/NotFound";
import LastStep from "src/pages/Onboarding/completion/page";
import Checkout from "src/pages/Onboarding/payment/Checkout";


// Upgrade Pages
import Login from "src/pages/Login/page";
import Dashboard from "src/pages/Dashboard/page";
import SwapPlan from "src/pages/SwapPlan/page";
import UpgradePlan from "src/pages/UpgradePlan/page";
 

export const AdminRouter = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/swap-plan",
    component: SwapPlan,
  },
  {
    path: "/upgrade-plan",
    component: UpgradePlan,
  },
  {
    path: "/error",
    component: NotFound,
  }, 
];

export const OnboardRouter = [
  {
    path: "/onboarding",
    component: InitialStep,
  },

  {
    path: "/onboarding/getting-started",
    component: Step1,
  },

  {
    path: "/onboarding/setting-thing-up",
    component: Step2,
  },
  {
    path: "/onboarding/pick-a-plan",
    component: Step3,
  },

  {
    path: "/onboarding/support-implementation",
    component: Step4,
  },
  {
    path: "/onboarding/checkout",
    component: Checkout,
  },
  {
    path: "/onboarding/congratulations",
    component: LastStep,
  },
  {
    path: "/error",
    component: NotFound,
  },
];
