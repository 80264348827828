import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextField from "src/components/CustomTextField";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pushDiscounts, setDiscounts, setModulesAndFeatures, setplanTotalAmount } from "src/redux/reducers/pickPlan";
import { totalAmount } from "src/redux/reducers/cartReducer";
import cart from "src/api/cart";
// import { Pros, RequestPromo } from "src/types/page";
import { useLocation } from "react-router-dom";
import { Addon, Module } from "src/types/planApi";
import { Tooltip } from "@mui/material";

const Cart = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // ----- redux selectors ----
  const initialEmpCount = useSelector(
    (state: any) => state.plans.choosePlan.postData.employee_count
  );
  const plans = useSelector((state: any) => state.plans.choosePlan.postData);

  const newCurrency = useSelector((state: any) =>
    plans.currency ? plans.currency : state.plans.choosePlan.currency
  );

  const [empCount, setEmpCount] = useState(initialEmpCount);

  // ---- state management ----
  const [packageName, setPackageName] = useState<string>(plans.plan_name);
  const [planFrequency, setPlanFrequency] = useState<string>(plans.frequency);
  const [initialPrice, setInitialPrice] = useState<string>(
    plans.total_base_price
  );
  const [planModules, setPlanModules] = useState<Module[]>([]);
  // const [renderModules, setRenderModules] = useState<boolean>(false);
  // const [discount, setDiscount] = useState<number>(0);

  // ------ Promo state managment ----
  const [showPromoBox, setShowPromoBox] = useState<boolean>(true);
  const [promoCode, setPromoCode] = useState<any>([]);
  const [promoValidate, setPromoValidate] = useState<boolean>(false);
  const [promoValid, setPromoValid] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [promoMessage, setPromoMessage] = useState<string | undefined>("");
  const [renderedPromo, setRenderedPromo] = useState<any>([]);

  // -------- Pricing state management -----
  const [subTotal, setSubTotal] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  // ---- helper functions ---
  const activeModules = planModules.filter(
    (planModule) => planModule.is_active
  );

  const getYearType = (data: string) => {
    switch (data) {
      case "yearly":
        return 12;
      case "monthly":
        return 1;
    }
  };

  // -------- remove addons controls ----
  const removeAddon = (addon: Addon) => {
    const moduleIndex = plans.modules.findIndex(
      (module: any) => module.module_id === addon.module_id
    );
    const alterAddon = planModules[moduleIndex].addons.filter((item) => item.addon_id !== addon.addon_id);
    dispatch(
      setModulesAndFeatures({ type: "pros", data: alterAddon, moduleIndex })
    );
  };

 
  // ------ calculate price for addons variation ---
  const calculateAddonPrice = (addon: Addon) => {
    let totalPrice = addon.price;
    if (!addon.data) {
      totalPrice = addon.price ?? 0;
    }
    if (addon.data && "total_price" in addon.data) {
      totalPrice = addon.data.total_price ?? 0;
    }
    // debugger
    return totalPrice;
  };

  // this will turn the price into 2 decimal place only
  const roundToTwoDecimalPlaces = (num: number): number => {
    return Math.round(num * 100) / 100;
  };

  // ------- calculate pricees in cart -------
  const calculateSubTotal = () => {
    let modulesPrice = 0;

    modulesPrice = plans.modules.reduce((accumulator: number, item: Module) => {
        if (!item.is_active) return accumulator;
       
        const itemPrice = item.price; 

        return accumulator + itemPrice;
      }, 0) * (getYearType(plans?.frequency) ?? 1);
     
    const sumAddonsPrices = (item: Module) => {
      if (item.addons.length > 0) {
        return item.addons.reduce(
          (total: number, addon: any) =>
            total +
            (addon.is_slider
              ? addon.data
                ? addon.data.total_price
                : 0
              : addon.price) ,

          0
        );
      }
      return 0;
    };

    const totalAddonPrice = plans.modules.reduce(
      (total: number, item?: Module) => {
        if (item) {
          return total + sumAddonsPrices(item);
        }
        return total;
      },
      0
    );
    // let planPrice = (plans.price !== 0 ? plans.price : modulesPrice) + totalAddonPrice;
    let planPrice = (plans.pricing_method === "plan_based" ? plans.total_base_price : modulesPrice) + totalAddonPrice;
    // let supportAmount = plans.supportPlans?.amount ?? 0;
    let supportAmount = plans.supportPlans?.frequency === "per_month" ? plans.supportPlans?.total_price ?? 0 : plans.supportPlans?.amount ?? 0;
    let servicesAmount = plans.servicePlans?.amount ?? 0;
    let subTotalAmount = planPrice + supportAmount + servicesAmount;
    setSubTotal(subTotalAmount);
  };
  // -------- useEffects start----------

  // --- calculate module price in module based plans --------
  const totalModulePrice = (planModule: Module) => { 
    let modulePrice = planModule.price; 
    modulePrice = planModule.variation !== "fixed" ? modulePrice * (getYearType(plans?.frequency) ?? 1) : modulePrice
    return modulePrice;
  };

  // -------- calculate pricing ------
  useEffect(() => {
    calculateSubTotal();
    // eslint-disable-next-line
  }, [plans]);

  // -------- calculate total price ------
  useEffect(() => {
    let total = subTotal;
    dispatch(setplanTotalAmount(total));
    dispatch(totalAmount(total));
    setTotalPrice(total);
    // eslint-disable-next-line
  }, [subTotal]);

  // ----- set data for cart
  useEffect(() => {
    setEmpCount(initialEmpCount);
    setPackageName(plans.plan_name);
    setPlanFrequency(plans.frequency);
    setInitialPrice(plans.total_base_price);
    setPlanModules(Object.values(plans.modules));
    // eslint-disable-next-line
  }, [plans]);

  // ------- state to show modules when loaded ---
  // useEffect(() => {
  //   planModules.length > 0 ? setRenderModules(true) : setRenderModules(false);
  // }, [planModules]);

  // ------Promo code validation -----

  // ---- api strike for promo validation ----

  useEffect(() => {
    if (promoCode && promoCode.length) {
      let promoCodeData = {
        promo_code: promoCode[0]?.name,
      };
      cart.getPromoCodePrice(promoCodeData).then((res) => {
        setPromoValidate(true);
        if (
          res.data.status_code === 404 ||
          res.data.status_code === 409 ||
          res.data.status_code === 400
        ) {
          setPromoMessage(res.data.message);
          setPromoValid(false);
        } else if (res.data.status_code === 200) {
          setRenderedPromo((prevRenderedPromo: any) => [
            ...prevRenderedPromo,
            res.data.data,
          ]);
          // debugger;
          dispatch(pushDiscounts(res.data.data));
          setPromoValid(true);
          setPromoMessage(res.data.message);
        }
        setPromoCode([]);
      });
    }
    // eslint-disable-next-line
  }, [promoCode]);

  //  ------ remove promo function ----
  const removePromo = (id: number) => {
    dispatch(
      setDiscounts(renderedPromo?.filter((item: any) => item.id !== id))
    );
    setRenderedPromo(renderedPromo?.filter((item: any) => item.id !== id));
    cart.removeDiscountCode(id).then(() => {});
  };

  useEffect(() => {
    setPromoValid(false);
  }, [isDirty]);

  // ------- give promo entry avaliable --------
  useEffect(() => {
    const hiddenPromoPaths = ["/onboarding/checkout", "/swap-plan"];
    const shouldShowPromoBox = !hiddenPromoPaths.some((path) =>
      location.pathname.endsWith(path)
    ); 
      setShowPromoBox(shouldShowPromoBox);
    // if (location.pathname.endsWith("/onboarding/checkout")) {
    //   setShowPromoBox(false);
    // } else {
    //   setShowPromoBox(true);
    // }
  }, [location.pathname]);

  useEffect(() => {
    console.log("redux-post",plans)
  }, [plans])


  return (
    <div className="w-full flex flex-1 bg-[#F9FAFB] py-4 pl-3 pr-2 rounded-xl  h-full mb-2">
      <div className="w-full flex flex-col justify-start items-start gap-3 2xl:gap-5">
        {/* --- plan name and price --- */}
        <div className="w-full flex flex-col justify-start items-start pb-2 gap-3 border-dashed border-b-2 border-[#141b223d]">
          {/* ------------ Number of Employees ----------- */}
          <div className="text-lightText text-sm">
            Your Invoice for
            <span className="text-pageHeading text-base font-semibold mx-1">
              {empCount}
            </span>
            employees
          </div>

          {/* ------- Plan Pricing -------------- */}
          <div className="w-full flex justify-between items-center">
            <Tooltip title={packageName} arrow>
              <div className="px-[6px] py-[2px] bg-[#919EAB29] text-pageHeading text-xs rounded-md capitalize">
                {packageName}
              </div>
            </Tooltip>
            <div className="text-pageHeading text-sm capitalize">
              {newCurrency} {initialPrice} {planFrequency}
            </div>
          </div>
        </div>

        {/* ---- added modules in plan ----- */}
        {activeModules.length > 0 && (
          <div className="w-full text-sm text-pageHeading border-dashed border-b-2 border-[#141b223d] pb-3 flex flex-col justify-start items-start gap-2">
            {planModules
              .filter((planModule) => planModule.is_active)
              .map((planModule: Module) => (
                <div
                  className="w-full flex justify-between items-center"
                  key={planModule.module_id}
                >
                  <Tooltip title={planModule.module_name} arrow>
                    <div className=" px-[6px] py-[2px] bg-[#919EAB29] text-pageHeading text-xs rounded-md capitalize break-words overflow-hidden truncate">
                      {planModule.module_name}
                    </div>
                  </Tooltip>
                  {planModule.price > 0 && (
                    <div className="flex justify-end 2xl:justify-end items-center gap-1 flex-1 ">
                      <div className="text-lightText text-xs flex gap-1 justify-end items-center">
                        <div>{plans.varation === "fixed" ? planModule.price : planModule.price/empCount}</div>
                        <div>x</div>
                        {planModule.variation === "as_per_actual" && (
                          <>
                            <span>{empCount}</span>
                            <span>x</span>
                          </>
                        )}
                        <div>{getYearType(plans.frequency)}</div>
                        <div>=</div>
                      </div>
                      <div className="text-pageHeading text-sm justify-self-end">
                        {newCurrency}{" "} 
                        {totalModulePrice(planModule)}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}

        {/* ----- added addons in plan ---- */}
        {planModules.some((module) => module.addons.length > 0) && (
          <div className="flex flex-col justify-start items-start w-full gap-2 border-dashed border-b-2 border-[#141b223d] pb-2">
            <div className="text-sm">Included Addons:</div>
            <div className="w-full flex flex-col justify-start items-start gap-2 flex-wrap">
              {planModules.map((module) =>
                module.addons.map((addon: Addon, index: number) => (
                  <div
                    className="w-full flex justify-between items-center"
                    key={addon.key}
                  >
                    <div className="flex justify-start items-center gap-1 px-[6px] py-[2px] bg-[#919EAB29] text-pageHeading text-xs rounded-md capitalize">
                      <Tooltip title={addon.name} arrow>
                        <div className=" break-words overflow-hidden capitalize truncate">
                          {addon.name}
                        </div>
                      </Tooltip>
                      <button onClick={() => removeAddon(addon)}>
                        <CloseIcon sx={{ fontSize: "12px" }} />
                      </button>
                    </div>
                    {addon.is_free === 0 && (
                      <div className="flex justify-end items-center gap-[2px] flex-1 flex-wrap">
                        <div className="text-lightText text-xs flex gap-1 justify-end items-center"> 
                          {addon.variation === "fixed" && (
                            <>
                              <span>{addon.monthly_price}</span>
                              <span>x</span>
                            </>
                          )}
                          {addon.variation === "as_per_actual" && (
                            <>
                              <span>
                                {(addon?.data?.selected ?? 1)  <= (addon?.data?.free_until) ? "0"
                                  : (addon?.data?.selected ?? 1) - (addon?.data?.free_until ?? 1)}
                              </span>
                              <span>x</span>
                              <span> 
                                {addon.price}
                              </span>
                              <span>x</span>
                            </>
                          )}

                          <div>{getYearType(plans?.frequency)}</div>
                          <div>=</div>
                        </div>
                        <div className="text-pageHeading text-sm justify-self-end">
                          {newCurrency}
                          {calculateAddonPrice(addon)}
                        </div>
                      </div>
                    )}
                    {addon.is_free === 1 && (
                      <div className="text-lightText text-xs">Free</div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
        )}

        {plans.supportPlans && (
          <div className="w-full text-sm text-pageHeading border-dashed border-b-2 border-[#141b223d] pb-2 flex flex-col justify-start items-start gap-2">
            {plans.supportPlans && (
              <div className="w-full flex justify-between items-center">
                <div className=" px-[6px] py-[2px] bg-[#919EAB29] text-pageHeading text-xs rounded-md capitalize break-words overflow-hidden">
                  {plans.supportPlans.name} Support
                </div>

                <div className="flex justify-end 2xl:justify-end items-center flex-1  gap-1">
                  {plans.supportPlans.frequency === "per_month" &&( <div className="text-lightText text-xs flex gap-1 justify-end items-center">
                  <div>{plans.supportPlans.amount}</div>
                  <div>x</div>
                  <div>{getYearType(plans.frequency)}</div>
                  <div>=</div>
                </div> )}
                  <div className="text-pageHeading text-sm justify-self-end">
                    {newCurrency}{" "}
                    {plans.supportPlans.frequency === "per_month" ? plans.supportPlans.total_price : plans.supportPlans.amount}
                  </div>
                </div>
              </div>
            )}
            {plans.servicePlans && (
              <div className="w-full flex justify-between items-center">
                <div className=" px-[6px] py-[2px] bg-[#919EAB29] text-pageHeading text-xs rounded-md capitalize break-words overflow-hidden">
                  {plans.servicePlans.name} Services
                </div>

                <div className="flex justify-end 2xl:justify-end items-center flex-1  gap-1">
                  {/* <div className="text-lightText text-xs flex gap-1 justify-end items-center">
                  <div>12</div>
                  <div>x</div>
                  <div>1</div>
                  <div>=</div>
                </div> */}
                  <div className="text-pageHeading text-sm justify-self-end">
                    {newCurrency}
                    {plans.servicePlans.amount}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* ---- discunt & promo ---- */}
        <div className="w-full flex flex-col gap-2 2xl:gap-3 justify-start items-start">
          {/* -------  sub total ----- */}

          <div className="w-full text-pageHeading flex justify-between items-center">
            <div className="text-sm">Subtotal</div>
            <div className="text-base">
              {newCurrency}
              {subTotal}
            </div>
          </div>
          {/* <div className="w-full text-pageHeading flex justify-between items-center">
            <div className="text-sm">Discount</div>
            <div className="text-base text-pageHeading">
              {newCurrency}
              {discount}
            </div>
          </div> */}
          {/* -------  Promo ----- */}
          {(showPromoBox && !plans.is_free) && (
            <div className="w-full flex flex-col justify-start items-start gap-3 border-dashed border-b-2 border-[#919EAB3D] pb-3">
              <CustomTextField
                label="Promo code"
                fullWidth
                setValueOnEnter={setPromoCode}
                setIsDirty={setIsDirty}
              />
              {promoValidate && (
                <div
                  className={`${
                    promoValid !== true ? "text-[#e20100]" : "text-[#65c466]"
                  } text-xs mt-[-10px]`}
                >
                  {promoMessage}
                </div>
              )}
              {!promoValidate && (
                <div className={`text-[#798da3] text-xs mt-[-10px]`}>
                  Press Enter to continue
                </div>
              )}
              <div className="w-full flex flex-col justify-start items-start gap-2">
                {renderedPromo?.map((promo: any) => (
                  <div
                    className="w-full flex justify-between items-center"
                    key={promo.id}
                  >
                    <div className="flex justify-start items-center gap-2 px-[6px] py-[2px] bg-[#919EAB29] text-pageHeading text-xs rounded-md capitalize">
                      <div className="max-w-[90%] break-words overflow-hidden">
                        {promo.promo_code}
                      </div>
                      <button onClick={() => removePromo(promo?.id)}>
                        <CloseIcon sx={{ fontSize: "12px" }} />
                      </button>
                    </div>
                    <div className=" text-ri-secondary text-base">
                      {promo.discount_value && (
                        <div className="text-pageHeading text-sm justify-self-end">
                          {newCurrency}
                          {promo.discount_value}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* -------  Total ----- */}
        <div className="w-full text-pageHeading flex justify-between items-center ">
          <div className="text-base font-bold">Total</div>
          <div className="text-base font-bold">
            {newCurrency}
            {totalPrice}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
