import axios from "axios";

// backend baseURL Production
// baseURL: "https://ri-admin.dotdropit.com/",

// backend baseURL Stage
// baseURL: "https://stage-onboarding-api.resourceinn.com/",
 
// backend baseURL Stage
// baseURL: "http://ri-tenant-portal.test/",
 

let instance = axios.create({
  baseURL: "https://ri-admin.dotdropit.com/",
});


// production baseURL
// baseURL: "https://onboarding.resourceinn.com/",

// frontend baseURL
// baseURL: "https://stage-onboarding.resourceinn.com/",

// frontend local baseURL
// baseURL: "http://localhost:3000/",

let hostInstance = axios.create({
  baseURL: "https://onboarding.resourceinn.com/",
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    try {
      if (error?.response?.status === 401) {
      }
      if (error?.response?.status === 422) {
        alert(error.response.data.error);
      }
    } catch (error) {
      // console.log(error);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export {instance,hostInstance};
