

import React from 'react'

const Step3Img = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_538_72679)">
        <path opacity="0.32" d="M24.0953 14.8403H23.7876C22.5694 14.8403 21.3512 14.8683 20.1344 14.8333C18.645 14.7905 18.0507 15.5814 17.8624 16.7229C17.7261 17.5475 17.7254 18.3791 17.9425 19.1952C18.2129 20.2133 18.8705 20.7154 19.9257 20.7182C21.3013 20.7217 22.6769 20.7189 24.0447 20.7189C24.0637 20.7399 24.0862 20.7525 24.0862 20.7651C24.0784 21.7082 24.1389 22.659 24.0405 23.593C23.9323 24.6237 23.0338 25.329 21.9912 25.3347C20.4842 25.3431 18.9773 25.3368 17.4704 25.3368C13.0106 25.3368 8.55091 25.3368 4.09119 25.3368C2.66854 25.3368 1.7981 24.4694 1.7981 23.051C1.7981 19.5353 1.7981 16.0203 1.7981 12.5047C1.7981 11.0862 2.66784 10.2217 4.09259 10.2217C9.99814 10.2217 15.9037 10.2217 21.8092 10.2217C23.2284 10.2217 24.0946 11.0904 24.0946 12.5138C24.0946 13.196 24.0946 13.8783 24.0946 14.5598C24.0946 14.6446 24.0946 14.7295 24.0946 14.8396L24.0953 14.8403Z" fill="#212B36"/>
        <path d="M5.44428 9.38142C5.95221 8.93618 6.40254 8.54002 6.85427 8.14526C8.7975 6.44633 10.7421 4.74881 12.6825 3.04707C13.0935 2.68667 13.5446 2.54013 14.0588 2.77362C14.5906 3.01482 14.8379 3.45164 14.8379 4.03081C14.8379 4.53775 14.8316 5.0447 14.8414 5.55164C14.8443 5.71221 14.7979 5.79916 14.6483 5.87278C12.3446 7.01288 10.0445 8.15999 7.74228 9.30289C7.65938 9.34426 7.56173 9.37722 7.4704 9.37792C6.82266 9.38423 6.17492 9.38142 5.44287 9.38142H5.44428Z" fill="#212B36"/>
        <path d="M22.3748 15.6802C23.2066 15.6802 24.0384 15.6781 24.8702 15.6802C25.6577 15.683 26.1931 16.2124 26.1987 16.9921C26.2022 17.5166 26.2022 18.0411 26.1987 18.5648C26.1931 19.334 25.6613 19.8746 24.8969 19.8767C23.2248 19.8809 21.5521 19.8802 19.8801 19.8767C19.2688 19.8753 18.9323 19.632 18.775 19.036C18.5572 18.2107 18.5586 17.3736 18.7665 16.5469C18.9232 15.9249 19.2632 15.683 19.9061 15.6802C20.7287 15.6767 21.5521 15.6795 22.3755 15.6795L22.3748 15.6802ZM21.1503 16.1002C20.2215 16.1002 19.4656 16.8575 19.4684 17.7858C19.4712 18.7009 20.2208 19.4525 21.1369 19.4581C22.0657 19.4637 22.8293 18.71 22.8321 17.7858C22.8349 16.8589 22.0783 16.1002 21.1503 16.1002Z" fill="#212B36"/>
        <path d="M9.58862 9.32683C11.0597 8.59411 12.5302 7.86139 14.0013 7.12937C14.8935 6.68553 15.785 6.24099 16.678 5.79855C17.6945 5.29441 18.6247 5.86446 18.6254 6.99124C18.6261 7.77654 18.6254 8.56185 18.6254 9.36469H9.59495L9.58862 9.32753V9.32683Z" fill="#212B36"/>
        <path d="M21.147 16.9406C21.6036 16.9392 21.9851 17.3151 21.9893 17.7708C21.9935 18.2378 21.605 18.6241 21.1378 18.6178C20.6819 18.6122 20.306 18.2294 20.3089 17.7736C20.3117 17.3151 20.6875 16.9413 21.147 16.9399V16.9406Z" fill="#212B36"/>
        </g>
        <defs>
        <clipPath id="clip0_538_72679">
        <rect width="24" height="24" fill="white" transform="translate(2 2)"/>
        </clipPath>
        </defs>
    </svg>
  )
}

export default Step3Img