import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  loggedIn: boolean;
  forUpgrade: boolean;
  error: string | null;
  activeUser:  {
    token: string;
    user_name: string;
    company_name: string;
  } | {};
}

const initialState: UserState = {
  loggedIn: false,
  forUpgrade: false,
  error: null,
  activeUser: {
    token: "",
    user_name: "",
    company_name: "",
  },
};

const userReducer = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    loggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    forUpgrade: (state, action: PayloadAction<boolean>) => {
      state.forUpgrade = action.payload;
    },
    setActiveUser: (state, action: PayloadAction<object>) => {
      state.activeUser = action.payload;
      state.loggedIn = true;
      state.forUpgrade = true;
    },
    setLogout: (state, action: PayloadAction<boolean>) => {
      state.activeUser = {};
      state.loggedIn = false;
      state.forUpgrade = false;
      localStorage.clear();
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    clearState:(state)=>{
      state =  initialState;
    }
  },
});

export const { loggedIn, forUpgrade, setActiveUser, setLogout, setError,clearState } =
  userReducer.actions;

export default userReducer.reducer;
