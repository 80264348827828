import React from "react"; 
// import { useSelector } from "react-redux";
import Navigation from "./Routes/Navigation";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

const App = () => {
  // const userAuth = useSelector((state: any) => state.auth);

  return (
    <> 
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </>
  );
};

export default App;
