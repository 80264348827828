import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import LoaderGif from 'src/assets/loader/RI_new_loader.gif'

export default function Loader() {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    setOpen(true);
  }, [])
  
  return (
    <div>
      <Backdrop
        sx={{ backgroundColor: '#f8f6f75c',color: '#833bac', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <img src={LoaderGif} alt="" className='h-[100px]'/>
      </Backdrop>
    </div>
  );
}

