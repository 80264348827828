import React from "react";

const Step5Img = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11060_315564)">
        <path
          opacity="0.32"
          d="M5 16.1384C7.95238 16.1384 10.9052 16.1393 13.8576 16.1353C13.9945 16.1353 14.0786 16.1742 14.157 16.2906C14.9292 17.439 15.9779 18.2796 17.1208 19.0123C17.2841 19.117 17.4497 19.2186 17.6174 19.3157C18.1871 19.6456 18.7704 19.6666 19.3401 19.3359C20.402 18.7196 21.4014 18.0146 22.2251 17.0828C22.4475 16.8312 22.6421 16.5537 22.8486 16.2874C22.8864 16.2391 22.9199 16.1872 22.9868 16.0923C22.9925 16.1997 22.9974 16.2494 22.9974 16.2995C22.9974 18.1158 22.9982 19.9325 22.9974 21.7488C22.9974 22.5222 22.5307 22.9989 21.7726 22.9989C16.5938 22.9993 11.415 22.9993 6.23669 22.9989C5.58863 22.9989 5.21441 22.7137 5.02509 22.0804C5.02025 22.0639 5.00836 22.0495 5 22.0343C5 20.069 5 18.1033 5 16.1379L5 16.1384ZM8.93548 18.4255C8.58415 18.4255 8.23326 18.4242 7.88194 18.4255C7.5064 18.4273 7.25281 18.6569 7.25105 18.9939C7.24928 19.3319 7.50287 19.5673 7.87665 19.5682C8.58503 19.57 9.29297 19.57 10.0013 19.5682C10.3734 19.5673 10.627 19.3287 10.6234 18.9904C10.6199 18.66 10.3681 18.4277 10.0066 18.4255C9.64958 18.4233 9.29253 18.4251 8.93548 18.4251V18.4255Z"
          fill="#212B36"
        />
        <path
          opacity="0.32"
          d="M5 11.3854C5.10082 11.0318 5.26195 10.7158 5.6102 10.5605C5.77706 10.4858 5.96769 10.4276 6.14776 10.4267C8.3605 10.4177 10.5732 10.4209 12.786 10.4213C12.8084 10.4213 12.8309 10.4258 12.8868 10.4316C12.8868 11.0023 12.8622 11.5729 12.8938 12.14C12.9251 12.7075 13.0127 13.2724 13.077 13.8511H5V11.3854Z"
          fill="#212B36"
        />
        <path
          d="M13.9989 10.706C13.9989 10.2593 14.0037 9.81311 13.9971 9.36643C13.9927 9.04775 14.1305 8.83918 14.4175 8.7152C15.6815 8.16782 16.9442 7.61774 18.2055 7.06454C18.4076 6.97592 18.5965 6.97995 18.7977 7.06812C20.0537 7.61909 21.3107 8.16737 22.5698 8.71162C22.8639 8.83873 23.0026 9.0482 22.9995 9.37449C22.992 10.2021 23.0057 11.0296 22.9907 11.8568C22.9718 12.9126 22.8032 13.9389 22.3453 14.9012C21.9477 15.7359 21.386 16.4444 20.6749 17.0115C20.0876 17.4801 19.4515 17.8856 18.8329 18.3139C18.626 18.4572 18.4059 18.4599 18.187 18.3301C17.2537 17.7755 16.3675 17.1588 15.6278 16.3455C14.775 15.4074 14.2586 14.3023 14.1107 13.0388C14.0204 12.2667 14.0055 11.4857 13.957 10.7087C13.9707 10.7078 13.9848 10.7069 13.9984 10.706H13.9989ZM17.9819 12.879C17.9357 12.8137 17.9009 12.7671 17.8692 12.7188C17.6772 12.427 17.4888 12.1329 17.2938 11.8433C17.1423 11.6186 16.9143 11.5246 16.6888 11.581C16.2944 11.68 16.1271 12.1101 16.356 12.4686C16.7034 13.0124 17.0604 13.5495 17.4153 14.0879C17.7014 14.5216 18.1153 14.5373 18.4375 14.1287C18.851 13.6041 19.2644 13.0791 19.6778 12.5541C19.9886 12.1593 20.3038 11.7677 20.6076 11.3675C20.8885 10.9978 20.7106 10.506 20.273 10.4321C20.0278 10.3905 19.8459 10.5051 19.6967 10.6962C19.1323 11.4177 18.5648 12.1369 17.9814 12.879H17.9819Z"
          fill="#212B36"
        />
      </g>
      <defs>
        <clipPath id="clip0_11060_315564">
          <rect
            width="18"
            height="16"
            fill="white"
            transform="translate(5 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Step5Img;
