import React from "react"; 
import Step4 from "src/pages/Onboarding/support_implementation/page";
import Checkout from "src/pages/Onboarding/payment/Checkout";
import LastStep from "src/pages/Onboarding/completion/page";

import { useSelector } from "react-redux";
import CustomButton from "src/components/CustomButton";
import { Box } from "@mui/material";
import { Steps } from "antd";
import "src/index.css";
import Upgrade1 from "./Upgrade1";

const steps = [
  {
    title: "Upgrade Plan",
    content: Upgrade1,
  },
  {
    title: "Upgrade Services",
    content: Step4,
  },
  {
    title: "Payment",
    content: Checkout,
  },
  {
    title: "Deployment",
    content: LastStep,
  },
];

const UpgradePlan = () => {
  // ---------------- State Management ------------------------------
  // const [loading, setLoading] = React.useState<boolean>(false);

  // ------ redux selectors ----
  const postToData = useSelector(
    (state: any) => state.plans.choosePlan.postData
  );
  const cartTotalAmount = useSelector(
    (state: any) => state.cart.cartTotalAmount
  );

  // ---------- helper function -------------

  const [current, setCurrent] = React.useState<number>(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));
 

  const CurrentContent = steps[current].content;

  // -------------- Post Data for SaaS creation --------
  const postDataApi = () => {
    const dataToPost = {
      total_price: cartTotalAmount,
      plan: postToData,
    };
    // supportImplment.setPostData(dataToPost).then((res) => {
    //   if (cartTotalAmount > 0) {
    //     setCurrent(current + 1);
    //   } else {
    //     // navigate("/onboarding/congratulations");
    //   }
    // });
    if (cartTotalAmount > 0) {
      setCurrent(current + 1);
    }
  };

  // Alteration for next button click -----
  const handleNextClick = () => {
    current === 2 ? postDataApi() : next();
  };

 

  return (
    <>
      <div className="w-full flex flex-1 flex-col justify-between items-start max-h-[calc(100vh-56px)] lg:max-h-[calc(100vh-80px)] overflow-hidden rounded-lg p-2">
        <div className="w-full">
          <div className="px-5 hidden lg:flex">
            <Steps current={current} items={items} />
          </div>
          <div className="w-full lg:mt-[16px]">
            <CurrentContent />
          </div>
        </div>
        <div className="w-full px-4 py-2 flex justify-between items-center border-dashed border-t-2 border-[#919EAB3D]">
          {current > 0 && (
            <CustomButton
              variant="unfilled"
              text={"Back"}
              onClick={() => prev()}
            />
          )}
          <Box sx={{ flex: "1 1 auto" }}></Box>
          {current < steps.length - 2 && (
            <CustomButton
              variant="filled"
              text={"Next"}
              onClick={handleNextClick}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UpgradePlan;
