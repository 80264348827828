import * as React from "react";
import { Slider, Checkbox, Grid } from "@mui/material";
import OtherAddonCard from "./OtherAddonCard";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Addon, Module } from "src/types/planApi";
import { setAddonSelection } from "src/redux/reducers/pickPlan";
import { withStyles } from "@mui/styles";

// Define custom styles for the Slider thumb
const CustomSlider = withStyles({
  disabled: {
    "& .MuiSlider-thumb ": {
      // backgroundColor: "#505c68", // Background color of the thumb
    },
    "& .MuiSlider-track": {
      // backgroundColor: "#e0e0e0",
    },
    "& .MuiSlider-root": {
      // backgroundColor: "#212b3612",
    },
  },
})(Slider);

const OtherAddons: React.FC = () => {
  const dispatch = useDispatch();

  // ------ redux selectors ----
  const renderPlan = useSelector(
    (state: any) => state.plans.choosePlan.dataToRender
  );
  const postToData = useSelector(
    (state: any) => state.plans.choosePlan.postData
  );
 
  // ---- state managment ---
  const [fixedAddons, setFixedAddons] = useState<Addon[]>([]);
  const [moduleArray, setModuleArray] = useState<Module[]>([]);
  const [sliderAddons, setSliderAddons] = useState<Addon[]>([]);
  const [nonSliderAddons, setNonSliderAddons] = useState<Addon[]>([]);

  // ----- helper functions ----
  const checkSelected = (item: Addon) => {
    const module = postToData.modules.find(
      (unit: Module) => unit.module_id === item.module_id
    );
    if (module && Array.isArray(module.addons)) {
      return module.addons.some((addon: Addon) => addon.key === item.key);
    }
    return false;
  };

 
  const handleSelection = (selectedAddon: Addon) => {
    const moduleIndex = postToData.modules.findIndex(
      (module: Module) => module.module_id === selectedAddon.module_id
    );
    const matchedModule = postToData.modules[moduleIndex];

    const addonIndex = matchedModule.addons.findIndex(
      (item: Addon) => item.addon_id === selectedAddon.addon_id
    );
    selectedAddon = {...selectedAddon}; 
  
    if (selectedAddon.monthly_price === undefined) {
        selectedAddon.monthly_price = selectedAddon.price;
      }
      selectedAddon.price = selectedAddon.monthly_price * (getYearType(postToData.frequency) ?? 1);

    if (addonIndex === -1) {
      dispatch(
        setAddonSelection({
          type: "add",
          data: [selectedAddon],
          moduleIndex: moduleIndex,
        })
      );
    } else {
      dispatch(
        setAddonSelection({
          type: "remove",
          data: selectedAddon,
          moduleIndex: moduleIndex,
        })
      );
    }
  };

  // -------- add/remove addons controls ----
  const handleCheckboxChange = (obj: Addon) => {
    const moduleIndex = postToData.modules.findIndex(
      (module: Module) => module.module_id === obj.module_id
    );
    const matchedModule = postToData.modules[moduleIndex];

    const addonIndex = matchedModule.addons.findIndex(
      (item: Addon) => item.addon_id === obj.addon_id
    );
    if (addonIndex === -1) {
      dispatch(
        setAddonSelection({
          type: "sliderAddon",
          data: obj,
          moduleIndex: moduleIndex,
        })
      );
    } else {
      dispatch(
        setAddonSelection({
          type: "remove",
          data: obj,
          moduleIndex: moduleIndex,
        })
      );
    }
  };

  // ------- addon slider change function ------
  const handleAddSliderChange = (
    event: Event,
    value: number | number[],
    addon: Addon
  ) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    const moduleIndex = postToData.modules.findIndex(
      (module: Module) => module.module_id === addon.module_id
    );
    const matchedModule = postToData.modules[moduleIndex];

    const addonIndex = matchedModule.addons.findIndex(
      (item: Addon) => item.addon_id === addon.addon_id
    );

    const { totalPrice, monthly_price } = calculateTotalPrice(newValue, addon);

    if (addonIndex !== -1) {
      const updatedAddon = { ...addon };
      updatedAddon.data = {
        ...updatedAddon.data,
        selected: newValue,
        total_price: totalPrice,
        monthly_price: monthly_price,
      };
      dispatch(
        setAddonSelection({
          type: "sliderAddon",
          data: updatedAddon,
          moduleIndex: moduleIndex,
        })
      );
    }
  };

  // ----check if addon is added to enable slider ---
  const addonAdded = (addon: Addon) => {
    const moduleIndex = postToData.modules.findIndex(
      (item: Module) => item.module_id === addon.module_id
    );
    if (moduleIndex !== -1) {
      const matchedModule = postToData.modules[moduleIndex];

      const addonIndex = matchedModule.addons.findIndex(
        (item: Addon) => item.addon_id === addon.addon_id
      );

      if (addonIndex !== -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return;
    }
  };

  const getYearType = (data: string) => {
    switch (data) {
      case "yearly":
        return 12;
      case "monthly":
        return 1;
    }
  };

  // ------ calculate price for variation ---
  const calculateTotalPrice = (newValue: number, addon: Addon):  { totalPrice: number, monthly_price: number } => {
    let totalPrice = addon.price;
    let monthly_price = addon.price;
    for (const slab of addon.data.slabs) {
      if (newValue >= slab.min && newValue <= slab.max) {
        totalPrice = slab.price * (newValue - addon.data.free_until);
        monthly_price = slab.price * (newValue - addon.data.free_until);
        break;
      }
    }
    if (addon.variation === "as_per_actual") {
      totalPrice = totalPrice * (getYearType(postToData?.frequency) ?? 1);
    }
    return {totalPrice,monthly_price};
  };

  const getDefaultValue = (addonItem: Addon) => {
    let foundItem;

    let matchedModule = postToData.modules.find(
      (item: Module) => item.module_id === addonItem.module_id
    );

    if (matchedModule) {
      let matchedAddon = matchedModule.addons.find(
        (item: Addon) => item.addon_id === addonItem.addon_id
      );
      if (matchedAddon) {
        foundItem = matchedAddon.data
          ? matchedAddon.data.selected
            ? matchedAddon.data.selected
            : matchedAddon.data.free_until
            ? matchedAddon.data.free_until
            : matchedAddon.data.min_value
          : undefined;
      } else {
        foundItem = addonItem.data
          ? addonItem.data.selected
            ? addonItem.data.selected
            : addonItem.data.free_until
            ? addonItem.data.free_until
            : addonItem.data.min_value
          : undefined;
      }
      return foundItem;
    }
    return 0;
  };

  // ------ set modules to get addons ------
  useEffect(() => {
    
    // if (renderPlan && renderPlan.modules) {
    if (renderPlan?.pricing_method === "plan_based") {
      setModuleArray(Object.values(renderPlan?.modules));
    }
  }, [renderPlan]);

  useEffect(() => {
    // if (renderPlan?.price === 0 && !renderPlan?.is_free) {
    if (renderPlan?.pricing_method === "module_based") {
      setModuleArray(Object.values(postToData?.modules));
    }
    // eslint-disable-next-line
  }, [postToData]);

  // --------- set addons of rendered plan ----
  useEffect(() => {
    let addonsArray: Addon[] = [];
    if (renderPlan?.price === 0 && !renderPlan?.is_free) {
      const customModulesIds = moduleArray.map(
        (item: Module) => item.module_id
      );
      const allModules = Object.values(renderPlan.modules);
      const matchingModules: Module[] = [];
      allModules.forEach((item: any) => {
        if (customModulesIds.includes(item.module_id)) {
          matchingModules.push(item);
        }
      });
      // getting the addons of only tyhose modules which are selected
      addonsArray = matchingModules.flatMap((module: Module) =>
        module.addons ? Object.values(module.addons) : []
      );
    } else {
      addonsArray = moduleArray.flatMap((item) => Object.values(item.addons));
    }

    const sliderAddonsArray = addonsArray.filter(
      (item: Addon) => item.is_slider === 1
    );
    const nonSliderAddonsArray = addonsArray.filter(
      (item: Addon) => item.is_slider !== 1
    );
    setFixedAddons(addonsArray);
    setSliderAddons(sliderAddonsArray);
    setNonSliderAddons(nonSliderAddonsArray);
    // eslint-disable-next-line
  }, [moduleArray]);

  return (
    <>
      {fixedAddons.length !== 0 && (
        <div className="w-full flex flex-col justify-center items-start gap-4 border-dashed border-t-2 border-[#919EAB3D] py-6">
          <div className="text-pageHeading text-sm font-semibold">
            Other Addons
          </div>
          <Grid container spacing={2}>
            {nonSliderAddons.map((item: Addon) => (
              <Grid item xs={12} sm={6} xl={4} key={item.addon_id}>
                <OtherAddonCard
                  addon={item}
                  selectedAddons={checkSelected(item)}
                  selectAddon={handleSelection}
                />
              </Grid>
            ))}
          </Grid>
          {sliderAddons.map((sliderAddon: Addon) => (
            <div
              className="w-full shadow-custom flex flex-col justify-center items-start gap-2 p-3"
              key={sliderAddon.addon_id}
            >
              <div className="w-full text-pageHeading text-sm font-semibold flex">
                <div className="flex flex-1 justify-start items-center capitalize">
                  {sliderAddon.name}
                </div>
                <div className="flex ">
                  <div className="text-pageHeading font-semibold text-sm flex items-center">
                    {renderPlan.currency}
                    {sliderAddon.price}{" "}
                    <span className="text-xs text-lightText">
                      ({sliderAddon.variation === "fixed" ? "Fixed" : "per"}{" "}
                      {sliderAddon.unit})
                    </span>
                  </div>
                  <Checkbox
                    color="default"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                        borderRadius: "8px",
                      },
                    }}
                    onChange={() => handleCheckboxChange(sliderAddon)}
                    checked={!addonAdded(sliderAddon)}
                  />
                </div>
              </div>
              <div className="text-desc text-xs">{sliderAddon.description}</div>
              <CustomSlider
                value={getDefaultValue(sliderAddon)}
                step={sliderAddon.data.steps}
                marks
                min={sliderAddon.data.min_value}
                max={sliderAddon.data.max_value}
                valueLabelDisplay="auto"
                onChange={(event, value) =>
                  handleAddSliderChange(event, value, sliderAddon)
                }
                disabled={addonAdded(sliderAddon)}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default OtherAddons;
