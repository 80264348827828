import React, { useEffect } from "react";
import Logo from "src/assets/images/header_logo.svg";
import { Box, Modal } from "@mui/material";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import Cart from "./Cart";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  py: 1,
  px: 2,
};

const Header = () => {
  const location = useLocation();

  const [open, setOpen] = React.useState<boolean>(false);
  const [enableCart, setEnableCart] = React.useState<boolean>(false);

  // ------ Cart Modal functions  -------
  const handleCartOpen = () => {
    setOpen(true);
  };
  const handleCartClose = () => setOpen(false);

  // ------- give promo entry avaliable --------
  useEffect(() => {
    const hiddenPromoPaths = ["/onboarding/pick-a-plan", "/onboarding/support-implementation"];
    const shouldShowPromoBox = hiddenPromoPaths.some((path) =>
      location.pathname.endsWith(path)
    );
    setEnableCart(shouldShowPromoBox);
  }, [location.pathname]);

  return (
    <>
      <div className="bg-ri-secondary h-[64px] w-full flex justify-between items-center px-4">
        <div className="flex-1 flex justify-center">
          <img src={Logo} alt="ResourceInn" className="mx-auto" />
        </div>
        {enableCart && (
          <div className="flex lg:hidden" onClick={handleCartOpen}>
            <ShoppingCartTwoToneIcon sx={{ color: "#cbb6d4" }} />
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleCartClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Cart />
        </Box>
      </Modal>
    </>
  );
};

export default Header;
