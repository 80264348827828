import { instance } from "src/services/apiService";

const getHeaders = () => ({
  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
});


let upgradePlanApi = {
  getUpgradePlans: () => {
    return instance({
      url: "/api/onboarding/get/upgrade-plan",
      method: "get",
      headers: getHeaders(),
    });
  }, 
};
export default upgradePlanApi;
