import { createSlice } from "@reduxjs/toolkit";
import plansApiData from "src/api/redux/plansApi";
import { Addon, Module, Plan, PlanRootObject } from "src/types/planApi";
import { AxiosResponse } from "axios";

export const initialState = {
  choosePlan: {
    id: "freemium",
    currency: "$",
    loaded: false,
    otherAddons: false,
    data: null,
    dataToRender: null,
    postData: {
      plan_id: 1,
      plan_name: "freemium",
      key: "freemium",
      variation: "",
      frequency: "yearly",
      total_base_price: 0,
      discount: [],
      employee_count: 9,
      price: 0,
      modules: [
        {
          module_id: 1,
          key: "",
          module_name: "",
          description: "",
          tags: [""],
          icon: "",
          features: [
            {
              desc: "",
              info: "",
            },
          ],
          addons: [
            {
              module_id: 1,
              key: "",
              name: "",
              description: "",
              icon: "",
              price: 5,
              variation: "",
              unit: "",
              is_slider: false,
              data: {
                free_until: 10,
                steps: 10,
                min_value: 10,
                max_value: 50,
                slabs: [
                  {
                    min: 0,
                    max: 10,
                    price: 0.5,
                  },
                  {
                    min: 11,
                    max: 50,
                    price: 0.5,
                  },
                ],
              },
            },
          ],
        },
      ],
      supportPlans: {},
      servicePlans: {},
      other_addons: [],
      promo: [],
    amountToPay: 0,
    },
    supportPlans: {
      id: 1,
      name: "freemium",
      price: 0,
    },
    servicePlans: {
      id: 3,
      name: "premium",
      price: 0,
    },
    discounts: JSON.parse(localStorage.getItem("discounts") || "[]"),
    employeeCount: 10,
    emailCount: 500,
    emailPrice: 0,
    frequency: "yearly",
    prevState: false,
    prevpostPlan: {},
  },
};

const cookPostData = (data: Plan, state: any) => {

  let dataToPost;
  let dataRendered;
  let secondRes = JSON.parse(JSON.stringify(state.choosePlan.prevState));
  let prevPostData;
  if(secondRes){
    prevPostData = JSON.parse(JSON.stringify(state.choosePlan.prevpostPlan));
  }
  if (!data.is_free && data.pricing_method !== "module_based") {
    dataToPost = JSON.parse(JSON.stringify(state.choosePlan.data));
    dataRendered = JSON.parse(JSON.stringify(state.choosePlan.dataToRender));
    if (dataToPost?.hasOwnProperty(data.key) && !secondRes) {
      let temp = dataToPost[data.key];
      temp.frequency = state.choosePlan.frequency;
      temp.employee_count = state.choosePlan.employeeCount;
      // temp.modules = [];
      // temp.modules = Object.values(dataRendered.modules).filter(
      //   (item: any) => !item.is_active
      // );
      temp.modules = Object.values(dataRendered.modules);
      temp.modules.forEach((item: any) => {
        item.addons = [];
      });

      state.choosePlan.postData = temp;
    } else {
      state.choosePlan.postData = prevPostData;

    }
  } else {
    dataToPost = JSON.parse(JSON.stringify(state.choosePlan.data));
    if(secondRes){
      state.choosePlan.postData = prevPostData;
      return;
    }
    if (dataToPost?.hasOwnProperty(data.key)) {
      let temp = dataToPost[data.key];
      temp.modules = Object.values(temp.modules);
      if(data.pricing_method === "module_based"){
        temp.modules = temp.modules.filter((postModule:any) => postModule.key === "global_addons");
      }else{
        temp.modules = []
      }
      temp.modules.forEach((item: any) => {
        item.addons = [];
      }); 
      
      temp.frequency = state.choosePlan.frequency;
      temp.employee_count = state.choosePlan.employeeCount;

      state.choosePlan.postData = temp;
    }
  }
  cookPlanBasePrice(state);
};

const cookRenderData = (data: any, state: any) => {
  let tempData;
  tempData = JSON.parse(JSON.stringify(state.choosePlan.data));
  if (tempData?.hasOwnProperty(data.name)) {
    state.choosePlan.dataToRender = tempData[data.name];
  } else {
    return;
  }
};

const upgradePostData = (data: any, state: any) => {
  state.choosePlan.postData = data;
};

function filterPostArrayByData(postModulesArray: Module[], data: Module[]) {
  const dataModuleIds = new Set(data.map((item) => item.module_id));

  // Filter postModulesArray to keep only modules that are present in data along keeping system_settings in postData
  const filteredPostArray = postModulesArray.filter(
    (item) => dataModuleIds.has(item.module_id) || !item.is_active
  );

  // Add modules from data that are not present in postModulesArray
  data.forEach((item) => {
    if (
      !filteredPostArray.some((module) => module.module_id === item.module_id)
    ) {
      filteredPostArray.push(item);
    }
  });

  return filteredPostArray;
}

const getYearType = (data: string) => {
  switch (data) {
    case "yearly":
      return 12;
    case "monthly":
      return 1;
    default:
      return 1;
  }
};

function cookPlanBasePrice(state: any) {
  let plan = JSON.parse(JSON.stringify(state.choosePlan.postData));
  let monthlyPrice = plan.price;
  let emp = plan.employee_count;
  let basePrice = monthlyPrice * getYearType(plan.frequency) * emp;

  let modules = plan.modules; // assuming plan.modules is an array


  // Loop through the modules array
  modules.forEach((postModule: any) => {
    if (postModule.addons && Array.isArray(postModule.addons)) {
      postModule.addons.forEach((addon: any) => {
        if (addon.data && addon.data.selected !== null && addon.data.selected !== undefined) {
          
          addon.data.total_price = addon.data.monthly_price * getYearType(plan.frequency);
        } else {  
          addon.price = addon.monthly_price * getYearType(plan.frequency);
        }
      });
    }
  });

  state.choosePlan.postData.modules = modules;
  state.choosePlan.postData.total_base_price = basePrice;
}


function setSupportPrice(state: any) {
  let plan = JSON.parse(JSON.stringify(state.choosePlan.postData));
  let selectedSupport = JSON.parse(JSON.stringify(state.choosePlan.postData.supportPlans));
  
  let supportBasePrice = selectedSupport.amount 
  if(selectedSupport.frequency === "per_month" && selectedSupport.total_price !== null){
    supportBasePrice = selectedSupport.amount * getYearType(plan.frequency) 
    // Create a deep copy of the supportPlans object
    let updatedSupportPlans = JSON.parse(JSON.stringify(state.choosePlan.postData.supportPlans));
    updatedSupportPlans.total_price = supportBasePrice;

    // Replace the original supportPlans object with the updated copy
    state.choosePlan.postData.supportPlans = updatedSupportPlans;
  }
}

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    setLoaded: (state, action) => {
      state.choosePlan.loaded = action.payload;
    },
    setCurrency: (state, action) => {
      state.choosePlan.currency = action.payload;
    },
    setPlansData(state, action) {
      if (!state.choosePlan.loaded) {
        state.choosePlan.data = action.payload;
        state.choosePlan.loaded = true;
      }
    },
    setPlans(state, action) {
      state.choosePlan.id = action.payload;
    },
    setPrevPostPlan(state, action) {
      state.choosePlan.prevpostPlan = action.payload;
    },
    setplanTotalAmount(state, action) {
      state.choosePlan.postData.amountToPay = action.payload;
    },
    setEmployeeCount(state, action) {
      state.choosePlan.employeeCount = action.payload;
      state.choosePlan.postData.employee_count = action.payload;

      // --- change price of module based plan modules upon emp no ----
      if (state.choosePlan.postData.variation !== "fixed") {
        let modules = JSON.parse(JSON.stringify(state.choosePlan.postData.modules));

        modules.forEach((module: Module) => {
          if (module.is_active) {
            if (module.pre_price === undefined) {
              module.pre_price = module.price;
            }
            module.price = module.pre_price * action.payload;
            // 
          }
        });
        cookPlanBasePrice(state)
        state.choosePlan.postData.modules = modules;
      }
    },
    setRenderData(state, action) {
      cookRenderData(action.payload, state);
    },
    setPostData(state, action) {
      cookPostData(action.payload, state);
    },
    setModulesAndFeatures(state, action) {
      const { type, data, moduleIndex } = action.payload;
      switch (type) {
        case "pros":
          let postArray = JSON.parse(
            JSON.stringify(state.choosePlan.postData.modules)
          );
          if (Array.isArray(data)) {
            postArray[moduleIndex].addons = data.map((item) => {
              // Check if `data` exists within each element
              if (item.data && item.is_slider) {
                // Apply logic to add the `selected` property
                if (!item.data.selected) {
                  const newData = { ...item.data };
                  // newData.selected = item.data.min_value;
                  newData.selected =
                    item.data.free_until !== undefined &&
                    item.data.free_until !== null
                      ? item.data.free_until
                      : item.data.min_value;
                    for (const slab of item.data.slabs) {
                      if (
                        item.data.free_until >= slab.min &&
                        item.data.free_until <= slab.max
                      ) {
                        newData.total_price = slab.price * item.data.free_until;
                        break;
                      }
                    }
                  // newData.total_price = item.price;
                  return { ...item, data: newData };
                }
              }
              return item;
            });
          } else {
            // If `data` is not an array, assign `data` directly
            postArray[moduleIndex].addons = data;
          }
          state.choosePlan.postData.modules = postArray;

          break;

        case "modules":
          state.choosePlan.postData.modules.push(data);
          break;

        case "modulesOnly":
          let newData = data;
          let postModulesArray = JSON.parse(
            JSON.stringify(state.choosePlan.postData.modules)
          );
          postModulesArray = filterPostArrayByData(postModulesArray, newData);
          // state.choosePlan.postData.modules = postModulesArray;

          // --- change price of module based plan modules upon emp no ----
          if (state.choosePlan.postData.variation !== "fixed") {
            let emp = JSON.parse(JSON.stringify(state.choosePlan.postData.employee_count));

            postModulesArray.forEach((module: Module) => {
              if (module.is_active) {
                if (module.pre_price === undefined) {
                  module.pre_price = module.price;
                }
                module.price = module.pre_price * emp;
                // 
              }
            });
            state.choosePlan.postData.modules = postModulesArray;
          }
          state.choosePlan.postData.modules = postModulesArray;

          break;

        case "frequency":
          state.choosePlan.frequency = data;
          state.choosePlan.postData.frequency = data;
          cookPlanBasePrice(state);
          break;
 
      }
    },
    setAddonSelection(state, action) {
      const { type, data, moduleIndex } = action.payload;
      let postModules = JSON.parse(
        JSON.stringify(state.choosePlan.postData.modules)
      );
      switch (type) {
        case "add":
          postModules[moduleIndex].addons = [
            ...postModules[moduleIndex].addons, // Spread existing items
            ...data.map((item: Addon) => item), // Spread new items
          ];
          state.choosePlan.postData.modules = postModules;
          break;

        case "sliderAddon":
          let newAddon: any = {}; 

          if (data.data && data.is_slider) {
            let resIndex = postModules[moduleIndex].addons.findIndex(
              (addon: Addon) => addon.addon_id === data.addon_id
            );

            if (resIndex !== -1) {
              postModules[moduleIndex].addons[resIndex] = data;
            } else {
              if (!data.data.selected) {
                
                const newData = { ...data.data };
                newData.selected =
                  data.data.free_until !== undefined &&
                  data.data.free_until !== null
                    ? data.data.free_until
                    : data.data.min_value;
                for (const slab of data.data.slabs) {
                  if (
                    data.data.free_until >= slab.min &&
                    data.data.free_until <= slab.max
                  ) { 
                    newData.total_price = slab.price * data.data.free_until;
                    break;
                  }
                }
                newAddon = { ...data, data: newData };
              }
              if (Object.keys(newAddon).length !== 0) {
                postModules[moduleIndex].addons.push(newAddon);
              }
            }
          }

          state.choosePlan.postData.modules = postModules;

          break;

        case "remove":
          postModules[moduleIndex].addons = postModules[
            moduleIndex
          ].addons.filter((item: any) => item.addon_id !== data.addon_id);

          state.choosePlan.postData.modules = postModules;
          break;
      }
    },
    pushDiscounts(state, action) {
      state.choosePlan.discounts.push(action.payload);
      // state.choosePlan.postData.promo.push(action.payload);
      localStorage.setItem(
        "discounts",
        JSON.stringify(state.choosePlan.discounts)
      );
    },
    setSupport(state, action) {
      switch (action.payload.type) {
        case "support":
          state.choosePlan.supportPlans = action.payload;
          state.choosePlan.postData.supportPlans = action.payload.plan;
          if(action.payload.plan.frequency !== "free"){
            setSupportPrice(state)
          }
          break;
        case "service":
          state.choosePlan.servicePlans = action.payload;
          state.choosePlan.postData.servicePlans = action.payload.plan;
          break;
      }
    },
    
    setBack(state, action) {
      state.choosePlan.prevState = action.payload;
    },

    setOtherAddons(state, action) {
      state.choosePlan.otherAddons = action.payload;
    },

    setUpgradeModules(state, action) {
      upgradePostData(action.payload, state);
    },
    setEmailCount(state, action) {
      state.choosePlan.emailCount = action.payload;
    },
    setDiscounts(state, action) {
      state.choosePlan.discounts = action.payload;
      localStorage.setItem(
        "discounts",
        JSON.stringify(state.choosePlan.discounts)
      );
    },

    setEmailPrice(state, action) {
      state.choosePlan.emailPrice = action.payload;
    },
  },
});

export const {
  setLoaded,
  setCurrency,
  setPlans,
  setOtherAddons,
  setPlansData,
  setplanTotalAmount,
  setAddonSelection,
  setPostData,
  setRenderData,
  setModulesAndFeatures,
  setSupport,
  setBack,
  setPrevPostPlan,
  setEmployeeCount,
  setEmailCount,
  setUpgradeModules,
  setEmailPrice,
  setDiscounts,
  pushDiscounts,
} = plansSlice.actions;

export const fetchPlansData = () => {
  try {
    return plansApiData
      .getPlansData()
      .then((res: AxiosResponse<PlanRootObject>) => {
        const data = res.data;
        if (data) {
          return data;
        } else {
          // Handle the case where data is undefined or null
          throw new Error("Received undefined or null data from API.");
        }
      })
      .catch((error: any) => {
        // Handle any errors that occurred during the API call or data processing
        console.error("Error fetching plans data:", error);
        throw error; // Rethrow the error to propagate it further if necessary
      });
  } catch (error) {
    // This block won't catch any errors from asynchronous operations
    console.error("Error:", error);
  }
};

export default plansSlice.reducer;
