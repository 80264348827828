import React, { useEffect, useState } from "react";
import Loader from "src/components/Loader";
import basicSetting from "src/api/basicSetting";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuth, setError } from "src/redux/reducers/auth";
import ErrorImg from "src/assets/images/warning.svg";
import axios from "axios";

const InitialStep = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errRes, setErrRes] = useState("");
  const dispatch = useDispatch();

  const fetchIpAndLocationData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromURL = urlParams.get("token");
    const token = {
      SessionIdentifier :tokenFromURL
    }

    try {
      // Fetch IP address
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const userIp = ipResponse.data.ip;

      // Fetch additional data based on IP
      const locationResponse = await axios.get(`https://ipapi.co/${userIp}/json/`);
      const data = {user_data:locationResponse.data}
      console.log(data)

      await basicSetting.getUserInfo(data,token)
          .then((response: any) => {
            if(response.data.user_info_saved){
              return
            }else{
              dispatch(setAuth({ isAuthenticated: false, token: null }));
              setErrRes("Some Issue occured");
              setLoading(false);
              navigate("/error");
            }
          })

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {

    const executeAsync = async () => {
      await fetchIpAndLocationData();
      
      let temp = localStorage.getItem("token");

      // localStorage.clear();
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromURL = urlParams.get("token");
      const token = tokenFromURL ? tokenFromURL : temp;

      if (token) {
        localStorage.clear();

        basicSetting
          .getInitialToken(token)
          .then((response: any) => {
            if (response.data.statusCode === 403) {
              dispatch(setAuth({ isAuthenticated: false, token: null }));
              dispatch(setError(response.data.data));
              setErrRes(response.data.data);
              setLoading(false);
              return;
            } else {
              let tokenSetter = response.data.data.token;
              localStorage.setItem("token", tokenSetter);
              dispatch(setAuth({ isAuthenticated: true, token: tokenSetter }));
              navigate("/onboarding/getting-started");
              setLoading(false);
            }
          })
          .catch((error) => {
            dispatch(setAuth({ isAuthenticated: false, token: null }));
            dispatch(setError(error.response.data));
            setErrRes(error.response.data);
            setLoading(false);
          });
      } else {
        dispatch(setAuth({ isAuthenticated: false, token: null }));
        setErrRes("Link not valid");
        setLoading(false);

        dispatch(setError("Some Issue occured"));
        navigate("/error");
      }

    };

    executeAsync();
    // eslint-disable-next-line
  }, []);

  

  return (
    <div className="h-[calc(100vh-100px)] bg-white w-full flex flex-col justify-center items-center font-customFont">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="px-4 py-4 ">
            <img src={ErrorImg} alt="" />
          </div>
          <div className="px-4 py-4 text-pageHeading text-base ">{errRes}</div>
        </>
      )}
    </div>
  );
};

export default InitialStep;
