import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

interface CustomTextFieldProps extends Omit<TextFieldProps, "onChange"> {
  onChangeValue?: (value: string) => void;
  defaultValue?: string;
  companyDomain?: (value: string) => void;
  required?: boolean;
}

const StyledCustomTextField = styled(TextField)(({ theme, required }) => ({
  "& label.Mui-focused": {
    color: "#919EAB",
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& fieldset": {
      borderColor: "#919EAB",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiOutlinedInput-input": {
    paddingRight: 40,
  },
  "& .MuiInputLabel-root": {
    "&::after": {
      content: required ? "' * '" : "''",
      color: required ? "red" : "transparent", // Change color to transparent when not required
    },
  },
  "& .MuiInputLabel-asterisk": {
    display: "none", // Hide the default asterisk
  },
}));

const LoaderTextField: React.FC<CustomTextFieldProps> = ({
  onChangeValue,
  required,
  companyDomain,
  defaultValue,
  ...props
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    companyDomain?.(event.target.value);
    const inputValue = event.target.value;
    // Replace whitespace and special characters with an empty string
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9]/g, "");
    // Call onChangeValue callback with sanitized value
    onChangeValue?.(sanitizedValue);
  };

  return (
    <StyledCustomTextField
      {...props}
      onChange={handleChange}
      required={required}
      defaultValue={defaultValue}
      InputLabelProps={{ shrink: true }}
    />
  );
};

export default LoaderTextField;
