import React from "react";

const Step4Img = () => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_538_75754)">
        <path
          opacity="0.32"
          d="M8.53644 0C8.93799 0.128299 9.34341 0.246584 9.7398 0.386774C10.1388 0.528215 10.3137 0.776051 10.2898 1.23104C10.2421 2.16543 10.2756 3.1042 10.2756 4.04047C10.2756 4.12496 10.2756 4.20945 10.2756 4.29769C8.32275 3.94221 7.04967 5.47053 7.12585 6.833C7.20074 8.17795 8.53063 9.47095 10.2588 9.1236V11.269C10.171 11.269 10.0839 11.269 9.99738 11.269C9.10326 11.269 8.20849 11.2978 7.3163 11.2609C6.43767 11.2246 6.00255 12.0864 6.44864 12.7066C6.72108 13.0852 6.66878 13.5665 6.38667 13.9088C6.08905 14.2699 5.69267 14.3895 5.24206 14.2743C4.78563 14.1579 4.50287 13.8419 4.4622 13.3919C4.44219 13.1672 4.49383 12.8912 4.61972 12.7072C5.04128 12.0907 4.6378 11.2333 3.76304 11.2621C2.85406 11.2922 1.94315 11.2621 1.03354 11.274C0.834697 11.2765 0.733987 11.2058 0.637796 11.0481C-1.07686 8.22489 0.851482 4.50735 4.2143 4.15876C4.30855 4.14874 4.40281 4.1331 4.51255 4.11745C4.43444 3.53541 4.43508 2.96714 4.63909 2.42203C5.13489 1.09961 6.12004 0.327318 7.53257 0.0481903C7.5939 0.0362992 7.65393 0.016272 7.71462 0C7.98899 0 8.26272 0 8.53644 0Z"
          fill="#212B36"
        />
        <path
          d="M14.2873 3.09919e-05C14.4661 0.0394594 14.6462 0.0745068 14.8238 0.120194C16.6333 0.587076 17.7708 2.27311 17.4874 4.06679C17.4836 4.08994 17.4849 4.1131 17.4829 4.16192C17.6843 4.19071 17.889 4.21511 18.0911 4.25016C21.2337 4.7984 22.9426 8.2268 21.4384 10.9492C21.3105 11.1808 21.1717 11.2891 20.8832 11.2772C20.2492 11.2515 19.614 11.269 18.9497 11.269C19.1337 10.3021 18.8851 9.48225 18.144 8.83449C17.6243 8.38013 17.0007 8.18799 16.2989 8.22116C15.0246 8.28062 13.6572 9.43093 13.9832 11.2496H11.7257C11.7257 11.1238 11.7257 11.0018 11.7257 10.8798C11.7257 10.0599 11.7269 9.24004 11.7257 8.42018C11.7237 7.59219 11.0007 7.18726 10.2621 7.59845C9.74696 7.88508 9.18595 7.79997 8.82572 7.38002C8.49647 6.99638 8.49776 6.42874 8.8283 6.04196C9.18208 5.62703 9.74954 5.54441 10.2679 5.83105C11.0078 6.24036 11.7244 5.83418 11.7257 5.00118C11.7276 3.6506 11.7315 2.30002 11.7205 0.949442C11.7192 0.757307 11.7896 0.652164 11.9464 0.558913C12.3912 0.294805 12.8645 0.112058 13.3829 0.0419628C13.428 0.0357043 13.4707 0.0137996 13.5145 -0.0012207C13.7721 -0.0012207 14.0297 -0.0012207 14.288 -0.0012207L14.2873 3.09919e-05Z"
          fill="#212B36"
        />
        <path
          opacity="0.32"
          d="M11.7257 12.6691C11.8302 12.6691 11.9168 12.6691 12.0033 12.6691C12.8967 12.6691 13.7915 12.641 14.6837 12.6772C15.5449 12.7123 15.9981 11.8743 15.5526 11.2309C15.235 10.7722 15.3583 10.2033 15.7805 9.86094C16.1698 9.54551 16.7676 9.54613 17.1627 9.86219C17.5811 10.197 17.7044 10.774 17.3854 11.2322C16.9542 11.8518 17.3687 12.7117 18.2583 12.6772C19.1666 12.6422 20.0775 12.6735 20.9871 12.6654C21.1537 12.6641 21.246 12.7173 21.3306 12.8544C23.0956 15.7283 21.1905 19.4477 17.756 19.8288C17.6701 19.8382 17.5849 19.8557 17.4868 19.8714C17.574 20.4866 17.543 21.0786 17.3299 21.6557C16.5462 23.776 13.8128 24.6547 11.9077 23.4005C11.7715 23.311 11.7211 23.2184 11.7224 23.0601C11.7295 21.9986 11.7263 20.9372 11.7263 19.8757V19.6242C12.6714 19.7969 13.4868 19.5879 14.1524 18.9307C14.6695 18.42 14.9032 17.7961 14.8677 17.0807C14.796 15.6456 13.3848 14.4791 11.727 14.817C11.727 14.4565 11.727 14.1023 11.727 13.7481C11.727 13.3938 11.727 13.0478 11.727 12.6685L11.7257 12.6691Z"
          fill="#212B36"
        />
        <path
          d="M3.0375 12.6696C2.69147 14.5158 4.16533 15.7694 5.59916 15.7243C6.99231 15.6805 8.34351 14.4789 8.01233 12.6689H10.2764C10.2764 12.7716 10.2764 12.8542 10.2764 12.9368C10.2764 13.7798 10.308 14.6247 10.268 15.4659C10.2234 16.394 11.1744 16.7513 11.7819 16.3239C12.1556 16.061 12.5817 16.1117 12.9587 16.3658C13.3312 16.6162 13.4797 16.9804 13.4023 17.4085C13.3229 17.8485 13.0375 18.1301 12.5927 18.2452C12.3003 18.321 12.0052 18.2922 11.7599 18.1182C11.1169 17.6632 10.2434 18.1232 10.266 18.9537C10.3035 20.3187 10.2725 21.6862 10.2802 23.0518C10.2809 23.2214 10.2273 23.3221 10.0801 23.4079C8.77475 24.1683 7.44422 24.219 6.16016 23.4229C4.86191 22.618 4.32285 21.4227 4.51136 19.9313C4.51523 19.9012 4.51523 19.8712 4.51717 19.8405C4.14273 19.7679 3.76572 19.7241 3.40807 19.6202C1.29638 19.0069 -0.0799911 17.1006 0.0187823 14.9539C0.0536436 14.1898 0.27314 13.4807 0.684373 12.8298C0.727627 12.7609 0.821881 12.6777 0.894186 12.6771C1.60109 12.6658 2.30865 12.6702 3.03815 12.6702L3.0375 12.6696Z"
          fill="#212B36"
        />
      </g>
      <defs>
        <clipPath id="clip0_538_75754">
          <rect width="22" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Step4Img;
