import {instance} from "src/services/apiService";

const getHeaders = () => ({
  SessionIdentifier: localStorage.getItem("token"),
});

let settingUp = {
  getNationality: () => {
    return instance({
      url: "/api/onboarding/get/systemsetup/nationality",
      method: "get",
      headers: getHeaders(),
    });
  },
  postSettingUp: (data: any) => {
    return instance({
      url: "/api/onboarding/store/systemsetup",
      method: "post",
      data: data,
      headers: getHeaders(),
    });
  },
  getSettingUp: () => {
    return instance({
      url: "/api/onboarding/get/settingup",
      method: "get",
      headers: getHeaders(),
    });
  },
};
export default settingUp;
