import * as React from "react";
import Radio from "@mui/material/Radio";
import CheckImg from "src/assets/images/check_icon.svg";
import GreenArrow from "src/assets/images/green_arrow.png";
import RiArrow from "src/assets/images/ri_arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  setBack,
  setEmployeeCount,
  setPlans,
  setRenderData,
} from "src/redux/reducers/pickPlan";
import { Plan } from "src/types/planApi";
import Loader from "src/components/Loader";
import './planSelect.css'

interface PlanSelectProps {
  upgrade: boolean;
}

const PlanSelect: React.FC<PlanSelectProps> = ({ upgrade }) => {
  const dispatch = useDispatch();

  const postres = useSelector((state: any) => state.plans.choosePlan.postData);
  const plans = useSelector((state: any) => state.plans.choosePlan.data);
  const choosePlan = useSelector((state: any) => state.plans.choosePlan);

  // const newCurrency = useSelector(
  //   (state: any) => state.plans.choosePlan.currency
  // );
  const newCurrency = useSelector(
    (state: any) => postres.currency ? postres.currency : state.plans.choosePlan.currency
  );

  const [planSelected, setPlanSelected] = React.useState("");
  const [plansArray, setPlansArray] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(false);


  const alterBackOption = () => {
    dispatch(setBack(false))
  };

  const handleClick = (planObj: Plan) => {
    alterBackOption()
    handleChange(planObj)
  };

  const handleChange = async (planObj: Plan) => {
    if (planSelected === planObj.key) {
      return;
    }
    
    let value = planObj.key;
    setLoading(true);
    let data = {
      name: value,
      data: null,
    };
    // dispatch(setPostData(data));
    const employeesDefault = getEmployees(planObj);

    dispatch(setEmployeeCount(employeesDefault));
    dispatch(setPlans(value));
    dispatch(setRenderData(data));
    setPlanSelected(value);
    setLoading(false);
  };

  const getEmployees = (planObj: Plan) => {
    switch (planObj.is_free) {
      case true:
        return 9;
      default:
        return 10;
    }
  };

  useEffect(() => {
    if (plans) {
      setPlansArray(Object.values(plans));
    }
  }, [plans]);

  // ---- select first plan at load -----
  useEffect(() => {
    if (plans) {
      let allPlans: Plan[] = Object.values(plans);
      let firstPlan: Plan | undefined = allPlans.find(
        (plan: any) => plan.is_active
      );

      if(choosePlan.prevState){
        handleChange(choosePlan.prevpostPlan);
        setPlanSelected(choosePlan.prevpostPlan.key);
      }
      if (!choosePlan.prevState && firstPlan) {
        handleChange(firstPlan);
        setPlanSelected(firstPlan.key);
      }
    }
    // eslint-disable-next-line
  }, [plans]);
  


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className=" w-full overflow-x-auto overflow-y-hidden flex justify-start items-stretch gap-2 2xl:gap-[15px] pb-2">
          {plansArray
            .filter((plan: Plan) => plan.is_active)
            .map((plan: Plan) => (
              <div
                key={plan.plan_id}
                className={`min-w-[220px] max-w-[320px] cursor-pointer flex-col gap-1 py-3 px-4 pt-1 border rounded-lg relative flex-1 ${
                  plan.is_free === true && plan.key === planSelected
                    ? "border-green-600"
                    : plan.key === planSelected
                    ? "border-ri-secondary"
                    : "border-gray-300"
                }`}
                onClick={() => handleClick(plan)}
              >
                <div className="flex justify-start items-center ml-[-12px] mb-[-8px] ">
                  <Radio
                    checked={planSelected === plan.key}
                    value={plan.key}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 24,
                      },
                      "&.Mui-checked": {
                        color: plan.is_free ? "#00AB55" : "secondary.main",
                      },
                    }}
                  />
                  <div className="text-sm font-medium text-pageHeading  capitalize">
                    {plan.plan_name}
                  </div>
                </div>

                <div className="pb-1">
                  <span className="font-bold text-xl text-pageHeading">
                    {plan.is_free === true ? "" : newCurrency}{" "}
                    {plan.is_free === true ? "Free" : plan.price.toFixed(2)}{" "}
                  </span>
                  {plan.is_free !== true && (
                    <span className="text-lightText text-xs ">
                      {plan.variation === "fixed" ? "Fixed" : "/ per"}{" "}
                      {plan.variation === "fixed" ? "" : " employee"}
                    </span>
                  )}
                </div>

                <div className="flex justify-between items-center flex-wrap">
                  {plan.tags.map((tag, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-start gap-[2px] pt-[2px]"
                    >
                      <img src={CheckImg} alt="" className="w-[20px]" />
                      <div
                        key={index}
                        className="text-sm text-desc font-medium"
                      >
                        {tag}
                      </div>
                    </div>
                  ))}
                </div>

                {planSelected === plan.key && (
                  <div className="absolute z-10 bottom-[-8px] right-[50%]">
                    <img
                      src={plan.is_free === true ? GreenArrow : RiArrow}
                      alt=""
                    />
                  </div>
                )}

                {plan.show_expiry === true && (
                  <div className="absolute z-10 top-[8px] right-[8px] bg-red-600 rounded-lg px-2 fade-in-out text-white font-medium">
                   {plan.remaining_days !== 0 ? `${plan.remaining_days} days left` : "Last Day" } 
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default PlanSelect;
