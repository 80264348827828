import * as React from "react";
import { Box } from "@mui/material";
import CustomizedCheckbox from "src/components/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setActiveStep } from "src/redux/reducers/stepsReducer";
import Step1Img from "src/assets/imageComponents/Step1Img";
import Step2Img from "src/assets/imageComponents/Step2Img";
import Step3Img from "src/assets/imageComponents/Step3Img";
import Step4Img from "src/assets/imageComponents/Step4Img";
import Step5Img from "src/assets/imageComponents/Step5Img";

interface Step {
  id: number;
  icon: React.ComponentType<any>;
  name: string;
  active: boolean;
  complete: boolean;
}

type StepsState = Step[];

// Define the type for step IDs
type StepId = 1 | 2 | 3 | 4 | 5 ;

// Create a mapping of step IDs to components
const stepIcons: Record<StepId, React.ComponentType<any>> = {
  1: Step1Img,
  2: Step2Img,
  3: Step3Img,
  4: Step4Img,
  5: Step5Img,
};

const Sidebar = () => {
  const dispatch = useDispatch();
  const steps = useSelector((state: { steps: StepsState }) => state.steps);
  const [data, setData] = React.useState(steps);
  // const [active, setActive] = React.useState(false);

  // const fillColor = () => {
  //   const color = active ? "#835db2" : "#212B36";
  //   return color;
  // };

  useEffect(() => {
    const pathname = window.location.pathname;

    const updatedSteps = steps.map((step: any) => {
      if (step.url === pathname) {
        dispatch(setActiveStep({ stepId: step.id - 1 }));
        return { ...step, active: true };
      }
      if (pathname === "/onboarding/congratulations") {
        dispatch(setActiveStep({ stepId: step.id - 1 }));
        return { ...step, active: true };
      }
      // dispatch(setActiveStep({ stepId: step.id }));
      return { ...step, active: false };
    });
    setData(updatedSteps);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(steps);
  }, [steps]);

  return (
    <Box
    sx={{
      // width: "20%",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "20px 20px 15px 20px",
      fontSize: "14px",
      fontWeight: "600",
    }}
  >
    {data.map((item: any) => {
      const IconComponent = stepIcons[item.id as StepId];  

      return (
        <div
          key={item.id}
          className={`flex p-[10px] justify-between items-center rounded-r-lg ${
            item.active ? "border-l-4 border-ri-secondary bg-activeTab" : ""
          }`}
        >
          <div className="flex justify-between items-center gap-3">
            <div className={` ${item.active || item.active ? "active" : ""}`}>
              <IconComponent />  
            </div>
            <div className="text-ri-secondary text-[14px]">{item.name}</div>
          </div>
          <div>
            {item.complete === "true" || item.complete === true ? (
              <CustomizedCheckbox active={item.active} completed={true} />
            ) : (
              <CustomizedCheckbox active={item.active} completed={false} />
            )}
          </div>
        </div>
      );
    })}
  </Box>
);
};


export default Sidebar;
