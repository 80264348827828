import { configureStore, combineReducers } from '@reduxjs/toolkit';
import stepsReducer from './reducers/stepsReducer';
import cartReducer from './reducers/cartReducer';
import subscribeReducer from './reducers/subscribeReducer';
import pickplans from "src/redux/reducers/pickPlan";
import auth from "src/redux/reducers/auth";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {thunk} from 'redux-thunk';
import userReducer from "src/redux/reducers/userReducer"


const rootReducer = combineReducers({
  steps: stepsReducer,
  plans: pickplans,
  cart: cartReducer,
  auth: auth,
  userReducer: userReducer,
  subscribe: subscribeReducer,
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})
export const persistor = persistStore(store)

// const store = configureStore(rootReducer , applyMiddleware(thunk));

// export default store;