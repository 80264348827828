import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { 
  setActiveUser,
  setLogout,
} from "src/redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import CustomTextField from "src/components/CustomTextField";
import CustomPasswordField from "src/components/CustomPasswordField";
import { Button, Grid } from "@mui/material";
import Loader from "src/components/Loader";
import login from "src/api/login";
import LoginVector from "src/assets/images/loginVector.svg";
import LoginLeftVector from "src/assets/images/login_Left.svg";
import { TypeAnimation } from "react-type-animation";

type TempValidate = {
  userName: string;
  password: string;
};

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // const upgrade = useSelector((state: any) => state.userReducer.forUpgrade);

  // Validation helper function
  const validateForm = () => {
    let tempErrors = {} as TempValidate;
    tempErrors.userName = userName ? "" : "Email or Username is required.";
    tempErrors.password = password ? "" : "Password is required.";

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  // State Management
  const [userName, setUserName] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [authError, setAuthError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  // Login helper function
  const handleLogin = () => {
    if (validateForm()) {
      setLoading(true);
      const payload = {
        email: userName,
        password: password,
      };

      login
        .userLogin(payload)
        .then((response: any) => {
          localStorage.clear();
          if (response.status === 200) {
            let tokenSetter = response.data.data.token;
            sessionStorage.setItem("token", tokenSetter);
            dispatch(setActiveUser(response.data.data));
            navigate("/dashboard");
          }
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          setAuthError(error.response.data.message);
          dispatch(setLogout(true));
        });
    } else {
      setLoading(false);
      dispatch(setLogout(false));
    }
  };

  // Login form upgrade token
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const upgradeTokenFromURL = urlParams.get("upgrade");

    if (upgradeTokenFromURL) {
      localStorage.clear();
      login
        .getUpgradeToken(upgradeTokenFromURL)
        .then((response: any) => {
          let res = {
            data: {
              data: {
                token: "123-123-123-123",
                user_name: "admin",
                company_name: "Jalal Tech LLC",
              },
            },
          };
          // let tokenSetter = response.data.data.token;
          let tokenSetter = res.data.data.token;
          localStorage.setItem("token", tokenSetter);
          // dispatch(setActiveUser(response.data.data));
          dispatch(setActiveUser(res.data.data));
          navigate("/dashboard");
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="flex w-[100vw] h-[100vh] justify-center items-center">
        <div className="hidden md:flex flex-1 h-full bg-ri-secondary justify-center items-center text-white">
          {/* Image Here! */}
          <div className="w-1/2">
            <img src={LoginLeftVector} alt="" />
          </div>
        </div>

        <div className="flex flex-col flex-1 h-full justify-start items-center">
          <div className="flex flex-col gap-5 justify-center items-center h-full">
            <div className="flex justify-center items-center gap-3 divide-x absolute top-4">
              <div className="max-w-[200px]">
                <img src={LoginVector} alt="" />
              </div>
              <div className="px-2 font-bold text-[#212B36] text-[1.5em]">
                v 8.0
              </div>
            </div>

            <div className=" flex flex-col justify-start items-start gap-4">
              <div className="w-[65%] pl-8 lg:pl-12">
                <span className="font-semibold text-[#212B36] text-[1.5em] px-1">
                  Login
                </span>
                <TypeAnimation
                  sequence={["with your credentials.", 1000, "", 500]}
                  cursor={false}
                  speed={40}
                  style={{
                    fontSize: "1.5em",
                    overflow: "hidden",
                    maxWidth: "fit-content",
                    whiteSpace: "nowrap",
                    fontWeight: "500",
                    background:
                      "linear-gradient(90deg, #f3a332 0, #fd1d1d 50%, #833ab4 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    WebkitTextFillColor: "transparent",
                  }}
                  repeat={Infinity}
                />
              </div>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={10}>
                  <CustomTextField
                    label="Email or Username"
                    required
                    fullWidth
                    onChangeValue={setUserName}
                    value={userName}
                    defaultValue={userName}
                    error={Boolean(errors.userName)}
                    helperText={errors.userName}
                  />
                </Grid>
                <Grid item xs={10}>
                  <>
                    <CustomPasswordField
                      label="Password"
                      fullWidth
                      prompt={false}
                      onChangeValue={setPassword}
                      error={Boolean(errors.password)}
                      helperText={errors.password}
                    />
                    <div className="text-[#e20100] text-xs pt-1 pl-1">
                      {authError}
                    </div>
                  </>
                </Grid>
                <Grid item xs={10}>
                  <Button
                    onClick={handleLogin}
                    sx={{
                      width: "100%",
                      backgroundColor: "#835db2",
                      color: "white",
                      padding: "8px 16px",
                      cursor: "pointer",
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontWeight: 700,
                      "&:hover": {
                        backgroundColor: "#833bac",
                        color: "#fff",
                      },
                    }}
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </div>
            {/* --- remove this --- */}
            <div className="flex flex-col gap-2 py-2">
              <div className="text-sm">motrumumla@gufum.com</div>
              <div className="text-sm">dex</div>
              <div className="text-sm">/?token=a15d3f1a-89d4-492e-9ab4-32354c30af30</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
