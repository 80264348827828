import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Layout } from "@stripe/stripe-js";
// import payments from "src/api/payment";
import { useSelector } from "react-redux";
import { hostInstance } from "src/services/apiService";
import SuccessModal from "./SuccessModal";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
 
  const [clientSecret, setClientSecret] = useState();
  const [message, setMessage] = useState<string | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const secretKey = useSelector((state: any) => state.cart.setSecret);

  useEffect(() => {
    setClientSecret(secretKey);
  }, [secretKey]);

  useEffect(() => {
    if (stripe && clientSecret) {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }: any) => {
          switch (paymentIntent.status) {
            case "succeeded":
              setMessage("Payment succeeded!");
              break;
            case "processing":
              setMessage("Your payment is processing.");
              break;
            case "requires_payment_method":
              setMessage("Your payment was not successful, please try again.");
              break;
            default:
              setMessage("Something went wrong.");
              break;
          }
        });
    }
    // eslint-disable-next-line
  }, [stripe]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: hostInstance.defaults.baseURL + "congratulations",
      },
    });
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    if (!error) {
      setShowPopup(true);
    }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs" as Layout,
  };

  useEffect(() => {
    sessionStorage.setItem("PageUrl", window.location.href);
  }, []);

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit} className="paymentForm">
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="paymentButton"
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>

      {showPopup && <SuccessModal />}
    </>
  );
};

export default CheckoutForm;
