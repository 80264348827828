import React, { useEffect } from "react";
import swapApiData from "src/api/redux/swapPlansApi";
import { useDispatch, useSelector } from "react-redux";
import Loader from "src/components/Loader";
import ModuleCard from "src/components/ModuleCard";
import {
  setInitialSubscribe,
  setSubscribe,
} from "src/redux/reducers/subscribeReducer";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import AlertImg from "src/assets/images/alert.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import { setInitialAuth } from "src/redux/reducers/auth";
import { setLogout } from "src/redux/reducers/userReducer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  py: 1,
  px: 2,
};

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ------ redux selectors -------
  const activeUser = useSelector((state: any) => state.userReducer.activeUser);
  const subscribedPlan = useSelector((state: any) => state.subscribe.data);
  const subscribedPlanIsFree = useSelector(
    (state: any) => state.subscribe.is_free
  );

  // ------ State Managment -------
  const [loading, setLoading] = React.useState(true);
  // const [error, setError] = React.useState("");
  // const [resPrice, setResPrice] = React.useState<number>(0);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openCancelled, setOpenCancelled] = React.useState<boolean>(false);
  const [cancellationMsg, setCancellationMsg] = React.useState<string>("");

  // ------ Helper Functions  -------

  const upgradeModule = () => {
    if (subscribedPlanIsFree) {
      handleClose();
      navigate("/swap-plan");
    }
    if (subscribedPlan.pricing_method === "plan_based") {
      handleClose();
      navigate("/swap-plan");
    }
    if (subscribedPlan.pricing_method === "module_based") {
      handleClose();
      navigate("/upgrade-plan");
    }
    handleClose();
  };

  // ------ Cancel Modal functions  -------
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // ------- cancelSubscription ------
  const cancelPlan = () => {
    setLoading(true);
    // swapApiData
    //   .cancelSubscription()
    //   .then((response: any) => {
    //     handleClose();
    //     setOpenCancelled(true);
    //     setCancellationMsg(response.data.message);
    //     debugger;
    //     setLoading(false);
    //   })
    //   .catch((error: any) => {
    //     // debugger;
    //     handleClose();
    //     setLoading(false);
    //     navigate("/error");
    //   });
    handleClose();

    setOpenCancelled(true);
    setCancellationMsg("Plan has been canceled!");
  };
  // ------- Show Subscription is cacelled ------
  const closeCancelled = () => {
    sessionStorage.clear();
    dispatch(setLogout(true));
    dispatch(setInitialAuth(true));
    dispatch(setInitialSubscribe(true));
    setOpenCancelled(false);
    navigate("/login");
  };

  // --- fetch user current plan info -------
  useEffect(() => {
    setLoading(true);
    swapApiData
      .getSubscribedPlan()
      .then((response: any) => {
        dispatch(setSubscribe(response.data.plan));
        // setResPrice(response.data.price);
        // debugger
        setLoading(false);
      })
      .catch((error: any) => {
        // setError(error.response.data);
        setLoading(false);
        navigate("/error");
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <Loader />}
      {subscribedPlan && (
        <div className="flex flex-col justify-start items-start max-h-[calc(100vh-64px)] p-3 lg:pr-6 overflow-y-auto grow">
          <div className="flex justify-between items-center  pb-2 w-full border-dashed border-b-2 border-[#919EAB3D]">
            <div className="flex flex-col justify-start items-start">
              <div className="text-pageHeading text-base capitalize">
                Welcome
                <span className="font-semibold text-lg capitalize text-pageHeading">
                  {" "}
                  {activeUser.user_name}!
                </span>
              </div>
              <div className="text-lightText text-sm font-[400] break-words">
                You are currently subscribed to the
                <span className="font-medium text-sm capitalize text-pageHeading">
                  {" "}
                  {subscribedPlan.plan_name}{" "}
                </span>
                plan
                <span className="font-medium text-sm capitalize text-pageHeading">
                  {" "}
                  {subscribedPlan.frequency}{" "}
                </span>
                .
              </div>
            </div>
            <button
              onClick={handleOpen}
              className="bg-[#b82f2d] py-2 px-3 rounded-lg text-white text-sm"
            >
              Cancel Subscription
            </button>
          </div>
          <div className="flex flex-col justify-start items-start  py-2 w-full gap-1">
            <div className="text-pageHeading text-base font-[500] capitalize">
              Your Subscription
            </div>
            <div className="flex w-full justify-start items-start flex-wrap">
              <ModuleCard />
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <button
              className="w-1/4 border border-dashed border-[#919EAB7A] px-1 py-2 rounded-md flex justify-center items-center cursor-pointer mt-2"
              onClick={upgradeModule}
            >
              <AddIcon />
            </button>
          </div>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col justify-start items-center w-full gap-3 py-2">
            <div className="absolute right-3 top-2 text-black">
              <button onClick={handleClose}>
                <CancelIcon style={{ color: "#919EAB" }} />
              </button>
            </div>
            <div className="w-1/4">
              <img src={AlertImg} alt="Confirmation" />
            </div>
            <div className="flex flex-col w-full gap-1">
              <div className="text-pageHeading font-semibold text-base text-center">
                Are you sure you want to unsubscribe?
              </div>
              <div className="text-lightText text-xs text-center ">
                By unsubscribing, you will lose access to all the benefits and
                features included in this plan. This action cannot be undone.
              </div>
            </div>
            <div className="flex justify-center items-center">
              <button
                onClick={cancelPlan}
                className="bg-[#b82f2d] py-2 px-3 rounded-lg text-white text-sm"
              >
                Proceed Cancellation
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openCancelled}
        onClose={closeCancelled}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col justify-start items-center w-full gap-3 py-2">
            <div className="absolute right-3 top-2 text-black">
              <button onClick={closeCancelled}>
                <CancelIcon style={{ color: "#919EAB" }} />
              </button>
            </div>
            <div className="w-1/4">
              <img src={AlertImg} alt="Confirmation" />
            </div>
            <div className="flex flex-col w-full gap-1">
              <div className="text-pageHeading font-semibold text-base text-center">
                {cancellationMsg}
              </div>
              {/* <div className="text-lightText text-xs text-center ">
                By unsubscribing, you will lose access to all the benefits and
                features included in this plan. This action cannot be undone.
              </div> */}
            </div>
            <div className="flex justify-center items-center">
              <button
                onClick={closeCancelled}
                className="bg-[#b82f2d] py-2 px-3 rounded-lg text-white text-sm"
              >
                Continue
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Dashboard;
