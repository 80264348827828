import * as React from "react";
 import FreeImg from "src/assets/images/free_support.svg";
import PremiumImg from "src/assets/images/premium_support.svg";
import Radio from "@mui/material/Radio";
import CheckImg from "src/assets/images/check_icon.svg";
import ExcImg from "src/assets/images/exclusive.svg";
import InfoImg from "src/assets/images/info.png";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { setSupport } from "src/redux/reducers/pickPlan";
import { ServiceCardType, ServiceFeatures } from "src/types/planApi";

export interface ServicesCardProps {
  data: ServiceCardType;
  selectedServices: string;
  setSelectedServices: React.Dispatch<React.SetStateAction<string>>;
}

const ServicesCard: React.FC<ServicesCardProps> = ({
  data,
  selectedServices,
  setSelectedServices,
}) => {
  const dispatch = useDispatch();

   const postPlan = useSelector((state: any) => state.plans.choosePlan.postData);

  const handleServiceChange = (obj: any) => {
    if (obj) {
      setSelectedServices(obj.name);
      const supportObj = {
        type: "service",
        plan: obj,
      };
      dispatch(setSupport(supportObj));
    }
  };

  return (
    <div
      className={`w-full h-full flex flex-col justify-center items-start p-3 border rounded-lg  ${selectedServices === data.name? "border-[#835db2]" : "border-gray"}`}
      onClick={() => handleServiceChange(data)}
    >
      <div className="w-full flex flex-col justify-center items-center gap-1">
        <div className="w-full flex justify-between items-center">
          <div className="flex justify-start items-center gap-3">
            <img
              src={
                data.plan_type === "freemium"
                  ? FreeImg
                  : data.plan_type === "exclusive"
                  ? ExcImg
                  : PremiumImg
              }
              alt=""
            />
            <div className="text-pageHeading text-sm font-[400] capitalize">
              {data.name}
            </div>
          </div>
          <Radio
            checked={selectedServices === data.name}
            value={data.name}
            sx={{
              "&.Mui-checked": {
                color: "#835db2"  
              },
              "& .MuiSvgIcon-root": {
                fontSize: 28,
              },
            }} 
          />
        </div>
        <div className="w-full flex flex-col justify-center items-start">
          <div className="w-full flex justify-start items-center gap-2 pl-1">
            <div className="text-pageHeading text-xl font-bold">
              {data.amount ? `${postPlan.currency}${data.amount}` : "Free"}
            </div>
            <div className="text-lightText text-xs font-[400]">
              {data.frequency}
            </div>
          </div>
          <div className="text-pageHeading text-xs font-[500] capitalize flex flex-col justify-center items-start w-full">
            {data.features.map((feature: ServiceFeatures, index: number) => (
              <div
                className={`w-full flex justify-between items-center `}
                key={index}
              >
                <div className="flex justify-start items-center gap-1">
                  <img src={CheckImg} alt="" />
                  <div className="">{feature.description}</div>
                </div>
                <div className="flex justify-end items-ceter">
                  <Tooltip title={feature.info}>
                    <IconButton>
                      <img src={InfoImg} alt="" className="w-[20px]" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
