import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartTotalAmount: 0,
    clientKey: "",
  },
  reducers: {
    totalAmount: (state, action) => {
      state.cartTotalAmount = action.payload;
    },
    setSecret: (state, action) => {
      state.clientKey = action.payload;
    },
  },
});

export const { totalAmount,setSecret } = cartSlice.actions;

export default cartSlice.reducer;
