import {instance} from "src/services/apiService";

let payments = {  
  getSecret: (data: any, headers: any) => {
    return instance({
      url: "/api/onboarding/create-payment-intent",
      method: "post",
      data: data,
      headers: headers,
    });
  }, 
};

export default payments;
