import { createSlice } from '@reduxjs/toolkit'; 

export const initialState = [
    {
        id: 1,
        name: "Getting Started",
        url: "/onboarding/getting-started/getting-started",
        complete: localStorage.getItem('step1-active'),
        active: true,
    },
    {
        id: 2,
        url: "/onboarding/setting-thing-up",
        name: "Setting you Up",
        complete: localStorage.getItem('step2-active'),
        active: false,
    },
    {
        id: 3,
        name: "Choose a Plan",
        url: "/onboarding/pick-a-plan",
        complete: localStorage.getItem('step3-active'),
        active: false,
    },
    {
        id: 4,
        name: "Support & Implement",
        url: "/onboarding/support-implementation",
        complete: localStorage.getItem('step4-active'),
        active: false,
    },
     {
        id: 5,
        name: "Payment",
        url: "/onboarding/checkout",
        complete: localStorage.getItem('step5-active'),
        active: false,
    }
];

const stepsSlice = createSlice({
    name: 'steps',
    initialState,
    reducers: {
        setActiveStep(state, action) {
            const { stepId } = action.payload;

            state.forEach((step, index) => {
                if (index < stepId) {
                    localStorage.setItem(`step${step.id}-active`, 'true');
                    step.complete = 'true';
                } else {
                    // For all subsequent steps, mark them as incomplete and inactive
                    localStorage.setItem(`step${step.id}-active`, 'false');
                    step.complete = 'false';
                }
                if (index <= stepId) {
                    localStorage.setItem(`step${step.id}-active`, 'true');
                    step.active = true;
                } else {
                    // For all subsequent steps, mark them as incomplete and inactive
                    localStorage.setItem(`step${step.id}-active`, 'false');
                    step.active = false;
                }
            });
        }
    }

});
export const { setActiveStep } = stepsSlice.actions;

export default stepsSlice.reducer;
