import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowImage from "src/assets/images/arrow_down.png";
import {
  setModulesAndFeatures,
} from "src/redux/reducers/pickPlan";
import { useEffect } from "react";

const PlanTab = () => {
  const dispatch = useDispatch();
  const plans = useSelector((state: any) => state.plans);
  // const [planType, setPlanType] = React.useState("yearly");
  const [yearly, setYearly] = React.useState("");
  // const [newPlan, setNewPlan] = React.useState("");

  useEffect(() => {
    setYearly(plans.choosePlan.frequency);
  }, [plans]);

  const changePlan = () => {
    const newPlanAlter = yearly === "monthly" ? "yearly" : "monthly";
    setYearly(newPlanAlter);
    // setNewPlan(newPlanAlter);
    // setPlanType(newPlan);
    const postData = {
      type: "frequency",
      data: newPlanAlter,
      moduleIndex: null,
    };
    dispatch(setModulesAndFeatures(postData));
  };

 

  return (
    <div className="relative z-20">
      <div className="z-20 flex justify-start items-center absolute top-[-30px] left-[-60px]">
        <div className="px-2 min-w-[100px] rounded-2xl flex justify-start items-center gap-2 bg-[#FF5630] text-white">
          <div>12% off</div>
          <div className="w-[4px] h-[4px] bg-white z-10 rounded-full"></div>
        </div>
        <div className="mt-[10px] ml-[-5px]">
          <img src={ArrowImage} alt="" className="" />
        </div>
      </div>
      <div
        className="flex justify-end items-center border-2 border-[#F0E4FB] rounded-3xl cursor-pointer"
        onClick={changePlan}
      >
        <div
          className={`${
            yearly === "yearly" ? "bg-[#F0E4FB]" : " bg-white"
          } rounded-3xl py-2 w-[80px] text-center text-ri-secondary font-[700] text-sm`}
        >
          Yearly
        </div>
        <div
          className={`${
            yearly === "monthly" ? "bg-[#F0E4FB]" : " bg-white"
          } rounded-[22px] py-2 w-[80px] text-center text-ri-secondary font-[700] text-sm }`}
        >
          Monthly
        </div>
      </div>
    </div>
  );
};

export default PlanTab;
