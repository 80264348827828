import { Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import upgradePlanApi from "src/api/redux/upgradePlanApi";
import ModuleAccordian from "src/components/Accordian/ModuleAccordian";
import Cart from "src/components/Cart";
import Loader from "src/components/Loader";
import OtherAddons from "src/components/PlanPage/OtherAddons";
import PlanTab from "src/components/PlanPage/PlanTab";
import {
  setEmployeeCount,
  setLoaded,
  setPlansData,
  setRenderData,
  setUpgradeModules,
} from "src/redux/reducers/pickPlan";

const Upgrade1 = () => {
  const dispatch = useDispatch();

  // redux selectors
  const plans = useSelector((state: any) => state.plans.choosePlan);
  const renderPlan = useSelector((state: any) => state.plans.choosePlan.dataToRender);
  const subscribedPlan = useSelector((state: any) => state.subscribe.data);
  const postPlan = useSelector((state: any) => state.plans.choosePlan.postData);
  const plan = useSelector((state: any) => state.plans.choosePlan);

  // State management
  // const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); 
  const [totalEmp, setTotalEmp] = React.useState<number>(postPlan.employeeCount);
  // ------- set employee in company ----
  const empInCompany = (value: number | number[]) => {
    if (typeof value === "number") {
      dispatch(setEmployeeCount(value));
      setTotalEmp(value);
    }
  };

  // --------------- Change No. of employees ---------
  useEffect(() => {
    setTotalEmp(plans.employeeCount);
    // eslint-disable-next-line
  }, [renderPlan]);

  // -----Api call -------
  useEffect(() => {
    setLoading(true);
    upgradePlanApi
      .getUpgradePlans()
      .then((response: any) => {
        // Store Complete plan in redux
        dispatch(setLoaded(false));
        dispatch(setPlansData(response.data.complete_plan));

        // const subscribed = Object.values(response.data.plan_subscribed)[0];
        // dispatch(setUpgradeModules(subscribed));

         setLoading(false);
      })
      .catch((error: any) => {
        // setError(error.response.data);
        setLoading(false);
        // navigate("/error")
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (plan.data !== null) {
      let data: any = Object.values(plan.data)[0];
      let value = data.key;
      let planKey = {
        name: value,
        data: null,
      };
      dispatch(setRenderData(planKey));
    }
    // eslint-disable-next-line
  }, [plan.data]);

  useEffect(() => {
    dispatch(setUpgradeModules(subscribedPlan));
    // eslint-disable-next-line
  }, [renderPlan]);

  return (
    <>
      {loading && <Loader />}

      <div className="w-full flex justify-between items-start h-[81vh] lg:h-[71vh] 2xl:h-[81vh]  pl-1">
        <div className="w-full lg:w-[69%] 2xl:w-[80%] max-h-[100%]  overflow-y-auto  flex flex-col justify-start items-start gap-2">
          <div className="w-full flex flex-col justify-between items-start gap-1 pb-2 border-dashed border-b-2 border-[#919EAB3D]">
            <div className="text-pageHeading text-base font-[600]">
              Let’s upgrade your plan
            </div>
            <div className="text-lightText text-sm font-[400] break-words">
              Plans and any additional features you need for your business.
            </div>
          </div>

          {/* ------ Render modules and data for selected Plan --- */}
          <div className="w-full pb-6">
            {/* ---------- No of employees in company ------  */}
            {renderPlan && (
              <div className="flex flex-col justify-center items-start gap-8">
                <div className="text-black text-sm">
                  How many employees do you have?
                </div>
                <div className="w-full pl-[20px] pr-[25px]">
                  <Slider
                    defaultValue={totalEmp}
                    value={totalEmp}
                    step={10}
                    marks
                    min={10}
                    max={200}
                    valueLabelDisplay="on"
                    onChange={(event, newValue) => empInCompany(newValue)}
                  />
                </div>
              </div>
            )}

            {/* ----- render the modules ---- */}
            <ModuleAccordian />
          </div>
          {/* ----- render the Addons ---- */}
          <div className="w-full ">
            <OtherAddons />
          </div>
        </div>

        <div className="w-full hidden lg:flex flex-col gap-2 flex-1 relative pt-1 lg:px-5 pl-1 overflow-y-auto max-h-[100%]">
          {/* <div className="pt-[30px] flex justify-end items-end w-full">
            <PlanTab />
          </div> */}
          <div className="overflow-y-auto max-h-[100%]">
            <Cart />
          </div>
        </div>
      </div>
    </>
  );
};

export default Upgrade1;
