import { instance } from "src/services/apiService";

const getHeaders = () => ({
  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
});


let swapApiData = {
  getSwapPlansData: () => {
    return instance({
      url: "/api/onboarding/get/swap-plan",
      method: "get",
      headers: getHeaders(),
    });
  },
  getSubscribedPlan: () => {
    return instance({
      url: "/api/onboarding/get/subscribed-plan",
      method: "get",
      headers: getHeaders(),
    });
  },
  cancelSubscription: () => {
    return instance({
      url: "/api/onboarding/cancel-subscription",
      method: "get",
      headers: getHeaders(),
    });
  },
};
export default swapApiData;
