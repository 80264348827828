import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ExistingModule, ExistingAddon } from "src/types/existingPlan";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  py: 1,
  px: 2,
};

const ModuleCard = () => {
  const navigate = useNavigate();

  const subscribedPlan = useSelector((state: any) => state.subscribe.data);
  const subscribedPlanIsFree = useSelector(
    (state: any) => state.subscribe.is_free
  );

  // ---------State Managment ------------
  const [modulesArray, setModulesArray] = useState<ExistingModule[]>();
  const [renderAddons, setRenderAddons] = useState<ExistingAddon[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);

  // ---------Modal Actions ------------
  const handleOpen = (addons: ExistingAddon[]) => {
    setOpen(true);
    setRenderAddons(addons);
  };
  const handleClose = () => setOpen(false);

  const upgradeAddon = () => {
    if (subscribedPlanIsFree) {
      handleClose();
      navigate("/swap-plan");
    } else {
      handleClose();
      navigate("/upgrade-plan");
    }
    handleClose();
  };

  // ---- get data to render ---
  useEffect(() => {
    if (subscribedPlan && subscribedPlan.modules) {
      setModulesArray(subscribedPlan.modules);
    }
  }, [subscribedPlan]);

  return (
    <>
      <Grid container spacing={2}>
        {modulesArray
          ?.filter((item) => item.is_active)
          .map((item: ExistingModule) => (
            <Grid item xs={12} md={6} lg={4} key={item.module_id}>
              <div className="flex flex-col justify-between items-start w-full shadow-lg pb-0 rounded-lg h-full">
                <div className="flex flex-col justify-center items-start gap-2  p-2 pb-4 w-full">
                  <div className="flex justify-start items-center gap-[8px]">
                    {item.icon && (
                      <div className={`p-2 rounded-full max-w-[70px] `}>
                        <img
                          src={item.icon}
                          alt=""
                          className="w-full h-full object-cover object-center"
                        />
                      </div>
                    )}
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-pageHeading text-base font-normal">
                        {item.module_name}
                      </div>
                      {item.tags && (
                        <div className="text-lightText text-xs ">
                          {item.tags.map((tag: string, index: number) => (
                            <div key={index}>{tag}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-start text-sm text-lightText">
                    {item.description}
                  </div>
                </div>
                {item.addons && (
                  <button
                    className="w-full flex justify-between items-center p-2 text-lightText text-sm border-dashed border-t-2 border-[#919EAB3D]"
                    onClick={() => handleOpen(item.addons)}
                  >
                    <div>Addons</div>
                    <KeyboardArrowRightIcon />
                  </button>
                )}
              </div>
            </Grid>
          ))}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col justify-start items-start w-full">
            <div className="text-pageHeading text-base font-medium w-full border-dashed border-b-2 border-[#919EAB3D] pb-1">
              Subscribed Addons
            </div>
            {renderAddons.length ? (
              <div className="flex justify-start items-start flex-wrap gap-2 max-h-[60vh] overflow-y-auto pt-2">
                {renderAddons?.map((addon: ExistingAddon) => (
                  <div className="flex justify-between items-center gap-3 bg-slate-200 p-2 rounded-lg">
                    <div className="flex flex-col justify-start items-start gap-1">
                      <div>{addon.name}</div>
                      {/* <div className="text-lightText text-xs ">
                        {addon.description}
                      </div> */}
                    </div>
                    <div className="flex justify-center items-center gap-1 text-sm text-pageHeading">
                      {addon.data && (
                        <>
                          <div>
                            {addon.data
                              ? addon.data.selected
                                ? addon.data.selected
                                : addon.data.min_value
                              : ""}
                          </div>
                          <div>{addon.unit}</div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center gap-1 text-sm text-lightText w-full py-3">
                No Addons Available
              </div>
            )}
            <button
              className="w-full border border-dashed border-[#919EAB7A] px-1 py-2 rounded-md flex justify-center items-center cursor-pointer mt-2"
              onClick={upgradeAddon}
            >
              <AddIcon />
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ModuleCard;
