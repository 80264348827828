import React, { useEffect, useState } from "react";
import AvatarBagde from "src/components/AvatarBadge";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "src/assets/images/header_logo.svg";
import DashboardImg from "src/assets/images/Dashboard.svg";
import SwapImg from "src/assets/images/swap.svg";
import UpgradeImg from "src/assets/images/upgrade.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "src/redux/reducers/userReducer";
import { setLoaded } from "src/redux/reducers/subscribeReducer";
import login from "src/api/login";
import { Modal } from "@mui/material";
import Cart from "./Cart";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import { useLocation } from "react-router-dom";

type Options = {
  desc: string;
  type: string;
};

const profileOptions = [
  { desc: "Dashboard", type: "dashboard" },
  { desc: "Logout", type: "logout" },
];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  py: 1,
  px: 2,
};

const navLinks = [
  {
    img: DashboardImg,
    path: "/dashboard",
    name: "Dashboard",
  },
  {
    img: SwapImg,
    path: "/swap-plan",
    name: "Swap Plan",
  },
  {
    img: UpgradeImg,
    path: "/upgrade-plan",
    name: "Upgrade Plan",
  },
];


const AppBarComponent = () => {
  
  const is_free = useSelector((state: any) => state.subscribe.is_free);
  
  const filteredNavLinks = navLinks.filter(
    (link) => !(is_free && link.path === "/upgrade-plan")
  );

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const activeUser = useSelector((state: any) => state.userReducer.activeUser);

  const [open, setOpen] = React.useState<boolean>(false);
  const [enableCart, setEnableCart] = React.useState<boolean>(false);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClick = (option: Options) => {
    switch (option.type) {
      case "dashboard":
        navigate("/dashboard");
        handleCloseUserMenu();
        break;
      case "logout":
        handleLogout();
        handleCloseUserMenu();
        break;
      default:
        handleCloseUserMenu();
        break;
    }
  };

  const handleLogout = () => {
    dispatch(setLoaded(false));
    login
      .userLogout()
      .then((response: any) => {
        dispatch(setLogout(true));
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      })
      .catch((error: any) => {
        dispatch(setLogout(true));
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
        throw error;
      });
  };

  // ------ Cart Modal functions  -------
  const handleCartOpen = () => {
    setOpen(true);
  };
  const handleCartClose = () => setOpen(false);

  // ------- give promo entry avaliable --------
  useEffect(() => {
    const hiddenPromoPaths = ["/swap-plan", "/upgrade-plan"];
    const shouldShowPromoBox = hiddenPromoPaths.some((path) =>
      location.pathname.endsWith(path)
    ); 
    setEnableCart(shouldShowPromoBox); 
  }, [location.pathname]);

  return (
    <>
      <AppBar
        position="static"
        color="default"
        style={{ backgroundColor: "#835db2" }}
      >
        <Container maxWidth={false}>
          <Toolbar
            disableGutters
            sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}
          >
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon style={{ color: "white" }} />
              </IconButton>
              <div className="flex justify-center items-center py-4 w-full">
                <img src={Logo} alt="ResourceInn" />
              </div>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {filteredNavLinks.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.path}
                    className={({ isActive }) =>
                      `flex justify-start items-center px-2 py-1 cursor-pointer gap-2   text-black mx-2 ${
                        isActive
                          ? "bg-[#997bbeb9] rounded-lg text-white"
                          : "text-black"
                      }`
                    }
                  >
                    <div className="text-base">{item.name}</div>
                  </NavLink>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 0 ,display:"flex", justifyContent:'center', alignItems:"center",gap:"6px"}}>
              {enableCart && <div className="flex lg:hidden" onClick={handleCartOpen}>
                <ShoppingCartTwoToneIcon sx={{ color: "#cbb6d4" }} />
              </div>}
              
              <Tooltip title="Profile">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AvatarBagde />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div>
                  <div className="w-full px-2  border-dashed border-b-2 border-[#919EAB3D] capitalize pb-2 flex justify-start items-center gap-3">
                    <AvatarBagde />
                    <div>{activeUser.user_name}</div>
                  </div>
                  {profileOptions.map((option, index) => (
                    <MenuItem key={index} onClick={() => handleClick(option)}>
                      <Typography textAlign="center">{option.desc}</Typography>
                    </MenuItem>
                  ))}
                </div>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Modal
        open={open}
        onClose={handleCartClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Cart />
        </Box>
      </Modal>
    </>
  );
};

export default AppBarComponent;
