import {instance} from "src/services/apiService";

// Function to dynamically get headers
const getHeaders = () => ({
  SessionIdentifier: localStorage.getItem("token"),
});


let setPromoCode = {
  getPromoCodePrice: (data: any) => {
    return instance({
      url: "/api/onboarding/validate/promocode",
      method: "post",
      data: data,
      headers: getHeaders(),
    });
  },
  removeDiscountCode: (data: number) => {
    return instance({
      url: "/api/onboarding/remove/discountcode",
      method: "post",
      data: data,
      headers: getHeaders(),
    });
  },
};
export default setPromoCode;
