


import React from 'react'

const Step2Img = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M15 9C16.6569 9 18 7.65685 18 6C18 4.34315 16.6569 3 15 3C13.3431 3 12 4.34315 12 6C12 7.65685 13.3431 9 15 9Z" fill="#212B36"/>
        <path opacity="0.4" d="M16 20C18.7614 20 21 18.6569 21 17C21 15.3431 18.7614 14 16 14C13.2386 14 11 15.3431 11 17C11 18.6569 13.2386 20 16 20Z" fill="#212B36"/>
        <path d="M9.00098 10C11.2101 10 13.001 8.20914 13.001 6C13.001 3.79086 11.2101 2 9.00098 2C6.79184 2 5.00098 3.79086 5.00098 6C5.00098 8.20914 6.79184 10 9.00098 10Z" fill="#212B36"/>
        <path d="M9.00098 21.001C12.867 21.001 16.001 19.2101 16.001 17.001C16.001 14.7918 12.867 13.001 9.00098 13.001C5.13498 13.001 2.00098 14.7918 2.00098 17.001C2.00098 19.2101 5.13498 21.001 9.00098 21.001Z" fill="#212B36"/>
    </svg>
  )
}

export default Step2Img