import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckImg from "src/assets/images/check_icon.svg";
import InfoImg from "src/assets/images/info.png";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setModulesAndFeatures } from "src/redux/reducers/pickPlan";
import AddPros from "./AddPros";
import { setPostData } from "src/redux/reducers/pickPlan";
import { Addon, Feature, Module } from "src/types/planApi";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "0px 12px 24px -4px #919EAB1F",
  borderRadius: "8px",
}));

const ModuleAccordian: React.FC = () => {
  const dispatch = useDispatch();

  const renderPlan = useSelector((state: any) => state.plans.choosePlan.dataToRender);
  const upgrade = useSelector((state: any) => state.userReducer.forUpgrade);
  const postPlan = useSelector((state: any) => state.plans.choosePlan.postData);
  const choosePlan = useSelector((state: any) => state.plans.choosePlan);

  const [modulesArray, setModulesArray] = useState<Module[]>([]);
  const [customSelected, setCustomSelected] = useState<Module[]>([]);
  const [customCheckbox, setCustomCheckbox] = React.useState<boolean>(false);
  const [accordionOpen, setAccordionOpen] = React.useState(false);
   
  // ---- Accordian controls ----
  const accordianToggle = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    const expandIconClicked = target.closest(".MuiIconButton-root");

    if (!expandIconClicked) {
      accordianToggle();
    }
  };

  const handleCheckboxClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (accordionOpen) {
      accordianToggle();
    }
    // e.stopPropagation();
  };

  const checkAddons = (addons: Addon[]) => {
    return Object.keys(addons).length > 0;
  };

  const addonsToArray = (addons: Addon[]) => {
    return checkAddons(addons) ? Object.values(addons) : [];
  };

  // ------ Custom module controls ----
  const addCustomModule = async (obj: Module) => {
    if(upgrade || choosePlan.prevState){
      setCustomSelected(postPlan.modules)
    }
    const index = customSelected?.findIndex(
      (item: Module) => item.module_id === obj.module_id
    );
    
    if (index === -1) {
      const newObj = { ...obj, addons: [] };
      setCustomSelected((customSelected: Module[]) => [
        ...customSelected,
        newObj,
      ]);
    } else {
      setCustomSelected((customSelected: Module[]) =>
        customSelected.filter(
          (item: Module) => item.module_id !== obj.module_id
        )
      );
    }
  };

  const customModuleAdded = (module_id: number) => {
    if (postPlan.price === 0 && !postPlan.is_free) {
      // if (postPlan.key === "custom") {
      const matchIndex = postPlan.modules.findIndex(
        (item: Module) => item.module_id === module_id
      );
      return matchIndex !== -1;
    }
  };

  useEffect(() => {
    if (customCheckbox) {
      dispatch(
        setModulesAndFeatures({ type: "modulesOnly", data: customSelected })
      );
    }
    // eslint-disable-next-line
  }, [customSelected]);

  // ---- get data to render ---
  useEffect(() => {
    if (renderPlan && renderPlan.modules) {
      // let data = {
      //   name: renderPlan.key,
      //   data: null,
      // };
      setModulesArray(Object.values(renderPlan.modules));

      dispatch(setPostData(renderPlan));
      // renderPlan.key === "custom"
      renderPlan.price === 0 && !renderPlan.is_free
        ? setCustomCheckbox(true)
        : setCustomCheckbox(false);
    }
  }, [dispatch, renderPlan]);

  return (
    <>
      <Grid container spacing={2}>
        {modulesArray
          ?.filter((item) => item.is_active)
          .map((item: Module) => (
            <Grid item xs={12} sm={6} xl={4} key={item.module_id}>
              <CustomAccordion>
                <AccordionSummary
                  expandIcon={customCheckbox ? "" : <ExpandMoreIcon />}
                  onClick={handleClick}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col justify-center items-start gap-[8px]">
                      <div className="flex justify-start items-center gap-[8px]">
                        {/* <img src={item.icon} alt="" /> */}
                        <div className={`p-1 md:p-2 rounded-full max-w-[70px] `}>
                          <img
                            src={item.icon}
                            alt=""
                            className="w-full h-full object-cover object-center"
                          />
                        </div>
                        <div className="flex flex-col justify-center items-start">
                          <div className="text-pageHeading text-base font-semibold">
                            {item.module_name}
                          </div>
                          {item.tags && (
                            <div className="text-lightText text-xs ">
                              {item.tags.map((tag: string, index: number) => (
                                <div key={index}>{tag}</div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="text-start text-xs text-lightText">
                        {item.description}
                      </div>
                    </div>
                    {customCheckbox && (
                      <div onClick={handleCheckboxClick}>
                        <Checkbox
                          color="default"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                              borderRadius: "8px",
                            },
                          }}
                          checked={customModuleAdded(item.module_id)}
                          onChange={() => addCustomModule(item)}
                        />
                      </div>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {item.price > 0 && (
                    <div className="font-bold text-xl text-pageHeading mt-[-8px] mb-[8px]">
                      {renderPlan.currency} {item.price}
                      <span className="pl-2 text-start text-sm text-lightText font-semibold">
                        {item.variation === "fixed"
                          ? "Fixed"
                          : `per ${item.unit}`}
                      </span>
                    </div>
                  )}
                  <div className="flex flex-col justify-center items-start gap-3">
                    <div className="text-start text-sm text-lightText">
                      Essential features:
                    </div>

                    <div className="w-full flex flex-col justify-start items-start">
                      {item?.features?.map(
                        (feature: Feature, index: number) => (
                          <div
                            className="w-full flex justify-between items-center"
                            key={index}
                          >
                            <div className="flex justify-start items-center gap-[4px]">
                              <img src={CheckImg} alt="" className="w-[20px]" />
                              <div className="text-sm text-pageHeading font-medium">
                                {feature.description}
                              </div>
                            </div>
                            <div className="flex justify-end items-ceter">
                              <Tooltip title={feature.info}>
                                <IconButton>
                                  <img
                                    src={InfoImg}
                                    alt=""
                                    className="w-[20px]"
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  {/* {checkAddons(item.addons) && renderPlan.key !== "custom" && ( */}
                  {checkAddons(item.addons) &&
                    !renderPlan.is_free &&
                    renderPlan.price > 0 && (
                      <AddPros moduleName={item.module_name} propAddons={addonsToArray(item.addons)} />
                    )}
                  {checkAddons(item.addons) &&
                    customModuleAdded(item.module_id) && (
                      <AddPros moduleName={item.module_name} propAddons={addonsToArray(item.addons)} />
                    )}
                </AccordionDetails>
              </CustomAccordion>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default ModuleAccordian;
