
import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Promo } from "src/types/planApi";

interface CustomTextFieldProps extends Omit<TextFieldProps, "onChange"> {
  onChangeValue?: (value: string) => void;
  setValue?: (value: string) => void;
  setValueOnEnter?: React.Dispatch<React.SetStateAction<Promo[]>>;
  defaultValue?: string;
  required?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledCustomTextField = styled(TextField)(({ theme, required }) => ({
  "& label.Mui-focused": {
    color: "#919EAB",
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& fieldset": {
      borderColor: "#919EAB",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiInputLabel-root": {
    "&::after": {
      content: required ? "' *'" : "''",
      color: required ? "red" : "transparent", // Change color to transparent when not required
    },
  },
  "& .MuiInputLabel-asterisk": {
    display: "none", // Hide the default asterisk
  },
}));

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  onChangeValue,
  required,
  defaultValue,
  setValue,
  setValueOnEnter,
  setIsDirty,
  ...props
}) => {
  // const [enterPressed, setEnterPressed] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty?.(true);
    onChangeValue?.(event.target.value);
    setValue?.(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setIsDirty?.(true);
    if (event.key === "Enter") {
      // setEnterPressed(true);
      const id = Math.floor(Math.random() * 100);
      const value = (event.target as HTMLInputElement).value;
      const newPromo: any = {
        id: id,
        name: value,
      };
      setValueOnEnter?.((prevValue) => [...prevValue, newPromo]);
      (event.target as HTMLInputElement).value = "";
    }
  };

  return (
    <>
      <StyledCustomTextField
        {...props}
        defaultValue={defaultValue}
        onChange={handleChange}
        required={required}
        // onKeyDown={handleKeyDown}  will be used for promo code 
      />
     </>
  );
};

export default CustomTextField;
