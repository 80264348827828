import * as React from "react";
import SupportCard from "./SupportCard";
import ServicesCard from "./ServicesCard";
import Cart from "src/components/Cart";
import CustomButton from "src/components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "src/redux/reducers/stepsReducer";
import { useEffect } from "react";
import supportImplment from "src/api/supportImplement";
import { Grid } from "@mui/material";
// import { Promo } from "src/types/planApi";
import { ServiceCardType, SupportCardType } from "src/types/planApi";
import { setBack, setPrevPostPlan } from "src/redux/reducers/pickPlan";
import { useLocation } from "react-router-dom";
import Loader from "src/components/Loader";
import { setError } from "src/redux/reducers/auth";

const Step4 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // ------ redux selectors ----
  const postToData = useSelector((state: any) => state.plans.choosePlan.postData);
  const upgrade = useSelector((state: any) => state.userReducer.forUpgrade);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [disableNext, setDisableNext] = React.useState<boolean>(false);

  const [supportPlans, setSupportPlans] = React.useState<SupportCardType[]>();
  const [servicePlans, setServicePlans] = React.useState<SupportCardType[]>();
    // eslint-disable-next-line
  const [servicePlansDetails, setServicePlansDetails] = React.useState([]);
    // eslint-disable-next-line
  const [supportPlansDetails, setSupportPlansDetails] = React.useState([]);
  const [supportDesc, setSupportDesc] = React.useState<string>();
  const [serviceDesc, setServiceDesc] = React.useState<string>();

  const [selectedSupport, setSelectedSupport] = React.useState(postToData.supportPlans && postToData.supportPlans.name ?postToData.supportPlans.name : "");
  const [selectedServices, setSelectedServices] = React.useState(postToData.servicePlans && postToData.servicePlans.name ?postToData.servicePlans.name : "");
  const [supportError, setSupportError] = React.useState(false);
  const [serviceError, setServiceError] = React.useState(false);
  // const [promoCode, setPromoCode] = React.useState<Promo[]>([]);
  const helperText = "Please select one.";

  const validateForm = () => {
    if (selectedSupport === "" && supportPlansDetails.length > 0) {
      setSupportError(true);
      return false;
    } else if (selectedServices === "" && servicePlansDetails.length > 0) {
      setServiceError(true);
      return false;
    } else {
      return true;
    }
  };

  const cartTotalAmount = useSelector(
    (state: any) => state.cart.cartTotalAmount
  );

  const NextStage = () => {
    if (validateForm()) {
      if(!disableNext){
        setLoading(true)
        setDisableNext(true)
        dispatch(setActiveStep({ stepId: 4 }));
        const dataToPost = {
          total_price: cartTotalAmount,
        plan: postToData,
      };
      supportImplment.setPostData(dataToPost).then((res) => {
        // if (cartTotalAmount > 0) {
        // setLoading(false)
        //   navigate("/onboarding/checkout");
        // } else {
          const tenant_id = res.data.post_data_id;
          localStorage.setItem("id",tenant_id)
          setLoading(false)
          navigate("/onboarding/congratulations");
          // }
        })
        .catch((error: any) => { 
          setDisableNext(false)
          dispatch(setError(error.response.data));
          navigate("/error");
          console.error("Error fetching plans data:", error);
          throw error;
        });
        // navigate("/onboarding/congratulations");
      }
    }
    // return;
  };

  const PreviousStage = () => {
    moveToPreviousStep();
    dispatch(setBack(true)); 
    dispatch(setPrevPostPlan(postToData));
    navigate("/onboarding/pick-a-plan");
  };

  const moveToPreviousStep = () => {
    dispatch(setActiveStep({ stepId: 2 }));
  };

  // -------------- api strikes for onboarding--------------
  const supportPlansApi = () => {
    supportImplment.getSupportPlans().then((res) => {
      setSupportPlans(res.data.data);
      // debugger
      // setSupportPlansDetails(res.data.data.plan_details);
      setSupportDesc(res.data.description);
      setLoading(false);
    });
  };
  const servicesPlansApi = () => {
    supportImplment.getServicePlans().then((res) => {
      // debugger
      setServicePlans(res.data.data);
      // setServicePlansDetails(res.data.data.plan_details);
      setServiceDesc(res.data.description);
      setLoading(false);
    });
  };

  // -------------- api strikes for upgrade--------------
  const supportPlansUpgrade = () => {
    supportImplment.getUpgradeSupportPlans().then((res) => {
      setSupportPlans(res.data.data.all_support_plans);
      // debugger
      // setSupportPlansDetails(res.data.data.plan_details);
      setSupportDesc(res.data.description);
      setLoading(false);
    });
  };
  const servicesPlansUpgrade = () => {
    supportImplment.getUpgradeServicePlans().then((res) => {
      setServicePlans(res.data.data.all_service_plans);
      // setServicePlansDetails(res.data.data.plan_details);
      setServiceDesc(res.data.description);
      setLoading(false);
    });
  };

  // -------------- api call --------------
  useEffect(() => {
    if (location.pathname.endsWith("/onboarding/support-implementation")) {
      setLoading(true);
      supportPlansApi();
      servicesPlansApi();
    } else {
      setLoading(true);
      supportPlansUpgrade();
      servicesPlansUpgrade();
    }
  }, [location.pathname]);

 useEffect(() => {
  dispatch(setPrevPostPlan(postToData));
  // eslint-disable-next-line
 }, [])
 

  return (
    <>
    {loading && <Loader/>}
    <div
      className={`w-full flex flex-1 flex-col justify-start items-start ${
        !upgrade ? "shadow-custom" : ""
      } select-none rounded-lg`}
    >
      {/* ------ Header having title and step count ---- */}
      {!upgrade && (
        <div className="w-full py-2 lg:py-4 lg:px-5 flex flex-col justify-start items-start border-dashed border-b-2 border-[#919EAB3D]">
          <div className="text-pageHeading text-sm font-[400]">
            Support & Implementation
          </div>
          <div className="text-lightText text-sm font-[400]">4 of 5</div>
        </div>
      )}

      {/* ------ details start ---- */}
      <div
        className={`w-full flex justify-between items-start gap-6 ${
          upgrade ? "px-2 h-[82vh] lg:h-[71vh]" : " lg:pl-5 pr-1 h-[73vh] lg:h-[64vh] 2xl:h-[70vh]"
        }`}
      >
        {/* ------ description text --- */}
        <div
          className={`w-full lg:w-[69%] flex flex-col justify-start items-start gap-6 overflow-y-auto max-h-[100%] ${
            upgrade ? "p-0" : "pt-4 pb-2"
          }`}
        >
          {supportPlans && (
            <div className="w-full flex flex-col justify-start items-start gap-2">
              <div className="text-pageHeading text-base font-[600]">
                {supportDesc}{" "}?
              </div>
              <div className="w-full flex justify-between gap-2">
                <Grid container spacing={2}>
                  {supportPlans.map((plan: SupportCardType, index: number) => (
                    <Grid item xs={6} key={plan.id}>
                      <SupportCard
                        data={plan}
                        selectedSupport={selectedSupport}
                        setSelectedSupport={setSelectedSupport}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
              {supportError && (
                <div className="text-[#e20100] text-sm">{helperText}</div>
              )}
            </div>
          )}
          {servicePlans && (
            <div className="w-full flex flex-col justify-start items-start gap-2">
              <div className="text-pageHeading text-base font-[600]">
                {serviceDesc}{" "}?
              </div>
              <div className="w-full flex justify-between gap-2">
                <Grid container spacing={2}>
                  {servicePlans.map((plan: ServiceCardType) => (
                    <Grid item xs={6} key={plan.id}>
                      <ServicesCard
                        data={plan}
                        selectedServices={selectedServices}
                        setSelectedServices={setSelectedServices}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
              {serviceError && (
                <div className="text-[#e20100] text-sm">{helperText}</div>
              )}
            </div>
          )}
        </div>
        <div className="w-full hidden lg:flex flex-1 overflow-y-auto max-h-[100%] pt-6">
          <Cart />
        </div>
      </div>

      {!upgrade && (
        <div className="w-full px-2 lg:px-5 pt-3 pb-4 flex justify-between items-center border-dashed border-t-2 border-[#0708083d]">
          <CustomButton
            variant="unfilled"
            text={"Back"}
            onClick={PreviousStage}
            
          />
          <CustomButton variant="filled" text={"Finalize"} onClick={NextStage} type={"button"}
            disable={disableNext}/>
        </div>
      )}
    </div>
    </>
  );
};

export default Step4;
