import { instance } from "src/services/apiService";

interface LoginPayload{
  email: string;
  password: string
}

const getHeaders = () => ({
  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
});

let login = {
  getUpgradeToken: (data: string) => {
    return instance({
      url: `/api/accessurl/${data}`,
      method: "get",
    });
  },
  userLogin: (data:LoginPayload) => {
    return instance({
      url: "/api/onboarding/login",
      method: "post",
      data:data
    });
  }, 
  userLogout: () => {
    return instance({
      url: "/api/onboarding/logout",
      method: "post",
      headers: getHeaders(),
    });
  }, 
};

export default login;
