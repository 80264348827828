import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AdminRouter } from "./Routes";
import Layout from "src/Layout/Layout";
import AdminLayout from "src/Layout/AdminLayout";
import { OnboardRouter } from "./Routes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { forUpgrade } from "src/redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import InitialStep from "src/pages/Onboarding/initialStep/page";
import Login from "src/pages/Login/page";
import NotFound from "src/pages/NotFound";
import LastStep from "src/pages/Onboarding/completion/page";

const Navigation = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const upgrade = useSelector((state: any) => state.userReducer.forUpgrade);
  const isLoggedIn = useSelector((state: any) => state.userReducer.loggedIn);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromURL = urlParams.get("token");
    const upgradeFromURL = urlParams.get("upgrade");

    if (tokenFromURL) {
      dispatch(forUpgrade(false));
      navigate(`/onboarding/?token=${tokenFromURL}`);
    } else if (upgradeFromURL) {
      dispatch(forUpgrade(true));
      navigate(`/login/?upgrade=${upgradeFromURL}`);
    } else {
      if (window.location.pathname === "/" && !isLoggedIn) {
        navigate("/login");
      } else if (window.location.pathname === "/" && isLoggedIn) {
        navigate("/dashboard");
      }
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

 


  return (
    <>
      <Routes>
        {isLoggedIn || upgrade ? (
          AdminRouter.map((item, index) => (
            <>
              <Route
                path={item.path}
                key={index}
                element={
                  <AdminLayout>
                    <item.component />
                  </AdminLayout>
                }
              />
              <Route path="/congratulations" element={<LastStep />} />
            </>
          ))
        ) : (
          <Route path="*" element={<Login />} />
        )}
        {!upgrade && !isLoggedIn && (
          <>
            <Route
              path="/onboarding"
              element={
                <Layout>
                  <InitialStep />
                </Layout>
              }
            />
            {OnboardRouter.map((item, index) => (
              <Route
                path={item.path}
                key={index}
                element={
                  <Layout>
                    <item.component />
                  </Layout>
                }
              />
            ))}
          </>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Navigation;
