import {instance} from "src/services/apiService";

// Function to dynamically get headers
const getHeaders = () => ({
  SessionIdentifier: localStorage.getItem("token"),
});

const bearerHeaders = () => ({
  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
});

let supportImplment = {
  getSupportPlans: () => {
    return instance({
      url: "/api/onboarding/get/supportplans",
      method: "get",
      headers: getHeaders(),
    });
  },
  getServicePlans: () => {
    return instance({
      url: "/api/onboarding/get/serviceplans",
      method: "get",
      headers: getHeaders(),
    });
  },
  getUpgradeSupportPlans: () => {
    return instance({
      url: "/api/onboarding/get/upgrade-support-plan",
      method: "get",
      headers: bearerHeaders(),
    });
  },
  getUpgradeServicePlans: () => {
    return instance({
      url: "/api/onboarding/get/upgrade-service-plan",
      method: "get",
      headers: bearerHeaders(),
    });
  },
  setPostData: (data: any) => {
    return instance({
      url: "/api/onboarding/store/plan",
      method: "post",
      data: data,
      headers: getHeaders(),
    });
  },
};
export default supportImplment;
